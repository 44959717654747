import React, { useEffect, useState } from 'react';
import { RiArrowLeftLine, RiSave2Fill } from 'react-icons/ri';
import { useHistory } from 'react-router';
import Button from '../../../components/elements/button/Button';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import AmbraInputRadio from '../../../components/elements/form/AmbraInputRadio';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import AmbraTable from '../../../components/elements/table/AmbraTable';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import useAlerts from '../../../hooks/useAlerts';
import { useQuery } from '../../../hooks/useQuery';
import preliminaryRiskAnalysisService from '../../../services/preliminaryRiskAnalysisService';
import topicsService from '../../../services/topicsService';
import releasersService from '../../../services/releasersService';

const APRCreateAnalyzePage = () => {
    
    const history = useHistory();
    const query = useQuery();
    const alerts = useAlerts();

    //APR DATA
    const [editId, setEditId] = useState<string>('0');
    const [company, setCompany] = useState<any>();
    const [name, setName] = useState<string>('');
    const [expiration, setExpiration] = useState<string>('');
    const [sector, setSector] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [field, setField] = useState<string>('');
    const [isDraft, setIsDraft] = useState<boolean>(false);
    const [onlyAreaResponsible, setOnlyAreaResponsible] = useState<boolean>(false);
    const [needPassByPass, setNeedPassByPass] = useState<boolean>(false);
    const [releasersData, setReleaserData] = useState<any[]>([]);
    const [releasers, setReleasers] = useState<string[]>([]);

    const [terms, setTerms] = useState<string>('');
    const [searchTerms, setSearchTerms] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(1);
    const [updateCounter, setUpdateCounter] = useState<number>(0);
    const [startTopics, setStartTopics] = useState<any[]>([]);
    const [endTopics, setEndTopics] = useState<any[]>([]);


    useEffect(() => {
        let company = localStorage.getItem('company');
        if(company) {
            setCompany(company);
        }
        let id = query.get('id');
        if(id) {
            setEditId(id);
        }
    }, []);

    useEffect(() => {
        if(editId !== '0') {
            preliminaryRiskAnalysisService.get(editId).then((response) => {
                if(response.status === 200) {
                    loadObjectFromRequest(response.data);
                }
            });
            topicsService.list(editId).then((response) => {
                if(response.status === 200) {
                    let startTopicsData: any[] = [];
                    let endTopicsData: any[] = [];
                    //prática abaixo não é o ideal. O ideal seria a filtragem ocorrer no back-end.
                    response.data.map((topic: any) => {
                        if(topic.preliminary_risk_analysis === editId) {
                            if(topic.start_topic) {
                                startTopicsData.push(topic);
                            }else{
                                endTopicsData.push(topic);
                            }
                        }
                    });
                    setStartTopics(startTopicsData);
                    setEndTopics(endTopicsData);
                }
            });
        }
    }, [editId, updateCounter]);

    useEffect(() => {
        if(company && company.length > 0) {
            releasersService.list(company).then((response) => {
                if(response.status === 200) {
                    setReleaserData(response.data);
                }
            });
        }
    }, [company]);

    const loadObjectFromRequest = (requestData: any) => {
        setCompany(requestData.company);
        setField(requestData.field);
        setSector(requestData.sector);
        setExpiration(requestData.expiration+'');
        setIsDraft(requestData.is_draft);
        setOnlyAreaResponsible(requestData.only_to_assigned_releaser);
        //setDescription(requestData.description);
        setName(requestData.name);
        setNeedPassByPass(requestData.need_pass_by_pass);

        let releasersList: any[] = [];
        requestData.releasers_list.map((el: any) => {
            releasersList.push(el.releaser);
        });
        setReleasers(releasersList);
    }

    const storeAnalyzeAPR = (redirect: boolean) => {
        //TODO: realiza o store da APR
        let requestData = {
            company: company,
            expiration: parseInt(expiration+''),
            is_draft: isDraft,
            only_to_assigned_releaser: onlyAreaResponsible,
            name: name,
            need_pass_by_pass: needPassByPass,
            releasers: releasers,
        };   
        if(editId === '0') {
            preliminaryRiskAnalysisService.store(requestData).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Successo!', 'APR Salva com sucesso!');
                    if(redirect || !(response.data?.preliminaryRiskAnalysis?.id))
                        history.push('/auth/APR');
                    else
                        history.push('/auth/APR?id='+response.data?.preliminaryRiskAnalysis?.id);
                }
            }).catch((error) => {
                alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar a APR.');
            });
        }else{
            preliminaryRiskAnalysisService.put(editId, requestData).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Successo!', 'APR Salva com sucesso!');
                    history.push('/auth/APR');
                    if(redirect)
                        history.push('/auth/APR');
                }
            }).catch((error) => {
                alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar a APR.');
            });
        }
    }

    const destroyTopic = (topicId: number) => {
        alerts.showAlertConfirm('warning', 'Você realmente deseja excluir este tópico?', 'Essa ação não terá volta.', () => {
            topicsService.destroy(topicId).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Tópico excluído com sucesso');
                    setUpdateCounter(updateCounter + 1);
                }
            });
        }, () => {});
    }
    
    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Adicionar Análise Preliminar de risco  </Title>
                    <Subtitle> Análise Preliminar de Risco {'>'} Adicionar análise </Subtitle> 
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
                    <div className="lg:col-span-4">
                        <AmbraInputText
                            label="Nome da análise"
                            placeholder="Digite o nome da APR"
                            validation="required"
                            value={name}
                            onChange={(e: any) => { setName(e.target.value) }}
                        />
                    </div>
                    <div className="lg:col-span-3">
                        <AmbraInputCheckbox
                            label="Disponibilidade"
                            onChange={(e) => {setIsDraft(!isDraft)}}
                            checked={isDraft}
                            value='Marcar como rascunho'
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
                    <div className="lg:col-span-4">
                        <AmbraInputText
                            label="Validade em horas"
                            placeholder="Digite um número"
                            validation="required|number"
                            type='number'
                            value={expiration}
                            onChange={(e: any) => { setExpiration(e.target.value) }}
                        />
                    </div>
                    <div className="lg:col-span-3">
                        <AmbraInputCheckbox
                            label="Acesso"
                            onChange={(e) => {setOnlyAreaResponsible(!onlyAreaResponsible)}}
                            checked={onlyAreaResponsible}
                            value='Somente liberar para encarregado'
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
                    <div className="col-span-3">
                        <AmbraInputCheckbox
                            label="Passo a passo"
                            onChange={(e) => {setNeedPassByPass(!needPassByPass)}}
                            checked={needPassByPass}
                            value='Realização de passo a passo como obrigatório'
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        {
                            releasersData.map((el, index) => 
                            <AmbraInputCheckbox key={index}
                            value={el.name+' ('+el.unity_name+')'}
                            label={index === 0 ? 'Tipo de liberador' : ''}
                            onChange={(e) => {
                                let indexChange = releasers.indexOf(el.id);
                                let relCopy = [...releasers];
                                if(indexChange === -1) {
                                    relCopy.push(el.id);
                                }else{
                                    relCopy.splice(indexChange, 1);
                                }
                                setReleasers(relCopy);
                            }}
                            checked={releasers.indexOf(el.id) !== -1}
                            />)
                        }
                    </div>
                </div>

                {
                    editId !== '0' &&
                    <>

                        <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                            <div>
                                <AmbraTable
                                    title='Tópicos de início'
                                    data={startTopics}
                                    renderColumns={[
                                        { label: 'Tópico', dataColumn: 'name' },
                                        { label: 'Descrição', dataColumn: 'description' }
                                    ]}
                                    showEdit={true}
                                    showDelete={true}
                                    showFile={false}
                                    search={false}
                                    onEdit={(element: any) => { history.push('/auth/APR/Topic?aprId='+editId+'&id='+element.id) }}
                                    onDelete={(element: any) => { destroyTopic(element.id) }}
                                    onFile={(element: any) => { console.log(element) }}
                                    route={'/auth/APR/Topic?aprId='+editId}
                                    renderTools={<>Adicionar tópico</>}
                                />
                            </div>
                        </div>
                    </>
                }

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div className='col-start-6'>
                        <Button type='primary' className='mr-2' onClick={() => {history.push('/auth/APR')}}><RiArrowLeftLine className="inline-block text-white-700" /> Voltar</Button>
                        <Button type='primary' className='ml-2' onClick={() => {storeAnalyzeAPR(true)}}>Salvar APR <RiSave2Fill className="inline-block text-white-700" /></Button>
                        <Button type='primary' className='ml-2' onClick={() => {storeAnalyzeAPR(false)}}>Salvar e continuar na APR <RiSave2Fill className="inline-block text-white-700" /></Button>
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default APRCreateAnalyzePage;