import  { useEffect, useState } from 'react';

interface AmbraInputSelectPropsInterface {
    className?: string;
    label?: string;
    placeholder?: string;
    onChange?: (e: any) => void;
    data?: any[];
    value?: string;
    validation?: string;
}

const AmbraInputSelect = (props: AmbraInputSelectPropsInterface) => {

    const value = props?.value;
    const [isValid, setIsValid] = useState<boolean>(true);
    const [validationMessage, setValidationMessage] = useState<string>('');

    useEffect(() => {
        if(props.value) {
            validate(props.value);
        } else {
            validate('');
        }
        //eslint-disable-next-line
    }, [props.value]);

    const validate = (value: string) => {
        setIsValid(true);
        if(props.validation) {
            if(props.validation.includes('required') && value.length === 0) {
                setIsValid(false);
                setValidationMessage('Este campo é obrigatório');
                return;
            }
        }
    }
    
    const handleSelectChange = (event: any) => {
        validate(event.target.value);
        if(props.onChange) {
            props.onChange(event);
        }
    }

    return (
        <div className={props.className}>
            <label className="text-base font-medium text-gray-600">
                {props.label}
            </label>
            <select
                onChange={(e: any) => {handleSelectChange(e)}}
                className={'border-ambra-gray color-ambra-gray border-solid p-2 text-base rounded border block w-full shadow-sm outline-none focus:outline-none focus:shadow-lg transition-all duration-300 ' + (isValid ? 'border-gray-300' : 'border-red-300 bg-red-50')}
                value={value}
            >
                <option value="" disabled>{props.placeholder}</option>
                {
                    props.data?.map((el, index) => <option key={index} value={el.value}>{el.text}</option>)
                }
            </select>
            {
                !isValid && 
                <div className="p-0 m-0 text-base font-normal text-red-500 text-right">
                {validationMessage}
                </div>
            }
        </div>
    )
}

export default AmbraInputSelect;