import { useEffect, useState } from 'react';
import { RiArrowLeftLine, RiSave2Fill } from 'react-icons/ri';
import { useHistory } from 'react-router';
import Button from '../../../components/elements/button/Button';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import AmbraTable from '../../../components/elements/table/AmbraTable';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import useAlerts from '../../../hooks/useAlerts';
import { useQuery } from '../../../hooks/useQuery';
import questionsService from '../../../services/questionsService';
import topicsService from '../../../services/topicsService';

const PTCreateTopicPage = () => {
    const history = useHistory();
    const query = useQuery();
    const alerts = useAlerts();

    const [editId, setEditId] = useState<string>('0');
    const [company, setCompany] = useState<any>();
    const [name, setName] = useState<string>('');
    const [mandatory, setMandatory] = useState<boolean>(true);
    const [description, setDescription] = useState<string>('');
    const [order, setOrder] = useState<string>('0');
    const [startTopic, setStartTopic] = useState<boolean>(true);
    const [workPermission, setWorkPermission] = useState<string>('');

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [updateCounter, setUpdateCounter] = useState<number>(0);

    const [questions, setQuestions] = useState<any[]>([]);
    
    useEffect(() => {
        let company = localStorage.getItem('company');
        if (company) {
            setCompany(company);
        }
        let id = query.get('id');
        if (id) {
            setEditId(id);
        }
        let prId = query.get('prId');
        if (prId) {
            setWorkPermission(prId);
        }
        let startTopicQuery = query.get('start');
        if (startTopicQuery) {
            setStartTopic(startTopicQuery !== 'false');
        }else{
            setStartTopic(true);
        }
    }, []);

    useEffect(() => {
        if (editId !== '0') {
            topicsService.get(editId).then((response) => {
                if (response.status === 200) {
                    loadObjectFromRequest(response.data);
                }
            });
            questionsService.list(editId).then((response) => {
                if (response.status === 200) {
                    setQuestions(response.data);
                }
            });
        }
    }, [editId, updateCounter]);

    const loadObjectFromRequest = (requestData: any) => {
        setName(requestData.name);
        setMandatory(requestData.mandatory);
        setDescription(requestData.description);
        setOrder(requestData.order + '');
        setWorkPermission(requestData.work_permission);
        setStartTopic(requestData.start_topic);
        setCompany(requestData.company);
    }

    const storeTopic = () => {
        let requestData = {
            name: name,
            mandatory: mandatory,
            description: description,
            order: parseInt(order + ''),
            work_permission: workPermission,
            start_topic: startTopic,
            company: company
        };
        if (editId === '0') {
            topicsService.store(requestData).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Successo!', 'Tópico salvo com sucesso!');
                    history.push('/auth/PT/Create?id='+workPermission);
                }
            }).catch((error) => {
                alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar o tópico.');
            });
        } else {
            topicsService.put(editId, requestData).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Successo!', 'Tópico salvo com sucesso!');
                    history.push('/auth/PT/Create?id='+workPermission);
                }
            }).catch((error) => {
                alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar o tópico.');
            });
        }
    }

    const destroyQuestion = (questionId: number) => {
        alerts.showAlertConfirm('warning', 'Você realmente deseja excluir esta questão?', 'Essa ação não terá volta.', () => {
            questionsService.destroy(questionId).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Questão excluído com sucesso');
                    setUpdateCounter(updateCounter + 1);
                }
            });
        }, () => { });
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Adicionar Tópico </Title>
                    <Subtitle> Adicionar Permissão de Trabalho {'>'} Adicionar Permissão {'>'} Adicionar Tópico </Subtitle>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Nome do tópico"
                            placeholder="Digite um tópico"
                            validation="required"
                            value={name}
                            onChange={(e: any) => { setName(e.target.value) }}
                        />
                    </div>
                    <div>
                        <AmbraInputCheckbox
                            label="Obrigatoriedade"
                            onChange={(e) => { setMandatory(!mandatory) }}
                            checked={mandatory}
                            value='Marcar como obrigatório'
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Descrição"
                            placeholder="Digite a descrição o acessório"
                            validation="required"
                            value={description}
                            onChange={(e: any) => { setDescription(e.target.value) }}
                        />
                    </div>
                </div>
                {
                    editId !== '0' &&
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                            <div>
                                <AmbraTable
                                    title='perguntas'
                                    data={questions}
                                    renderColumns={[
                                        { label: 'Questoes', dataColumn: 'name' },
                                        { label: 'Foto Obrigatória', dataColumn: 'situation' }
                                    ]}
                                    showEdit={true}
                                    showDelete={true}
                                    showFile={false}
                                    search={false}
                                    onEdit={(element: any) => { history.push('/auth/PT/Quest?topicId='+editId+'&id='+element.id) }}
                                    onDelete={(element: any) => { destroyQuestion(element.id) }}
                                    onFile={(element: any) => { console.log(element) }}
                                    route={'/auth/PT/Quest?topicId='+editId}
                                    renderTools={<>adicionar pergunta</>}
                                />
                            </div>
                        </div>
                    </>
                }


                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div className='col-start-6'>
                        <Button type='primary' className='mr-2' onClick={() => {history.push('/auth/PT?id='+workPermission) }}><RiArrowLeftLine className="inline-block text-white-700" /> Voltar</Button>
                        <Button type='primary' className='ml-2' onClick={() => {storeTopic()}}>Salvar Tópico <RiSave2Fill className="inline-block text-white-700" /></Button>
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default PTCreateTopicPage