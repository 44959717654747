import React from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

interface AuthNavItemPropsInterface {
    route: string;
    icon: any;
    name: string;
    roles?: string;
}

interface AuthNavItemStateInterface {
}

const AuthNavItem = (props: AuthNavItemPropsInterface) => {
    
    const {loggedUser, setLoggedUser, isLoggedIn, token, setToken, getUserRole} = useAuth();

    const history = useHistory();

    let matchRoles = true;
    let loggedUserRoles = getUserRole();
    if(loggedUser?.type && parseInt(loggedUser.type+'') === 1) {
        loggedUserRoles += '|marketplace'
    }
    if(props.roles && props.roles.length > 0) {
        matchRoles = false;
        let rolesAr = props.roles.split('|');
        for(let i = 0; i < rolesAr.length; i++) {
            if(loggedUserRoles.includes(rolesAr[i])) {
                matchRoles = true;
            }
        }
    }

    const getNavActiveClass = (route: string) => {
        let currentPathNameSplit = window.location.pathname.replace('/auth/', '').split('/');
        let routeSplit = route.replace('/auth/', '').split('/');
        return (currentPathNameSplit[0] === routeSplit[0] ? ' bg-gray-800 text-white' : ' text-gray-800');
    }

    const navigate = () => {
        history.push(props.route);
    }

    return (
        <>
            {
                matchRoles ? 
                (<li className={'w-full p-2 hover:text-white-900 transition-all cursor-pointer' + getNavActiveClass(props.route)} onClick={() => {navigate()}}>
                    {props.icon}
                    {props.name}
                </li>)
                :
                (<></>)
            }
        </>
    )
    
}

export default AuthNavItem;