import React, { useEffect, useState } from "react";
import useValidation from "../../../hooks/useValidation";
import { maskBRL, maskCelPhone, maskCEP, maskCPFCNPJ, maskDate, maskCPF } from '../../../utils/masks';

interface AmbraInputTextPropsInterface {
  label?: string;
  placeholder?: string;
  className?: any;
  value?: string;
  validation?: string;
  type?: string;
  mask?: string;
  onBlur?: (e: any) => void
  onChange?: (e: any) => void;
  onIsValidUpdate?: (isValid: boolean) => void;
  onFocus?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onEnterKeyUp?: (e: any) => void;
}

//masks = cpfCnpj // CEP // E-mail

const AmbraInputText = (props: AmbraInputTextPropsInterface) => {
  const input = React.createRef<HTMLInputElement>();
  const validation = useValidation();
  const [type, setType] = useState<string>('text');

  // const onTriggerFocus = () => {
  //   input.current?.focus();
  // }

  const callOnIsValidUpdate = (isValid: boolean) => {
    if (props.onIsValidUpdate) {
      props.onIsValidUpdate(isValid);
    }
  }

  useEffect(() => {
    if (!props.type) {
      setType('text');
    } else {
      setType(props.type);
    }
  }, [props.type]);

  useEffect(() => {
    if (props.value) {
      validate(props.value);
    } else {
      validate('');
    }
  }, [props.value]);

  const validate = (value: string) => {
    if (props.validation && props.validation.length > 0) {
      validation.validate(value, props.validation);
      callOnIsValidUpdate(validation.isValid);
    }
  }

  const handleInputChange = (event: any) => {
    if (props.mask) {
      switch (props.mask) {
        case 'cpfCnpj':
          event.target.value = maskCPFCNPJ(event.target.value);
          break;
        case 'cpf':
          event.target.value = maskCPF(event.target.value);
          break;
        case 'cep':
          event.target.value = maskCEP(event.target.value);
          break;
        case 'phone':
          event.target.value = maskCelPhone(event.target.value);
          break;
        case 'date':
          event.target.value = maskDate(event.target.value);
          break
        case 'BRL':
          event.target.value = maskBRL(event.target.value);
          break;
      }
    }
    if (props.onChange) {
      props.onChange(event);
    }
    validate(event.target.value);
  }

  const handleInputBlur = (event: any) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  }

  const handleInputFocus = (event: any) => {
    if (props.onFocus) {
      props.onFocus(event);
    }
  }

  const handleKeyUp = (event: any) => {
    if (event.keyCode && event.keyCode === 13 && props.onEnterKeyUp) {
      props.onEnterKeyUp(event);
    }
    if (props.onKeyUp) {
      props.onKeyUp(event);
    }
  }

  return (
    <div className={props.className}>
      <label className="text-base font-medium color-ambra-gray">
        {props.label}
      </label>
      <input
        onKeyUp={(e: any) => { handleKeyUp(e) }}
        onChange={(e: any) => { handleInputChange(e) }}
        onBlur={(e: any) => { handleInputBlur(e) }}
        ref={input}
        value={props.value ?? ''}
        className={'bg-ambra-whiteForm border-solid p-2 text-base color-ambra-gray rounded border block w-full shadow-sm outline-none focus:outline-none focus:shadow-lg transition-all duration-300 ' + (validation.isValid ? 'border-ambra-gray ' : 'border-red-300 bg-red-50 ') + (props.className?.indexOf('rounded-r-none') !== -1 ? '' : '')}
        type={type}
        placeholder={props.placeholder}
        autoComplete="off"
        onFocus={(e: any) => { handleInputFocus(e) }}
      ></input>
      {
        !validation.isValid &&
        <div className="p-0 m-0 text-base font-normal text-red-500 text-right">
          {validation.validationMessage}
        </div>
      }
    </div>
  );

}

export default AmbraInputText;