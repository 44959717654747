import { RiArticleFill, RiUserFill, RiPieChart2Fill, RiBuildingFill, RiShieldCheckFill, RiCalendarFill, RiLoginBoxLine, RiSearchLine, RiBuilding2Line, RiBuilding2Fill, RiWalletFill, RiLockFill } from 'react-icons/ri';
import packageJson from "../../../../package.json";
import AuthNavItem from "./AuthNavItem";
import userService from '../../../services/userService';
import { useEffect, useState } from 'react';
import companiesService from '../../../services/companiesService';

const AuthNavbar = (props: any) => {
  const [company, setCompany] = useState<string>('');
  const [nameCompany, setNameCompany] = useState<any>();
  const [fileUrl, setFileUrl] = useState<string>('/icon.png');

  useEffect(() => {
    let company = localStorage.getItem('company');
    if (company) {
        setCompany(company);
    }
  }, []);

  useEffect(() => {
    if (company){
      companiesService.findCompanyById(company).then((response) => {
        if (response.status) {
            setNameCompany(response.data.name);
              if(response.data.profile_picture_url) 
              {
                setFileUrl(response.data.profile_picture_url);
              }
          }
    });
    }
  }, [company]);

  return (
    <div className="navbar-auth bg-ambra-orangeLight text-center text-gray-800">
      <div className="flex items-center justify-center">
        <img src={fileUrl} className="rounded-full w-10 h-auto inline-flex border-2 border-black"/>
        <div className="inline-flex text-2xl p-2 pt-6 pb-6 font-medium uppercase">{nameCompany}</div>
      </div>
      <hr />
      <ul className="mt-2 text-sm font-normal text-gray-600 uppercase text-left ms-2">
        <AuthNavItem
          route="/auth/companies"
          icon={<RiBuilding2Fill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Empresas"
          roles="ambra"
        />
        <AuthNavItem
          route="/auth/revenues"
          icon={<RiWalletFill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="faturamento"
          roles="ambra"
        />
        <AuthNavItem
          route="/auth/PR/Company"
          icon={<RiBuildingFill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Parametrização"
          roles="user|admin"
        />
        <AuthNavItem
          route="/auth/LB"
          icon={<RiShieldCheckFill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Liberadores"
          roles="user|admin"
        />
        <AuthNavItem
          route="/auth/CB"
          icon={<RiUserFill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Colaboradores"
          roles="user|admin"
        />
        <AuthNavItem
          route="/auth/reconhecimento-facial"
          icon={<RiLockFill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Reconhecimento Facial"
          roles="user|admin"
        />
        <AuthNavItem
          route="/auth/APR"
          icon={<RiArticleFill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Análise preliminar de riscos"
          roles="user|admin"
        />
        <AuthNavItem
          route="/auth/PT"
          icon={<RiArticleFill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Permissão de trabalho"
          roles="user|admin"
        />
        <AuthNavItem
          route="/auth/RL"
          icon={<RiPieChart2Fill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Relatórios"
          roles="user|admin"
        />
        <AuthNavItem
          route="/auth/VN"
          icon={<RiCalendarFill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Vencimentos"
          roles="user|admin"
        />
        <AuthNavItem
          route="/auth/duedNotifications"
          icon={<RiCalendarFill className="text-2xl inline-block" style={{ margin: '1vh' }} />}
          name="Vencimentos Notificados"
          roles="user|admin"
        />
      </ul>

      <div className="navbar-footer center mt-2 text-sm font-normal text-gray-600 uppercase">
        <hr className='mr-5 ml-5'/>
        <ul>
          <AuthNavItem
            route="/auth/logout"
            icon={<RiLoginBoxLine className="text-2xl" style={{ margin: 'auto' }} />}
            name="Sair"
          />
        </ul>
      </div>
    </div>
  );
}

export default AuthNavbar;
