import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Button from "../../../components/elements/button/Button";
import AmbraTable from "../../../components/elements/table/AmbraTable";
import Subtitle from "../../../components/elements/text/Subtitle";
import Title from "../../../components/elements/text/Title";
import AuthContainer from "../../../components/layout/auth/AuthContainer";
import useAlerts from "../../../hooks/useAlerts";

import unityServices from "../../../services/unityServices";

const PRSectorPage = () => {
  const history = useHistory();
  const alerts = useAlerts();

  const [company, setCompany] = useState<string>("");
  const [data, setData] = useState<any>([]);
  const [updateCounter, setUpdateCounter] = useState<number>(0);

  useEffect(() => {
    let companyId = localStorage.getItem("company");
    if (companyId) {
      setCompany(companyId);
    }
  }, []);

  useEffect(() => {
    console.log('oi')
    if (company !== "") {
 
      unityServices.list(company).then((response) => {
        if (response.status === 200) {
          setData(response.data);
        }
      });
    }
  }, [updateCounter, company]);

  const destroyArea = (areaId: string) => {
    console.log(areaId);
    alerts.showAlertConfirm(
      "warning",
      "Você realmente deseja excluir a unidade?",
      "Isso não poderá ser desfeito.",
      () => {
        unityServices.destroy(areaId).then((response) => {
          if (response.status) {
            alerts.showAlert(
              "success",
              "Sucesso",
              "A unidade foi excluída com sucesso"
            );
            setUpdateCounter(updateCounter + 1);
          }
        });
      },
      () => {}
    );
  };

  return (
    <AuthContainer>
      <div className="m-3 mt-4 mb-4">
        <div className="mb-4">
          <Title> Parametrização </Title>
          <Subtitle> Parametrização {">"} Unidades </Subtitle>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 mb-1 mt-5 border-bottom">
          <div>
            <Button
              type="visible"
              onClick={() => {
                history.push("/auth/PR/Company");
              }}
            >
              <h1 className="text-xl text-gray-500 uppercase">
                1. Dados da Empresa
              </h1>
            </Button>
          </div>

          <div>
            <Button type="Invisible">
              <h1 className="text-xl text-gray-700 uppercase font-medium">
                2. Unidades
              </h1>
            </Button>
          </div>
        </div>
        <hr />

        <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
          <div>
            <AmbraTable
              title="Unidades"
              data={data}
              renderColumns={[{ label: "Nome", dataColumn: "name" }]}
              showEdit={true}
              showDelete={true}
              showFile={false}
              search={true}
              onEdit={(element: any) => {
                history.push("/auth/PR/Area?id=" + element.id);
              }}
              onDelete={(element: any) => {
            
                destroyArea(element.id);
              }}
              onFile={(element: any) => {
                console.log(element);
              }}
              route="/auth/PR/Area"
              renderTools={<>adicionar unidade</>}
            />
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};

export default PRSectorPage;
