import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FiEdit2, FiPlus, FiTrash } from "react-icons/fi";
import useAlerts from "../../hooks/useAlerts";
import genericService from "../../services/genericService";
import { maskFloatToBRL } from "../../utils/masks";
import Box from "../elements/Box";
import Button from "../elements/button/Button";
import Subtitle from "../elements/text/Subtitle";
import Title from "../elements/text/Title";
import GenericCRUDModal from "./GenericCRUDModal";

export interface GenericCRUDFieldSelectDataInterface {
    text: string;
    value: any;
}

export interface GenericCRUDEntityFieldsInterface {
    entityFieldName: string; // field sended in request (field: modelObject)
    entityFieldFormComponent: React.ReactNode; // withParams (model => Object, onModelChange => Object(model))
    entityFieldListShow: boolean; // show on crud list
    entityFieldListLabel?: string; // if needed, label on list
    entityFieldListText?: (entityFieldModel: any) => string; // if needed, display text/value (as string) on list
}

export interface GenericCRUDFieldInterface {
    fieldName: string;
    fieldInputLabel: string;
    fieldInputPlaceholder: string;
    fieldValidationPattern?: string;
    fieldType?: string;
    fieldSelectData?: GenericCRUDFieldSelectDataInterface[];
    fieldMask?: string;
}

interface GenericCRUDPropsInterface {
    name: string;
    subtitle?: string;
    fields: GenericCRUDFieldInterface[];
    entityFields?: GenericCRUDEntityFieldsInterface[];
    endpoint: string;
}

const GenericCRUD = (props: GenericCRUDPropsInterface) =>  {

    const [data, setData] = useState<any[]>([]);
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
    const [showModalUpdate, setShowModalUpdate] = useState<boolean>(false);
    const [modelEdit, setModelEdit] = useState<any>(null);
    const alerts = useAlerts();

    const handleCreateClick = () => {
        setShowModalCreate(true);
    }

    const handleUpdateClick = (model: any) => {
        setModelEdit(model);
        setShowModalUpdate(true);
    }

    const handleDeleteClick = (model: any) => {
        alerts.showAlertConfirm('warning', 'Confirmação', 'Deseja realmente excluir o "'+props.name+'" #'+model.id+'?', () => {
            //success
            genericService.delete(props.endpoint, model.id).then((response) => {
                fetchData();
            });
        },
        () => {
            //cancel
        });
    }

    const fetchData = () => {
        genericService.getAll(props.endpoint).then((response) => {
            if(response.data) {
                setData(response.data);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const getDisplayValueFromElement = (el: any, elFields: any) => {
        if(elFields.fieldType == 'select') {
            for(let i = 0; i < elFields.fieldSelectData.length; i++)
                if(elFields.fieldSelectData[i].value == el[elFields.fieldName])
                    return elFields.fieldSelectData[i].text;
        }else if(elFields.fieldType == 'password') {
            return '-';
        }else if(elFields.fieldName == 'price') {
            return 'R$ '+maskFloatToBRL(el[elFields.fieldName]);
        }
        return el[elFields.fieldName];
    }

    return (
        <>
            <Box className="text-xl text-medium text-gray-700">
                <div className="grid grid-cols-3 gap-1">
                    <div className="col-span-2">
                        <Title>
                            {props.name}
                        </Title>
                        {
                            props.subtitle &&
                            <Subtitle>{props.subtitle}</Subtitle>
                        }
                    </div>
                    <div className="add text-base text-right">
                        <Button type="primary" onClick={() => {handleCreateClick()}}>
                            <FiPlus className="inline mb-1 text-xl" /> Criar
                        </Button>
                    </div>
                </div>
            </Box>
            <Box className="table-container">
                <table className="border w-full text-base font-normal text-left">
                    <thead>
                        <tr>
                            <th className="p-1 border-r">#</th>
                            {
                                props.fields.filter((elFields) => (elFields.fieldType != 'password')).map((el, index) => <th key={index} className="p-1 border-r">{el.fieldInputLabel}</th>)
                            }
                            <th className="p-1">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((el: any, index) => 
                                <tr key={el.id} className="border-t">
                                    <td className="p-1 border-r">{el.id}</td>
                                    {
                                        props.fields.filter((elFields) => (elFields.fieldType != 'password')).map((elFields, indexFields) => 
                                            <td key={indexFields} className="p-1 border-r">{getDisplayValueFromElement(el, elFields)}</td>
                                        )
                                    }
                                    <td className="p-1">
                                        <button className="bg-white text-indigo-700" onClick={() => {
                                            handleUpdateClick(el)
                                        }}>
                                            <FiEdit2 className="inline mb-1"/> Alterar
                                        </button>
                                        <button className="bg-white ml-4 text-pink-600" onClick={() => {
                                            handleDeleteClick(el)
                                        }}>
                                            <FiTrash className="inline mb-1"/> Excluir
                                        </button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </Box>
            <GenericCRUDModal
                show={showModalCreate}
                name={props.name}
                handleClose={() => {
                    setShowModalCreate(false);
                }}
                handleCreateOrUpdate={() => {
                    fetchData();
                }}
                fields={props.fields}
                endpoint={props.endpoint}
                entityFields={props.entityFields ?? []}
            ></GenericCRUDModal>
            <GenericCRUDModal
                show={showModalUpdate}
                name={props.name}
                handleClose={() => {
                    setShowModalUpdate(false);
                    setModelEdit(null);
                }}
                modelEdit={modelEdit}
                handleCreateOrUpdate={() => {
                    fetchData();
                }}
                fields={props.fields}
                endpoint={props.endpoint}
                entityFields={props.entityFields ?? []}
            ></GenericCRUDModal>
        </>
    );

}

export default GenericCRUD;