import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthGuard } from "../auth/AuthGuard";
import ConfigPage from "../pages/auth/config/ConfigPage";
import UserPage from "../pages/auth/config/UserPage";

import APRPage from "../pages/auth/APR/APRPage";
import APRCreateAnalyzePage from "../pages/auth/APR/APRCreateAnalyzePage";
import APRCreateTopicPage from "../pages/auth/APR/APRCreateTopicPage";
import APRCreateQuestPage from "../pages/auth/APR/APRCreateQuestPage";

import DashboardPage from "../pages/auth/dashboard/DashboardPage";
import ComponentsPage from "../pages/auth/components/ComponentsPage";

import CBPage from "../pages/auth/CB/CBPage";
import CBCreateCollaboratorPage from "../pages/auth/CB/CBCreateCollaboratorPage";
import CBCreateTrainingPage from "../pages/auth/CB/CBCreateTrainingPage";
import CBCreateASOPage from "../pages/auth/CB/CBCreateASOPage";

import PTPage from "../pages/auth/PT/PTPage";
import PTCreatePage from "../pages/auth/PT/PTCreatePage";
import PTCreateTopicPage from "../pages/auth/PT/PTCreateTopicPage";
import PTCreateQuestPage from "../pages/auth/PT/PTCreateQuestPage";

import PRCompanyPage from "../pages/auth/PR/PRCompanyPage";
import PRSectorPage from "../pages/auth/PR/PRSectorPage";
import PRCreateSectorPage from "../pages/auth/PR/PRCreateSectorPage";
import PRCreateAreaPage from "../pages/auth/PR/PRCreateAreaPage";

import LBPage from "../pages/auth/LB/LBPage";
import LBModal from "../pages/auth/LB/LBModal";

import LogoutPage from "../pages/auth/LogoutPage";
import RLPermissonPage from "../pages/auth/RL/RLPermissonPage";
import RLAnalyzePage from "../pages/auth/RL/RLAnalyzePage";
import VNPage from "../pages/auth/VN/VNPage";
import LoginPage from "../pages/public/LoginPage";
import RecoverPage from "../pages/public/RecoverPage";

import CompaniesPage from "../pages/auth/companies/CompaniesPage";
import CompaniesCreatePage from "../pages/auth/companies/CompaniesCreatePage";

import RevenuesPage from "../pages/auth/revenues/RevenuesPage";
import PRCompanyCreateUnity from "../pages/auth/PR/PRCompanyCreateUnity";
import PRCreateCompanyArea from "../pages/auth/PR/PRCreateCompanyArea";
import PrCreateCompanySector from "../pages/auth/PR/PRCreateCompanySector";
import DuedNotificationsPage from '../pages/auth/VN/DuedNotifications';
import { CBUploadPhotoPage } from '../pages/auth/CBUploadPhoto/CBUploadPhotoPage';


const ProjectRouter = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <div className="content">
          <Switch>
            <Route exact path="/recover">
              <RecoverPage></RecoverPage>
            </Route>
            <Route exact path="/">
              <LoginPage></LoginPage>
            </Route>
            <Route exact path="/login">
              <LoginPage></LoginPage>
            </Route>
            <Route exact path="/auth/companies">
              <AuthGuard roles="ambra" render={<CompaniesPage />}></AuthGuard>
            </Route>
            <Route exact path="/auth/companies/create">
              <AuthGuard
                roles="ambra"
                render={<CompaniesCreatePage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/revenues">
              <AuthGuard roles="ambra" render={<RevenuesPage />}></AuthGuard>
            </Route>
            <Route exact path="/auth/APR">
              <AuthGuard roles="ambra|admin" render={<APRPage />}></AuthGuard>
            </Route>
            <Route exact path="/auth/APR/Analyze">
              <AuthGuard
                roles="ambra|admin"
                render={<APRCreateAnalyzePage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/APR/Topic">
              <AuthGuard
                roles="ambra|admin"
                render={<APRCreateTopicPage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/APR/Quest">
              <AuthGuard
                roles="ambra|admin"
                render={<APRCreateQuestPage />}
              ></AuthGuard>
            </Route>

            <Route exact path="/auth/PT">
              <AuthGuard roles="ambra|admin" render={<PTPage />}></AuthGuard>
            </Route>
            <Route exact path="/auth/PT/Create">
              <AuthGuard
                roles="ambra|admin"
                render={<PTCreatePage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/PT/Topic">
              <AuthGuard
                roles="ambra|admin"
                render={<PTCreateTopicPage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/PT/Quest">
              <AuthGuard
                roles="ambra|admin"
                render={<PTCreateQuestPage />}
              ></AuthGuard>
            </Route>

            <Route exact path="/auth/CB">
              <AuthGuard roles="ambra|admin" render={<CBPage />}></AuthGuard>
            </Route>
            <Route exact path="/auth/CBCollaborator">
              <AuthGuard
                roles="ambra|admin"
                render={<CBCreateCollaboratorPage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/reconhecimento-facial">
              <AuthGuard
                roles="ambra|admin"
                render={<CBUploadPhotoPage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/CBTraining">
              <AuthGuard
                roles="ambra|admin"
                render={<CBCreateTrainingPage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/CBASO">
              <AuthGuard
                roles="ambra|admin"
                render={<CBCreateASOPage />}
              ></AuthGuard>
            </Route>

            <Route exact path="/auth/PR">
              <AuthGuard
                roles="ambra|admin"
                render={<PRSectorPage />}
              ></AuthGuard>
            </Route>
          
            <Route exact path="/auth/PR/Company">
              <AuthGuard
                roles="ambra|admin"
                render={<PRCompanyPage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/PR/Area">
              <AuthGuard
                roles="ambra|admin"
                render={<PRCreateAreaPage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/PR/Unities">
              <AuthGuard
                roles="ambra|admin"
                render={<PRCompanyCreateUnity />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/PR/CreateArea">
              <AuthGuard
                roles="ambra|admin"
                render={<PRCreateCompanyArea />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/PR/CreateSectorPage">
              <AuthGuard
                roles="ambra|admin"
                render={<PrCreateCompanySector />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/PR/CreateSector">
              <AuthGuard
                roles="ambra|admin"
                render={<PRCreateSectorPage />}
              ></AuthGuard>
            </Route>

            <Route exact path="/auth/PG">
              <AuthGuard roles="ambra|admin" render={<UserPage />}></AuthGuard>
            </Route>
            <Route exact path="/auth/RL">
              <AuthGuard
                roles="ambra|admin"
                render={<RLPermissonPage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/RL/Analyze">
              <AuthGuard
                roles="ambra|admin"
                render={<RLAnalyzePage />}
              ></AuthGuard>
            </Route>
            <Route exact path="/auth/LB">
              <AuthGuard roles="ambra|admin" render={<LBPage />}></AuthGuard>
            </Route>
            <Route exact path="/auth/VN">
              <AuthGuard roles="ambra|admin" render={<VNPage />}></AuthGuard>
            </Route>
            <Route exact path="/auth/duedNotifications">
              <AuthGuard roles="ambra|admin" render={<DuedNotificationsPage />}></AuthGuard>
            </Route>
            <Route exact path="/auth/logout">
              <LogoutPage></LogoutPage>
            </Route>
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default ProjectRouter;
