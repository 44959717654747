import React, { useEffect, useState } from 'react'
import AmbraTable from '../../../components/elements/table/AmbraTable';
import AmbraInputFile from '../../../components/elements/form/AmbraInputFile';
import AmbraInputRadio from '../../../components/elements/form/AmbraInputRadio';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import Box from '../../../components/elements/Box';
import Button from '../../../components/elements/button/Button';
import Title from '../../../components/elements/text/Title';
import Subtitle from '../../../components/elements/text/Subtitle';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import Pagination from '../../../components/elements/pagination/Pagination';
import { useHistory } from 'react-router';
import useAlerts from '../../../hooks/useAlerts';
import userService from '../../../services/userService';

const CBPage = () => {
    
    const history = useHistory();
    const alerts = useAlerts();

    const [company, setCompany] = useState<string>('');
    const [data, setData] = useState<any>([]);
    const [updateCounter, setUpdateCounter] = useState<number>(0);

    useEffect(() => {
        let companyId = localStorage.getItem('company');
        if(companyId) {
            setCompany(companyId);
        }
    }, []);

    useEffect(() => {
        if(company !== '') {
            userService.list(company).then((response) => {
                if(response.status === 200) {
                    setData(transformData(response.data));
                }
            });
        }
    }, [updateCounter, company]);

    const destroyUser = (userId: string) => {
        alerts.showAlertConfirm('warning', 'Você realmente deseja excluir o colaborador?', 'Isso não poderá ser desfeito.', () => {
            userService.destroy(userId).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'O colaborador foi excluído com sucesso');
                    setUpdateCounter(updateCounter + 1);
                }
            });
        }, () => {});
    }

    const transformData = (data: any) => {
        for(let i = 0; i < data.length; i++) {
            if(data[i].active) {
                data[i].activeLabel = 'Sim';
            }else{
                data[i].activeLabel = 'Não';
            }
        }
        return data;
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Colaboradores </Title>
                    <Subtitle> Colaboradores </Subtitle> 
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                    <div>
                        <AmbraTable
                            title='Colaboradores'
                            data={data}
                            renderColumns={[
                                { label: 'Nome', dataColumn: 'name' },
                                { label: 'Ativo', dataColumn: 'activeLabel' }
                            ]}
                            showEdit={true}
                            showDelete={true}
                            showFile={false}
                            search={true}
                            onEdit={(element: any) => { history.push('/auth/CBCollaborator?id='+element.id) }}
                            onDelete={(element: any) => { destroyUser(element.id) }}
                            onFile={(element: any) => { console.log(element) }}
                            route='/auth/CBCollaborator'
                            renderTools={<>adicionar colaborador</>}
                        />
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default CBPage