import React, { useState, useEffect } from 'react'
import AmbraTable from '../../../components/elements/table/AmbraTable';
import AmbraInputFile from '../../../components/elements/form/AmbraInputFile';
import AmbraInputRadio from '../../../components/elements/form/AmbraInputRadio';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import Box from '../../../components/elements/Box';
import Button from '../../../components/elements/button/Button';
import Title from '../../../components/elements/text/Title';
import Subtitle from '../../../components/elements/text/Subtitle';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import Pagination from '../../../components/elements/pagination/Pagination';
import { RiSave2Fill, RiArrowLeftLine } from 'react-icons/ri';
import { useQuery } from '../../../hooks/useQuery';
import useAlerts from '../../../hooks/useAlerts';
import { useHistory } from 'react-router';
import asoService from '../../../services/asoService';
import { convertDateFromDdMmYyyyToAPI } from '../../../utils/general';
import { fromJSDateToViewMaskOnlyDate } from '../../../utils/masks';
import workPermissionsService from '../../../services/workPermissionsService';

const CBCreateASOPage = () => {
    const history = useHistory();
    const query = useQuery();
    const alerts = useAlerts();

    const [company, setCompany] = useState<string>('');
    const [editId, setEditId] = useState<string>('0');
    const [name, setName] = useState<string>('');
    const [isFitToFunction, setIsFitToFunction] = useState<boolean>(true);
    const [isFitToFunctionExceptional, setIsFitToFunctionExceptional] = useState<boolean>(true);
    const [emissionDate, setEmissionDate] = useState<string>('');
    const [expiryDate, setExpiryDate] = useState<string>('');
    const [exceptionalPermission, setExceptionalPermission] = useState<string>('');
    const [user, setUser] = useState<string>('');


    const [workPermissions, setWorkPermissions] = useState<any[]>([]);
    const [workPermissionsLoaded, setWorkPermissionsLoaded] = useState<any[]>([]);
    const [workPermissionsTmp, setWorkPermissionsTmp] = useState<any[]>([]);
    const [validSelected, setValidSelected] = useState<string>('');

    useEffect(() => {
        let companyId = localStorage.getItem('company');
        if (companyId) {
            setCompany(companyId);
        }
        let paramId = query.get('id');
        if (paramId) {
            setEditId(paramId);
        }
        let userId = query.get('userId');
        if(userId) {
            setUser(userId);
        }
    }, []);

    useEffect(() => {
        if (editId !== '0') {
            asoService.get(editId).then((response) => {
                if (response.status) {
                    getObjectFromRequest(response.data);
                }
            });
        }
    }, [editId]);

    useEffect(() => {
        if(company.length > 0) {
            workPermissionsService.list(company).then((response) => {
                if(response.status) {
                    let newWorkPermissions: any[] = [...response.data];
                    for(let i = 0; i < newWorkPermissions.length; i++) {
                        newWorkPermissions[i].checked = false;
                    }
                    setWorkPermissions( newWorkPermissions );
                    setWorkPermissionsTmp( newWorkPermissions );
                }
            });
        }
    }, [company]);

    useEffect(() => {
        if(workPermissionsTmp.length > 0 && workPermissionsLoaded.length > 0) {
            let ids: any[] = [];
            workPermissionsLoaded.map((el) => {
                ids.push(el.work_permission);
            });
            console.log(ids);
            let newWp = [...workPermissions];
            console.log(newWp);
            for(let i = 0; i < newWp.length; i++) {
                if(ids.indexOf(newWp[i].id) !== -1) {
                    newWp[i].checked = false;
                }else{
                    newWp[i].checked = true;
                }
            }
            console.log(newWp);
            setWorkPermissions(newWp);
        }
    }, [workPermissionsTmp, workPermissionsLoaded]);
    
    useEffect(() => {
        if(validSelected.length > 0) {
            let vInt = parseInt(validSelected);
            let expiryDateNew = new Date(convertDateFromDdMmYyyyToAPI(emissionDate));
            expiryDateNew.setMonth(expiryDateNew.getMonth()+vInt);
            let newDateString = ('0'+expiryDateNew.getDate()).slice(-2) + '/' + ('0'+(expiryDateNew.getMonth()+1)).slice(-2) + '/' + (expiryDateNew.getFullYear());
            // console.log(newDateString);
            setExpiryDate( newDateString );
        }
    }, [validSelected]);

    const getObjectFromRequest = (requestData: any) => {
        setName(requestData.name);
        setIsFitToFunction(requestData.is_fit_for_function);
        setIsFitToFunctionExceptional(requestData.is_fit_for_exceptional_permission);
        setEmissionDate(fromJSDateToViewMaskOnlyDate(requestData.emission_date));
        setExpiryDate(fromJSDateToViewMaskOnlyDate(requestData.expiry_date));
        setExceptionalPermission(requestData.exceptional_permissions);
        setCompany(requestData.company);
        setUser(requestData.user);
        setWorkPermissionsLoaded(requestData.work_permissions);
        setValidSelected(requestData.expiration_months);

        // let dateNow = new Date().getTime();
        // let dateExpiry = new Date().getTime();
        // setEmissionDate();
    }

    const handleStoreError = () => {
        alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar o ASO.');
    }

    const store = () => {
        let workPermissionsRequest: any[] = [];
        workPermissions.map((el) => {
            if(!el.checked) {
                workPermissionsRequest.push(el.id);
            }
        });
        let isFitToFunctionExceptionalRequest = isFitToFunctionExceptional;
        if(workPermissionsRequest.length === 0) {
            isFitToFunctionExceptionalRequest = false;
        }

        let requestData = {
            name: 'ASO',
            is_fit_for_function: isFitToFunction,
            is_fit_for_exceptional_permission: isFitToFunctionExceptional,
            exceptional_permissions: JSON.stringify(workPermissionsRequest),
            expiry_date: convertDateFromDdMmYyyyToAPI(expiryDate),
            emission_date: convertDateFromDdMmYyyyToAPI(emissionDate),
            company: company,
            user: user,
            work_permissions: workPermissionsRequest,
            expiration_months: validSelected
        };
        if (editId === '0') {
            asoService.store(requestData).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Sucesso', 'ASO salvo com sucesso');
                    history.push('/auth/CBCollaborator?id='+user);
                } else {
                    handleStoreError();
                }
            }).catch((error) => {
                handleStoreError();
            });
        } else {
            asoService.put(editId, requestData).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Sucesso', 'ASO salvo com sucesso');
                    history.push('/auth/CBCollaborator?id='+user);
                } else {
                    handleStoreError();
                }
            }).catch((error) => {
                handleStoreError();
            });
        }
    }

    const setWorkPermissionChecked = (index: number, checked: boolean) => {
        let wpCopy = [...workPermissions];
        wpCopy[index].checked = checked;
        setWorkPermissions(wpCopy);
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Adicionar ASO </Title>
                    <Subtitle> Colaboradores {'>'} Adicionar Colaborador {'>'} Adicionar ASO </Subtitle>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                    <div>
                        <AmbraInputCheckbox
                            label="Apto para a função?"
                            onChange={(e) => { setIsFitToFunction(!isFitToFunction) }}
                            checked={isFitToFunction}
                            value='Sim'
                        />
                    </div>
                    {/* <div>
                        <AmbraInputCheckbox
                            label="Apto para alguma permissão especial?"
                            onChange={(e) => { setIsFitToFunctionExceptional(!isFitToFunctionExceptional) }}
                            checked={isFitToFunctionExceptional}
                            value='Sim'
                        />
                    </div> */}
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <span className='text-red-500 font-medium'>Alguma restrição médica para atividade especial?</span><br />
                        {
                            workPermissions.map((el: any, index: number) =>
                                <AmbraInputCheckbox
                                label={''}
                                onChange={(e) => { setWorkPermissionChecked(index, !el.checked) }}
                                checked={el.checked}
                                value={el.name}
                            />
                            )
                        }
                    </div>
                </div>
                {/* {
                    isFitToFunctionExceptional &&
                    <>
                        <div className="grid grid-cols-3 lg:grid-cols-3 gap-5 mb-5">
                            <div>
                                <AmbraInputCheckbox
                                    label="Permissão especial"
                                    onChange={(e) => { setExceptionalPermission('high') }}
                                    checked={exceptionalPermission === 'high'}
                                    value='Trabalho em Altura'
                                />
                                <AmbraInputCheckbox
                                    label=""
                                    onChange={(e) => { setExceptionalPermission('warm') }}
                                    checked={exceptionalPermission === 'warm'}
                                    value='Trabalho á Quente'
                                />
                            </div>
                            <div>
                            </div>
                        </div>
                    </>
                } */}
                {
                    <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                        <div>
                            <AmbraInputText
                                label="Data de emissão"
                                placeholder="Ex. 20/10/2022"
                                validation="required|date"
                                mask='date'
                                value={emissionDate}
                                onChange={(e: any) => { setEmissionDate(e.target.value) }}
                            />
                        </div>
                    </div>
                }
                {
                    emissionDate.length === 10 &&
                    <>
                    <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                        <div>
                            <AmbraInputSelect
                                label="Prazo de validade"
                                placeholder="Selecione o prazo de validade"
                                data={[
                                    {
                                        text: '6 meses',
                                        value: '6'
                                    },
                                    {
                                        text: '12 meses',
                                        value: '12'
                                    },
                                    {
                                        text: '24 meses',
                                        value: '24'
                                    },
                                ]}
                                validation="required"
                                value={validSelected}
                                onChange={(e: any) => { setValidSelected(e.target.value) }}
                            />
                        </div>
                    </div>
                    </>
                }

                {/* {
                    editId !== '0' &&
                    <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                        <div>
                            <AmbraInputText
                                label="Vencimento"
                                placeholder="Ex. (20/10/2022)"
                                validation="required|date"
                                mask="date"
                                value={expiryDate}
                                onChange={(e: any) => { setExpiryDate(e.target.value) }}
                            />
                        </div>
                    </div>
                } */}

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div className='col-start-6'>
                        <Button type='primary' className='mr-2' onClick={() => { history.push('/auth/CBCollaborator?id='+user); }}>
                            <RiArrowLeftLine className="inline-block text-white-700" /> Voltar</Button>
                        <Button type='primary' className='ml-2' onClick={() => { store() }}>Salvar ASO <RiSave2Fill className="inline-block text-white-700" /></Button>
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default CBCreateASOPage