import React from 'react';
import packageJson from '../../../../package.json';

class PublicNavbar extends React.Component {

    render() {
        return (
            <div className="max-w-sm mx-auto grid grid-cols-2 font-semibold text-white text-2xl text-left">
                <div>
                    {process.env.REACT_APP_NAME}
                </div>
                <div className="text-right text-sm text-gray-300 mt-2">
                    <span className="">v{packageJson.version}</span>
                </div>
            </div>
        )
    }

}

export default PublicNavbar;