import Box from "../../elements/Box";

interface AlertPropsInterface {
    type: string;
    title: string;
    content: string;
    confirm: boolean;
    onAlertClose: () => void;
    onAlertConfirm: () => void;
    onAlertCancel: () => void;
}

const Alert = (props: AlertPropsInterface) => {

    let borderTopColor = 'border-gray-400';
    switch (props.type) {
        case 'success':
            borderTopColor = 'border-green-400';
            break;
        case 'error':
            borderTopColor = 'border-red-400';
            break;
        case 'warning':
            borderTopColor = 'border-yellow-400';
            break;
    }

    return (
        <>
            <div className="alert-modal">
                <div className="alert-container">
                    <Box className={'border-t-4 ' + borderTopColor}>
                        <div className="mb-4 text-xl font-medium">
                            {props.title}
                        </div>
                        <div className="mb-4 text-lg text-light">
                            {props.content}
                        </div>
                        <div className="text-center">
                            {
                                props.confirm ?
                                <div className="grid grid-cols-2 gap-1 text-center">
                                    <div>
                                        <button className="bg-gray-400 text-white py-2 px-4 rounded transition hover:bg-blue-700 hover:shadow" onClick={() => {
                                            props.onAlertCancel();
                                            props.onAlertClose();
                                        }}>
                                            Cancelar
                                        </button>
                                    </div>
                                    <div>
                                        <button className="bg-gray-700 text-white py-2 px-4 rounded transition hover:bg-blue-700 hover:shadow" onClick={() => {
                                            props.onAlertConfirm();
                                            props.onAlertClose();
                                        }}>
                                            Confirmar
                                        </button>
                                    </div>
                                </div>
                                :
                                <button className="bg-gray-700 text-white py-2 px-4 rounded transition hover:bg-blue-700 hover:shadow" onClick={() => {
                                    props.onAlertClose();
                                }}>
                                    OK
                                </button>
                            }
                            
                        </div>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default Alert;