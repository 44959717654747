import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AmbraTable from '../../../components/elements/table/AmbraTable';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import useAlerts from '../../../hooks/useAlerts';
import companiesService from '../../../services/companiesService';
import userService from '../../../services/userService';

const CompaniesPage = () => {
    
    const history = useHistory();
    const alerts = useAlerts();

    const [company, setCompany] = useState<string>('');
    const [data, setData] = useState<any>([]);
    const [updateCounter, setUpdateCounter] = useState<number>(0);

    useEffect(() => {
        let companyId = localStorage.getItem('company');
        if(companyId) {
            setCompany(companyId);
        }
    }, []);

    useEffect(() => {
        companiesService.list().then((response) => {
            if(response.status === 200) {
                setData(transformData(response.data));
            }
        });
    }, [updateCounter, company]);

    const destroyCompany = (companyId: string) => {
        alerts.showAlertConfirm('warning', 'Você realmente deseja excluir essa empresa?', 'Isso não poderá ser desfeito.', () => {
            companiesService.destroy(companyId).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'A empresa foi excluída com sucesso');
                    setUpdateCounter(updateCounter + 1);
                }
            });
        }, () => {});
    }

    const transformData = (data: any) => {
        for(let i = 0; i < data.length; i++) {
            if(data[i].active) {
                data[i].activeLabel = 'Sim';
            }else{
                data[i].activeLabel = 'Não';
            }
        }
        return data;
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title>Cadastro de Empresas</Title>
                    <Subtitle>Controle de clientes</Subtitle> 
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                    <div>
                        <AmbraTable
                            title='Empresas'
                            data={data}
                            renderColumns={[
                                { label: 'Nome', dataColumn: 'name' },
                                { label: 'Ativo', dataColumn: 'activeLabel' }
                            ]}
                            showEdit={true}
                            showDelete={true}
                            showFile={false}
                            search={true}
                            onEdit={(element: any) => { history.push('/auth/companies/create?id='+element.id) }}
                            onDelete={(element: any) => { destroyCompany(element.id) }}
                            onFile={(element: any) => { console.log(element) }}
                            route='/auth/companies/create'
                            renderTools={<>adicionar empresa</>}
                        />
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default CompaniesPage