import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AmbraTable from '../../../components/elements/table/AmbraTable';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import preliminaryRiskAnalysisService from '../../../services/preliminaryRiskAnalysisService';
import useAlerts from '../../../hooks/useAlerts';

const APRPage = () => {
    const history = useHistory();
    const alerts = useAlerts();

    const [data, setData] = useState<any>([]);
    const [companyId, setCompanyId] = useState<string>('');
    const [updateCounter, setUpdateCounter] = useState<number>(0);

    useEffect(() => {
        let company = localStorage.getItem('company');
        if(company) {
            setCompanyId(company);
        }
    }, []);

    useEffect(() => {
        if(companyId.length > 0) {
            preliminaryRiskAnalysisService.list(companyId).then((response) => {
                if(response.status === 200) {
                    setData(transformData(response.data));
                }
            });
        }
    }, [companyId, updateCounter]);

    const destroy = (aprId: number) => {
        alerts.showAlertConfirm('warning', 'Você realmente deseja excluir esta APR?', 'Essa ação não terá volta.', () => {
            preliminaryRiskAnalysisService.destroy(aprId).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Sua APR foi excluída com sucesso');
                    setUpdateCounter(updateCounter + 1);
                }
            });
        }, () => {});
    }

    const transformData = (data: any) => {
        for(let i = 0; i < data.length; i++) {
            //manipulate data operations
            if(data[i].active) {
                data[i].activeLabel = 'Sim';
            }else{
                data[i].activeLabel = 'Não';
            }
            if(data[i].is_draft) {
                data[i].isDraftLabel = 'Sim';
            }else{
                data[i].isDraftLabel = 'Não';
            }
        }
        return data;
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title>APR - Análise Preliminar de riscos</Title>
                    <Subtitle>Análise Preliminar de Riscos</Subtitle> 
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                    <div>
                        <AmbraTable
                            title='Análises preliminares de riscos'
                            data={data}
                            renderColumns={[
                                { label: 'Nome', dataColumn: 'name' },
                                { label: 'Ativa', dataColumn: 'activeLabel' },
                                { label: 'Rascunho', dataColumn: 'isDraftLabel' }
                            ]}
                            showEdit={true}
                            showDelete={true}
                            showFile={false}
                            search={true}
                            onEdit={(element: any) => { history.push('/auth/APR/Analyze?id='+element.id) }}
                            onDelete={(element: any) => { destroy(element.id) }}
                            onFile={(element: any) => { console.log(element) }}
                            route='/auth/APR/Analyze'
                            renderTools={<>Adicionar análise</>}
                        />
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default APRPage