import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Button from '../../components/elements/button/Button';
import AmbraInputCheckboxLogin from '../../components/elements/form/AmbraInputCheckboxLogin';
import AmbraInputText from '../../components/elements/form/AmbraInputText';
import useAlerts from '../../hooks/useAlerts';
import useAuth from '../../hooks/useAuth';
import userService from '../../services/userService';
import { loggedUserState } from '../../state/authState';
import { inventoryDBState } from '../../state/inventoryState';

interface LoginPageProps {
}

const LoginPage = (props: LoginPageProps) => {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const { loggedUser, setLoggedUser, isLoggedIn, token, setToken } = useAuth();
    const [inventoryDB, setInventoryDB] = useRecoilState(inventoryDBState);
    const [loggedUserRecoil, setLoggedUserRecoil] = useRecoilState(loggedUserState);
    const [changePasswordMode, setChangePasswordMode] = useState(false);

    const [loggedUserId, setLoggedUserId] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');

    const [remember, setRemember] = useState<boolean>(false);

    let history = useHistory();
    const alerts = useAlerts();

    useEffect(() => {
        if (localStorage.rememberLogin) {
            setLogin(atob(localStorage.rememberLogin));
        }
        if (localStorage.rememberLogin && localStorage.rememberPassword) {
            setLogin(atob(localStorage.rememberLogin));
            setPassword(atob(localStorage.rememberPassword));
            setRemember(true);
        }
    }, []);

    const redirectAdmin = () => {
        history.push('/auth/PR/Company');
    }

    const redirectAmbra = () => {
        history.push('/auth/companies');
    }

    const handleLoginBtn = (e: any) => {
        // if(login === 'empresa@ambrapass.com.br' && password === 'pass@2022') {
        //     setToken('TODO-TOKENDEACESSO');
        //     localStorage.setItem('token', 'TODO-TOKENDEACESSO');
        //     setLoggedUserRecoil({ role: 'user', email: 'empresa@ambrapass.com.br', name: 'Empresa Ambra' });
        // }else{
        //     alerts.showAlert('error', 'Acesso não autorizado', 'Por favor, revise as suas credenciais e tente novamente.');
        // }
        userService.signIn(login, password).then((response) => {

            if (response.data?.token) {
                localStorage.setItem('rememberLogin', btoa(login));
                if (remember && btoa) {
                    localStorage.setItem('rememberPassword', btoa(password));
                }
                setToken(response.data.token);
                localStorage.setItem('token', response.data.token);

                if (!response.data?.user?.password_changed_at) {
                    setLoggedUserId(response.data?.user?.id);
                    setChangePasswordMode(true);
                    return;
                }

                //console.log(response.data.user.scopes);
                if (response.data.user.scopes == 'ambra_admin') {
                    localStorage.setItem('company', response.data.user.company);
                    setLoggedUserRecoil({ email: login, role: 'ambra' });
                    localStorage.setItem('loggedUser', JSON.stringify({ email: login, role: 'ambra', company: response.data.user.company }));
                    redirectAmbra();
                }
                if (response.data.user.scopes == 'company_admin' || response.data.user.scopes == 'company_grantor') {
                    localStorage.setItem('company', response.data.user.company);
                    setLoggedUserRecoil({ email: login, role: 'admin' });
                    localStorage.setItem('loggedUser', JSON.stringify({ email: login, role: 'admin', company: response.data.user.company }));
                    console.log(localStorage.setItem('loggedUser', JSON.stringify({ email: login, role: 'admin', company: response.data.user.company })));
                    redirectAdmin();
                }
            } else {
                alerts.showAlert('error', 'Acesso não autorizado', 'Por favor, revise as suas credenciais e tente novamente.');
            }
        }).catch((error) => {
            if (error.response?.status === 500)
                alerts.showAlert('error', 'Acesso não autorizado', 'Por favor, revise as suas credenciais e tente novamente.');
        });
    }

    const passwordOnKeyUp = (event: any) => {
        if (event.keyCode == 13) {
            handleLoginBtn(null);
        }
    }

    const handlePasswordChange = () => {
        setPassword(newPassword);
        console.log(loggedUserId);
        userService.changePassword(loggedUserId, newPassword).then((response) => {
            alerts.showAlert('success', 'Senha alterada com sucesso', 'A senha foi definida. Você será redirecionado');
            setTimeout(() => {
                history.go(0);
            }, 3000);
        });
    }

    return (
        // <div className="container m-auto pt-36">
        //     <PublicNavbar></PublicNavbar>
        //     <div className="max-w-sm mx-auto shadow-lg p-4 rounded bg-white px-4 text-left">
        //         <div className="mt-4">
        //             <label className="text-lg text-semibold text-gray-700">Usuário</label>
        //             <input value={login} onChange={(e: any) => {setLogin(e.target.value)}} className="appearance-none border-solid p-2 text-lg text-gray-600 rounded border block w-full shadow outline-none focus:outline-none focus:shadow-lg transition-all duration-300" placeholder="Nome de usuário" type="text"></input>
        //         </div>
        //         <div className="mt-2">
        //             <label className="text-lg text-semibold text-gray-700">Senha</label>
        //             <input value={password} onChange={(e: any) => {setPassword(e.target.value)}} className="appearance-none border-solid p-2 text-lg text-gray-600 rounded border block w-full shadow outline-none focus:outline-none focus:shadow-lg transition-all duration-300" type="password" placeholder="Senha"  onKeyUp={(event: any) => {passwordOnKeyUp(event)}}></input>
        //         </div>
        //         <div className="grid grid-cols-2 gap-1">
        //             <div className="mt-2 text-gray-700">
        //                 <label className="cursor-pointer" onClick={() => {setRemember(!remember)}}>
        //                     <FontAwesomeIcon className="text-gray-500 transition-all" icon={remember ? faCheckSquare : faSquare } /> Lembrar
        //                 </label>
        //             </div>
        //             <div className="mt-2 text-right text-base text-blue-600">
        //                 <Link to="recover">Esqueci minha senha</Link>
        //             </div>
        //         </div>
        //         <div className="mt-4">
        //             <button className="p-2 text-xl text-white bg-indigo-600 text-center rounded block w-full shadow transition-all hover:bg-blue-600 duration-300" onClick={(event:any) => {handleLoginBtn(event)}}>Acessar</button>
        //         </div>
        //     </div>
        // </div>
        <div className="grid grid-cols-2 gap-0 bg-ambra-orangeLight font-family-sans-serif" style={{ fontFamily: "system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'" }}>
            <div style={{ backgroundImage: 'url(\'/assets/login_bg.png\')', height: '100vh' }} className="col-span-1 bg-cover bg-local bg-center bg-no-repeat px-10">
                <h1 className='login-title'>O primeiro software de PT/APR do Brasil</h1>
                <h2 className='login-subtitle'>Garanta a agilidade no processo de geração, assinatura e liberação do serviço, aumentando a produtividade da equipe.</h2>
            </div>
            <div className="col-span-1 bg-ambra-orange-light color-ambra-gray pt-28 px-10">
                <img src="/assets/logo.png" className="mx-auto max-w-full" />
                <div className='mt-10'>
                    <AmbraInputText
                        label='E-mail'
                        value={login}
                        onChange={(e: any) => { setLogin(e.target.value) }}
                        placeholder='email@mail.com'
                    />
                </div>
                <div className='mt-2'>
                    <AmbraInputText
                        label='Senha'
                        value={password}
                        onChange={(e: any) => { setPassword(e.target.value) }}
                        placeholder='Digite a senha'
                        type='password'
                    />
                </div>
                <div className='mt-2'>
                    <AmbraInputCheckboxLogin
                        label='Lembrar senha'
                        // onKeyUp={(event: any) => { passwordOnKeyUp(event) }}
                        onChange={(e) => { setRemember(!remember) }}
                        checked={remember}
                        value=''
                    />
                </div>
                {
                    changePasswordMode ?
                        <>
                            <div className='mt-2'>
                                <AmbraInputText
                                    label='Primeiro acesso - Nova Senha'
                                    value={newPassword}
                                    onChange={(e: any) => { setNewPassword(e.target.value) }}
                                    placeholder='Digite a senha'
                                    type='password'
                                    validation='required|min:8'
                                />
                            </div>

                            <div className='mt-2'>
                                <AmbraInputText
                                    label='Confirme a nova senha'
                                    value={newPasswordConfirm}
                                    onChange={(e: any) => { setNewPasswordConfirm(e.target.value) }}
                                    placeholder='Digite a senha'
                                    type='password'
                                    validation='required|min:8'
                                />
                            </div>

                            {
                                newPassword !== newPasswordConfirm &&
                                <div className='text-red-500 text-right font-normal'>
                                    As senhas não conferem.
                                </div>
                            }
                            <Button type='primary' className='w-full mt-8' onClick={() => { handlePasswordChange() }}>Alterar senha</Button>
                        </>
                        :
                        <>
                            <Button type='primary' className='w-full mt-8' onClick={() => { handleLoginBtn(null) }}>Entrar</Button>
                        </>
                }

            </div>
        </div>
    )

}

export default LoginPage;