import React, { useState, useEffect } from "react";
import AmbraTable from "../../../components/elements/table/AmbraTable";
import AmbraInputFile from "../../../components/elements/form/AmbraInputFile";
import AmbraInputRadio from "../../../components/elements/form/AmbraInputRadio";
import AmbraInputSelect from "../../../components/elements/form/AmbraInputSelect";
import AmbraInputText from "../../../components/elements/form/AmbraInputText";
import AmbraInputCheckbox from "../../../components/elements/form/AmbraInputCheckbox";
import Box from "../../../components/elements/Box";
import Button from "../../../components/elements/button/Button";
import Title from "../../../components/elements/text/Title";
import Subtitle from "../../../components/elements/text/Subtitle";
import AuthContainer from "../../../components/layout/auth/AuthContainer";
import Pagination from "../../../components/elements/pagination/Pagination";
import releasersService from "../../../services/releasersService";
import useAlerts from "../../../hooks/useAlerts";
import { useQuery } from "../../../hooks/useQuery";
import { RiSave2Fill, RiArrowLeftLine, RiDeleteBin7Fill } from "react-icons/ri";
import { useHistory } from "react-router";
import fieldsService from "../../../services/fieldsService";
import sectorsService from "../../../services/sectorsService";
import trainingsService from "../../../services/trainingServices";
import userService from "../../../services/userService";
import asoService from "../../../services/asoService";
import { fromJSDateToViewMaskOnlyDate } from "../../../utils/masks";
import unityServices from "../../../services/unityServices";
import ReactLoading from 'react-loading';

const CBCreateCollaboratorPage = () => {
  const history = useHistory();
  const query = useQuery();
  const alerts = useAlerts();
  const [loading, setLoading] = useState<boolean>(false);

  //user data
  const [editId, setEditId] = useState<string>("0");
  const [company, setCompany] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [companyIdentificator, setCompanyIdentificator] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [nameCompany, setNameCompany] = useState<string>("");
  const [unityCompany, setUnityCompany] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");
  const [IsThirdEmployee, setIsThirdEmployee] = useState<boolean>(false);
  const [isReleaser, setIsReleaser] = useState<boolean>(false);
  const [isAreaResponsible, setIsAreaResponsible] = useState<boolean>(false);
  const [field, setField] = useState<string>("");
  const [sector, setSector] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [releasers, setReleasers] = useState<string[]>([]);
  const [fields, setFields] = useState<string[]>([]);
  const [isMedic, setIsMedic] = useState<boolean>(false);

  const [sectorTypeSelectData, setSectorTypelectData] = useState<any[]>([]);
  const [sectors, setSectors] = useState<any[]>([]);
  const [liberatorType, setLiberatorType] = useState<string>("");
  const [liberatorTypeSelectData, setLiberatorTypeSelectData] = useState<any[]>(
    []
  );
  const [areas, setAreas] = useState<any[]>([]);
  const [areaTypeSelectData, setAreaTypeSelectData] = useState<any[]>([]);
  const [unityData, setUnityData] = useState<any[]>([]);
  const [unitySelectData, setUnitySelectData] = useState<any[]>([]);
  const [releasersData, setReleaserData] = useState<any[]>([]);
  const [fieldsData, setFieldsData] = useState<any[]>([]);

  const [terms, setTerms] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");
  const [updateCounter, setUpdateCounter] = useState<number>(0);
  const [training, setTraining] = useState<any>([]);
  const [aso, setAso] = useState<any>([]);

  useEffect(() => {
    let company = localStorage.getItem("company");
    if (company) {
      setCompany(company);
    }
    let id = query.get("id");
    if (id) {
      setEditId(id);
    }
  }, []);

  useEffect(() => {
    if (editId !== "0") {
      userService.get(editId).then((response) => {
        if (response.status === 200) {
          loadObjectFromRequest(response.data);
          console.log(response.data);
        }
      });
      trainingsService.listByUser(editId).then((response) => {
        if (response.status === 200) {
          setTraining(transformData(response.data));
        }
      });
      asoService.listByUser(editId).then((response) => {
        if (response.status === 200) {
          setAso(transformData(response.data));
        }
      });
    }
  }, [editId, updateCounter]);

  useEffect(() => {
    if (company && company.length > 0) {
      unityServices.list(company).then((response) => {
        if (response.status === 200) {
          setUnityData(response.data);
        }
      });
      releasersService.list(company).then((response) => {
        if (response.status === 200) {
          setReleaserData(response.data);
        }
      });
    }
  }, [company]);

  useEffect(() => {
    if (unityCompany && unityCompany.length > 0) {
      fieldsService.list(unityCompany).then((response) => {
        if (response.status === 200) {
          setAreas(response.data);
        }
      });
    }
  }, [unityCompany]);

  useEffect(() => {
    let areasSelect: any[] = [];
    areas.map((el) => {
      areasSelect.push({
        text: el.name,
        value: el.id,
      });
    });
    setAreaTypeSelectData(areasSelect);
  }, [areas]);

  useEffect(() => {
    let sectorsSelect: any[] = [];
    sectors.map((el) => {
      sectorsSelect.push({
        text: el.name,
        value: el.id,
      });
    });
    setSectorTypelectData(sectorsSelect);
  }, [sectors]);

  useEffect(() => {
    if (unityData) {
      let selectData: any[] = [];
      unityData.map((el) => {
        selectData.push({
          text: el.name,
          value: el.id
        })
      });
      setUnitySelectData(selectData);
    }
  }, [unityData]);

  const loadObjectFromRequest = (requestData: any) => {
    setName(requestData.name);
    setEmail(requestData.email);
    setCpf(requestData.cpf);
    setCnpj(requestData.cnpj);
    setField(requestData.field);
    setSector(requestData.sector);
    setIsAreaResponsible(requestData.is_responsible);
    setIsMedic(requestData.is_medic);
    setIsReleaser(requestData.releasers_list.length > 0);
    setLiberatorType(requestData.liberatorType);
    setIsThirdEmployee(requestData.IsThirdEmployee);
    setPosition(requestData.position);
    setCompanyIdentificator(requestData.company_identificator);
    setCpf(requestData.cpf);
    setUnityCompany(requestData.unity);
    let fieldsList: any[] = [];
    requestData.fields_list.map((el: any) => {
      fieldsList.push(el.field_object);
    });
    setFields(fieldsList);
    let releasersList: any[] = [];
    requestData.releasers_list.map((el: any) => {
      releasersList.push(el.releaser);
    });
    setReleasers(releasersList);
  };

  const handleStoreError = (message:string = "Ocorreu um erro ao salvar o Colaborador.") => {
    if(message.toLowerCase().indexOf('e-mail') !== -1)
      message = 'E-mail inválido ou já existente no sistema';
    alerts.showAlert(
      "error",
      "Ops",
      message
    );
  };

  const storeCollaboratorCB = (redirect: boolean) => {
    //TODO: realiza o store da APR
    let requestData: any = {
      name: name,
      is_thirdy_party: IsThirdEmployee,
      company_name: IsThirdEmployee ? nameCompany : null,
      company_cnpj: IsThirdEmployee ? cnpj : null,
      email: email,
      unity: unityCompany,
      company: company,
      active: true,
      is_responsible: isAreaResponsible,
      is_releaser: isReleaser,
      is_medic: isMedic,
      releasers: releasers,
      fields: fields.map((el: any) => {
        return el.id + "";
      }),
      company_identificator: companyIdentificator,
      position: position,
      cpf: cpf,
    };
    if (password.length > 0) {
      requestData.password = password;
    }
    if (editId === "0") {
      userService
        .signupCompanyEmployee(requestData)
        .then((response) => {
          if (response.status) {
            alerts.showAlert(
              "success",
              "Sucesso",
              "Colaborador salvo com sucesso"
            );
            setLoading(false);
            if (redirect) history.push("/auth/CB");
            else setEditId(response.data.user.id);
          } else {
            handleStoreError();
            setLoading(false);
          }
        })
        .catch((error) => {
          if(error.response?.data?.error) {
            handleStoreError(error.response.data.error);
          }else{
            handleStoreError();
          }
          setLoading(false);
        });
    } else {
      userService
        .put(editId, requestData)
        .then((response) => {
          if (response.status) {
            alerts.showAlert(
              "success",
              "Sucesso",
              "Colaborador salvo com sucesso"
            );
            if (redirect) history.push("/auth/CB");
          } else {
            handleStoreError();
          }
        })
        .catch((error) => {
          handleStoreError();
        });
    }
  };

  const transformData = (data: any) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].active) {
        data[i].activeLabel = "Sim";
      } else {
        data[i].activeLabel = "Não";
      }
      if (data[i].expiry_date) {
        data[i].expiryDateLabel = fromJSDateToViewMaskOnlyDate(
          data[i].expiry_date
        );
        if (new Date().getTime() > new Date(data[i].expiry_date).getTime()) {
          data[i].status = "Vencido";
        } else {
          data[i].status = "Válido";
        }
      } else {
        data[i].expiryDateLabel = "";
        data[i].status = "Válido";
      }
      if (data[i].is_fit_for_function) {
        data[i].fitForFunctionLabel = "Sim";
      } else {
        data[i].fitForFunctionLabel = "Não";
      }
    }
    return data;
  };

  const destroyTraining = (traningId: string) => {
    alerts.showAlertConfirm(
      "warning",
      "Você realmente deseja excluir o treinamento?",
      "Isso não poderá ser desfeito.",
      () => {
        trainingsService.destroy(traningId).then((response) => {
          if (response.status) {
            alerts.showAlert(
              "success",
              "Sucesso",
              "O treinamento foi excluído com sucesso"
            );
            setUpdateCounter(updateCounter + 1);
          }
        });
      },
      () => { }
    );
  };

  const destroyASO = (asoId: string) => {
    alerts.showAlertConfirm(
      "warning",
      "Você realmente deseja excluir o ASO?",
      "Isso não poderá ser desfeito.",
      () => {
        asoService.destroy(asoId).then((response) => {
          if (response.status) {
            alerts.showAlert(
              "success",
              "Sucesso",
              "O ASO foi excluído com sucesso"
            );
            setUpdateCounter(updateCounter + 1);
          }
        });
      },
      () => { }
    );
  };

  return (
    <AuthContainer>
      <div className="m-3 mt-4 mb-4">
        <div className="mb-4">
          <Title> Adicionar Colaborador </Title>
          <Subtitle> Colaboradores {">"} Adicionar Colaborador</Subtitle>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
          <div>
            <AmbraInputText
              label="Nome*"
              placeholder="Digite um Nome"
              value={name}
              validation="required"
              onChange={(e: any) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
          <div>
            <AmbraInputText
              label="Email*"
              placeholder="Digite um e-mail"
              value={email}
              validation="required|email"
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
          <div>
            <AmbraInputText
              label="Senha* (min. 8 caracteres)"
              placeholder={editId !== "0" ? "(INALTERADA)" : "Digite a senha"}
              validation={
                editId !== "0" ? "unrequired|min:8" : "required|min:8"
              }
              value={password}
              key={"reload_pw_cb"}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
          <div>
            <AmbraInputText
              label="Matrícula"
              placeholder="000"
              validation="number"
              value={companyIdentificator}
              onChange={(e: any) => {
                setCompanyIdentificator(e.target.value);
              }}
            />
          </div>
          <div>
            <AmbraInputText
              label="CPF"
              placeholder="000.000.000-00"
              validation="cpfCnpj"
              mask="cpfCnpj"
              value={cpf}
              onChange={(e: any) => {
                setCpf(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
          <div>
            <AmbraInputText
              label="Cargo"
              placeholder="Digite o cargo"
              validation="required"
              value={position}
              onChange={(e: any) => {
                setPosition(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
          <div>
            <AmbraInputCheckbox
              label="Funcionário terceiro?"
              onChange={(e) => {
                setIsThirdEmployee(!IsThirdEmployee);
              }}
              checked={IsThirdEmployee}
              value="Sim"
            />
          </div>
          <div>
            {IsThirdEmployee && (
              <AmbraInputText
                label="(TERCEIRO) Nome da empresa"
                placeholder="Digite o nome da empresa"
                value={nameCompany}
                onChange={(e: any) => {
                  setNameCompany(e.target.value);
                }}
              />
            )}
          </div>
        </div>

        {IsThirdEmployee && (
          <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
            <div>
              <AmbraInputText
                label="(TERCEIRO) CNPJ da empresa"
                placeholder="00.000.000/0000-00"
                validation="cpfCnpj"
                mask="cpfCnpj"
                value={cnpj}
                onChange={(e: any) => {
                  setCnpj(e.target.value);
                }}
              />
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5 pt-2">
          <div className="col-span-2">
            <AmbraInputSelect
              label="Selecionar a unidade (o colaborador poderá participar de atividades em outras unidades)"
              placeholder="Selecione a unidade do colaborador"
              value={unityCompany}
              data={unitySelectData}
              onChange={(e: any) => {
                setField('');
                setFields([]);
                setUnityCompany(e.target.value);
              }}
            />
          </div>
        </div>

        {
          unityCompany !== null && unityCompany?.length > 0 &&
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5 pt-2">
            <div className="col-span-2">
              <AmbraInputSelect
                label="Adicionar áreas"
                placeholder="Selecione a área"
                value={field}
                data={areaTypeSelectData}
                onChange={(e: any) => {
                  setField(e.target.value);
                }}
              />
            </div>
            <div className="pt-6">
              <Button
                type="primary"
                onClick={() => {
                  let selectedArea = areas.find((el) => {
                    return el.id === field;
                  });
                  //console.log(selectedArea);
                  if (fields.indexOf(selectedArea) === -1) {
                    let fieldsCopy = [...fields, selectedArea];
                    setFields(fieldsCopy);
                  }
                }}
              >
                + Adicionar área
              </Button>
            </div>
          </div>
        }

        {
          fields.map((el: any, index: number) => (
            <div key={index}>
              {el.name}{" "}
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={(e: any) => {
                  let fieldsCopy = [...fields];
                  fieldsCopy.splice(index, 1);
                  setFields(fieldsCopy);
                }}
              >
                (Remover <RiDeleteBin7Fill style={{ display: "inline" }} />)
              </span>
            </div>
          ))
        }



        <div>
          <div className="grid grid-cols-2 lg:gris-cols-2 gap-5 mb-5">
            <div>
              <AmbraInputCheckbox
                label="Permissões"
                value="Encarregado de área"
                onChange={(e) => {
                  setIsAreaResponsible(!isAreaResponsible);
                }}
                checked={isAreaResponsible}
              />
              <AmbraInputCheckbox
                value="Liberador/Facilitador"
                label=""
                onChange={(e) => {
                  setIsReleaser(!isReleaser);
                }}
                checked={isReleaser}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="grid grid-cols-2 lg:gris-cols-2 gap-5 mb-5">
            <div>
              <AmbraInputCheckbox
                label="Perfil Profissional de Sáude"
                value="Acesso a área do profissional de saúde"
                onChange={(e) => {
                  setIsMedic(!isMedic);
                }}
                checked={isMedic}
              />
            </div>
          </div>
        </div>

        {isReleaser && (
          <>
            <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
              <div>
                {releasersData.map((el, index) => (
                  <AmbraInputCheckbox
                    key={index}
                    value={el.name + ' (' + el.unity_name + ')'}
                    label={index === 0 ? "Tipo de liberador" : ""}
                    onChange={(e) => {
                      let indexChange = releasers.indexOf(el.id);
                      let relCopy = [...releasers];
                      if (indexChange === -1) {
                        relCopy.push(el.id);
                      } else {
                        relCopy.splice(indexChange, 1);
                      }
                      setReleasers(relCopy);
                    }}
                    checked={releasers.indexOf(el.id) !== -1}
                  />
                ))}
              </div>
            </div>
          </>
        )}

        {editId !== "0" && (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-6 mb-6">
              <div>
                <AmbraTable
                  title="Treinamentos"
                  data={training}
                  renderColumns={[
                    { label: "Nome do treinamento", dataColumn: "name" },
                    { label: "Validade", dataColumn: "expiryDateLabel" },
                  ]}
                  showEdit={true}
                  showDelete={true}
                  showFile={false}
                  rowClassNameFn={(el: any) => {
                    if (el.status === "Vencido")
                      return "text-red-500 font-medium bg-red-100";
                    return "";
                  }}
                  search={false}
                  onEdit={(element: any) => {
                    history.push("/auth/CBTraining?id=" + element.id);
                  }}
                  onDelete={(element: any) => {
                    destroyTraining(element.id);
                  }}
                  onFile={(element: any) => {
                    console.log(element);
                  }}
                  route={"/auth/CBTraining?userId=" + editId}
                  renderTools={<>adicionar treinamento</>}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-6 mb-6">
              <div>
                <AmbraTable
                  title="Atestados de saúde ocupacional"
                  data={aso}
                  renderColumns={[
                    {
                      label: "Status",
                      dataColumn: "status",
                      classNameFn: (el: any) => {
                        console.log(el);
                        if (el === "Vencido") return "text-red-500 font-medium";
                        return "";
                      },
                    },
                    {
                      label: "Apto para a função",
                      dataColumn: "fitForFunctionLabel",
                    },
                    { label: "Validade", dataColumn: "expiryDateLabel" },
                  ]}
                  rowClassNameFn={(el: any) => {
                    if (el.status === "Vencido")
                      return "text-red-500 font-medium bg-red-100";
                    return "";
                  }}
                  showEdit={true}
                  showDelete={true}
                  showFile={false}
                  search={false}
                  onEdit={(element: any) => {
                    history.push("/auth/CBASO?id=" + element.id);
                  }}
                  onDelete={(element: any) => {
                    destroyASO(element.id);
                  }}
                  onFile={(element: any) => {
                    console.log(element);
                  }}
                  route={"/auth/CBASO?userId=" + editId}
                  renderTools={<>adicionar ASO</>}
                />
              </div>
            </div>
          </>
        )}

        <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
          {
            loading ?
              <ReactLoading type={"spin"} color={"#FED281"} height={'5vh'} width={'5vh'} />
              :
              <div className="col-start-6">
                <Button
                  type="primary"
                  className="mr-2"
                  onClick={() => {
                    history.push("/auth/CB");
                  }}
                >
                  <RiArrowLeftLine className="inline-block text-white-700" /> Voltar
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  onClick={() => {
                    setLoading(true);
                    storeCollaboratorCB(true);
                  }}
                >
                  Salvar <RiSave2Fill className="inline-block text-white-700" />
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  onClick={() => {
                    setLoading(true);
                    storeCollaboratorCB(false);
                  }}
                >
                  Salvar e adicionar ASO/Treinamento{" "}
                  <RiSave2Fill className="inline-block text-white-700" />
                </Button>
              </div>
          }
        </div>
      </div>
    </AuthContainer>
  );
};

export default CBCreateCollaboratorPage;
