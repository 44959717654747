import { AxiosResponse } from "axios";
import api from "./api";

interface reportServiceInterface {
    apr: (companyId: number|string, page: number|string, code:string, date: string, user: string, activity: string) => Promise<AxiosResponse<any>>;
    pt: (companyId: number|string, page: number|string, code:string, date: string, user: string, activity: string) => Promise<AxiosResponse<any>>;
    denied: (companyId: number|string, page: number|string) => Promise<AxiosResponse<any>>;
}

const prefix = 'report';

const reportService: reportServiceInterface = {
    apr: (companyId: number|string, page: number|string, code:string, date: string, user: string, activity: string) => 
        api.get('/report/apr/'+companyId+'?page='+page+'&code='+code+'&date='+date+'&user='+user+'&activity='+activity),
    pt: (companyId: number|string, page: number|string, code:string, date: string, user: string, activity: string) => 
        api.get('/report/pt/'+companyId+'?page='+page+'&code='+code+'&date='+date+'&user='+user+'&activity='+activity),
    denied: (companyId: number|string, page: number|string) => 
        api.get('/report/denieds/'+companyId+'?page='+page),
}

export default reportService;