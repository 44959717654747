import axios from "axios";

interface cepServiceInterface {
    getAddressFromCEP: (cep: string) => any
}

const cepService: cepServiceInterface = {
    getAddressFromCEP: (cep: string) => 
        axios.get('https://viacep.com.br/ws/'+cep+'/json')
};

export default cepService;