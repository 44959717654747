import { AxiosResponse } from "axios";
import api from "./api";

interface fieldsServiceInterface {
    list: (unity: number|string) => Promise<AxiosResponse<any>>;
    store: (data: fieldsData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: fieldsData) => Promise<AxiosResponse<any>>;
}

interface fieldsData {
    id?: string;
    name?: string;
    company?: string;
    unity:string;
    active?: boolean;
}

const prefix = 'fields';

const fieldsService: fieldsServiceInterface = {
    list: (unity: number|string) => 
        api.get('/company/all_'+prefix+'/'+unity),
    store: (data: fieldsData) => 
        api.post('/company/'+prefix, data),
    get: (id: number|string) => 
        api.get('/company/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/company/'+prefix+'/'+id),
    put: (id: number|string, data: fieldsData) =>
        api.put('/company/'+prefix+'/'+id, data)
}

export default fieldsService;