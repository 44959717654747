import api from "./api";

interface fileServiceInterface {
    uploadFile: (formData: FormData) => any
}

const fileService: fileServiceInterface = {
    uploadFile: (formData: FormData) => 
        api.post('files/upload', formData, {
            headers : {
                'Content-Type': 'multipart/form-data'
            }
        })
};

export default fileService;