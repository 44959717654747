import React from 'react';
import AuthNavbar from './AuthNavbar';

class AuthContainer extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="container-auth">
                <AuthNavbar></AuthNavbar>
                <div className="content-auth bg-ambra-white">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default AuthContainer;