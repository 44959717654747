import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { FiX } from 'react-icons/fi'

interface ModalLgProps {
    show: boolean;
    handleClose: () => void;
}

export default class ModalLg extends Component<ModalLgProps> {
    render() {
        return (
            <div className={'modal-lg ' + (this.props.show ? 'show' : 'hidden')}>
                <div className="modal-lg-container bg-ambra-white">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
