import { useEffect, useState } from "react";
import { RiArrowLeftLine, RiSave2Fill } from "react-icons/ri";
import { useHistory } from "react-router";
import Button from "../../../components/elements/button/Button";
import AmbraInputCheckbox from "../../../components/elements/form/AmbraInputCheckbox";
import AmbraInputText from "../../../components/elements/form/AmbraInputText";
import Subtitle from "../../../components/elements/text/Subtitle";
import Title from "../../../components/elements/text/Title";
import AuthContainer from "../../../components/layout/auth/AuthContainer";
import useAlerts from "../../../hooks/useAlerts";
import { useQuery } from "../../../hooks/useQuery";
import sectorsService from "../../../services/sectorsService";
import unityServices from "../../../services/unityServices";

const PRCompanyCreateUnity = () => {
  const history = useHistory();
  const query = useQuery();
  const alerts = useAlerts();

  //Encarregado const [dataSector, setDataSector] = useState<any>([]);

  const [editId, setEditId] = useState<string>("0");
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [field, setField] = useState<string>("");

  useEffect(() => {
    let companyId = localStorage.getItem("company");
    if (companyId) {
      setCompany(companyId);
    }
    let paramId = query.get("id");
    if (paramId) {
      setEditId(paramId);
    }
    let fieldId = query.get("fieldId");

    if (fieldId) {
      setField(fieldId);
    }
  }, []);

  useEffect(() => {
    if (editId !== "0") {
      sectorsService.get(editId).then((response) => {
        if (response.status) {
          getObjectFromRequest(response.data);
        }
      });
    }
  }, [editId]);

  const getObjectFromRequest = (area: any) => {
    setName(area.name);
    setAddress(area.address);
  };

  const handleStoreError = () => {
    alerts.showAlert("error", "Ops", "Ocorreu um erro ao salvar a área.");
  };

  const store = () => {
    let cont = true;
    if (editId !== "0") cont = false;
    let requestData = {
      name: name,
      address: address,
      company: company,
    };
    if (editId === "0") {
      unityServices
        .store(requestData)
        .then((response) => {
          if (response.status) {
            alerts.showAlert("success", "Sucesso", "Unidade salva com sucesso");
            history.push("/auth/PR/");
          } else {
            handleStoreError();
          }
        })
        .catch((error) => {
          handleStoreError();
        });
    } else {
      sectorsService
        .put(editId, requestData)
        .then((response) => {
          if (response.status) {
            alerts.showAlert("success", "Sucesso", "Unidade salva com sucesso");
            history.push("/auth/PR/Area?id=" + field);
          } else {
            handleStoreError();
          }
        })
        .catch((error) => {
          handleStoreError();
        });
    }
  };

  return (
    <AuthContainer>
      <div className="m-3 mt-4 mb-4">
        <div className="mb-4">
          <Title> Unidades </Title>
          <Subtitle>
            {" "}
            Parametrização {">"} Unidades {">"} Adicionar Unidade{" "}
          </Subtitle>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
          <div className="col-span-4">
            <AmbraInputText
              label="Nome da Unidade"
              validation="required"
              value={name}
              onChange={(e: any) => {
                setName(e.target.value.toUpperCase());
              }}
            />
          </div>

          <div className="col-span-4">
            <AmbraInputText
              label="Endereço"
              placeholder="Digite o seu endereço completo"
              value={address}
              onChange={(e: any) => {
                setAddress(e.target.value);
              }}
              validation="required"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
          <div className="col-start-6">
            <Button
              type="primary"
              className="mr-2"
              onClick={() => {
                history.push("/auth/PR");
              }}
            >
              <RiArrowLeftLine className="inline-block text-white-700" /> Voltar
            </Button>
            <Button
              type="primary"
              onClick={() => {
                store();
              }}
              className="ml-2"
            >
              Salvar Unidade{" "}
              <RiSave2Fill className="inline-block text-white-700" />
            </Button>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};

export default PRCompanyCreateUnity;
