import  { useEffect, useState } from 'react';
import { RiArrowLeftLine, RiSave2Fill } from 'react-icons/ri';
import { useHistory } from 'react-router';
import Button from '../../../components/elements/button/Button';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';

import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import { useQuery } from '../../../hooks/useQuery';
import fieldsService from '../../../services/fieldsService';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import useAlerts from '../../../hooks/useAlerts';

const PRCreateCompanyArea = () => {

    const history = useHistory();
    const query = useQuery();
    const alerts = useAlerts();

    const [editId, setEditId] = useState<string>('0');
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [active, setActive] = useState<boolean>(true);
    const [company, setCompany] = useState<string>('');

    useEffect(() => {
        let companyId = localStorage.getItem('company');
        if(companyId) {
            setCompany(companyId);
        }
        let paramId = query.get('unityId');
        if(paramId) {
            setEditId(paramId);
        }
    }, []);

    const handleStoreError = () => {
        alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar a área.');
    }

    const store = () => {
        let cont = true;
        if(editId !== '0')
            cont = false;
        let requestData = {
            name: name,
            company: company,
            unity:editId,
            active: active
        };
        console.log(requestData)
        if(editId !== '0') {
            fieldsService.store(requestData).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Área salva com sucesso');
            
                    if(!cont) {
                        history.push('/auth/PR');
                    }
                }else{
                    handleStoreError();
                }
            }).catch((error) => {
                handleStoreError();
            });
        }
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Criar Área</Title>
                    <Subtitle> Parametrização {'>'} Áreas  </Subtitle>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
                    <div className="col-span-4">
                        <AmbraInputText
                            label="Nome da área"
                            placeholder='Ex. Oficina Mecânica'
                            validation="required"
                            value={name}
                            onChange={(e: any) => {setName(e.target.value.toUpperCase())}}
                        />
                    </div>
                    <div className="col-span-3">
                        <AmbraInputCheckbox
                            label="Disponibilidade"
                            onChange={(e) => {setActive(!active)}}
                            checked={active}
                            value='Ativo'
                        />
                    </div>
                </div>

    

                
                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div className='col-start-6'>
                        <Button type='primary' className='mr-2' onClick={() => {history.push('/auth/PR')}}><RiArrowLeftLine className="inline-block text-white-700" /> Voltar</Button>
                        <Button type='primary' onClick={() => {store()}} className='ml-2'>Salvar Área <RiSave2Fill className="inline-block text-white-700" /></Button>
                       
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}


export default PRCreateCompanyArea;
