import React, { useEffect, useState } from "react";
import { RiArrowLeftLine, RiSave2Fill } from "react-icons/ri";
import { useHistory } from "react-router";
import Button from "../../../components/elements/button/Button";
import AmbraInputCheckbox from "../../../components/elements/form/AmbraInputCheckbox";
import AmbraInputText from "../../../components/elements/form/AmbraInputText";
import AmbraTable from "../../../components/elements/table/AmbraTable";
import Subtitle from "../../../components/elements/text/Subtitle";
import Title from "../../../components/elements/text/Title";
import AuthContainer from "../../../components/layout/auth/AuthContainer";
import useAlerts from "../../../hooks/useAlerts";
import { useQuery } from "../../../hooks/useQuery";
import fieldsService from "../../../services/fieldsService";
import sectorsService from "../../../services/sectorsService";

const PRCreateSectorPage = () => {
  const history = useHistory();
  const query = useQuery();
  const alerts = useAlerts();

  const [dataSector, setDataSector] = useState<any>([]);

  const [editId, setEditId] = useState<string>("0");
  const [name, setName] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [company, setCompany] = useState<string>("");
  const [updateCounter, setUpdateCounter] = useState<number>(0);
  const [field, setField] = useState<string>("");

  useEffect(() => {
    let companyId = localStorage.getItem("company");
    if (companyId) {
      setCompany(companyId);
    }
    let paramId = query.get("unityId");
    if (paramId) {
      setEditId(paramId);
    }
    let fieldId = query.get("id");
    if (fieldId) {
      setField(fieldId);
    }
  }, []);

  useEffect(() => {
    if (field !== "0") {
      fieldsService.get(field).then((response) => {
        if (response.status) {
          getObjectFromRequest(response.data);
        }
      });
    }
  }, [editId]);

  useEffect(() => {
    if (company !== "" && editId !== "0") {
      sectorsService.list(field).then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDataSector(response.data);
        }
      });
    }
  }, [updateCounter, company, field]);

  const getObjectFromRequest = (area: any) => {
    console.log(area);
    setName(area.name);
    setActive(area.active);
    setCompany(area.company);
    setField(area.id);
  };

  const handleStoreError = () => {
    alerts.showAlert("error", "Ops", "Ocorreu um erro ao salvar a área.");
  };
  const destroySector = (sectorId: string) => {
    alerts.showAlertConfirm(
      "warning",
      "Você realmente deseja excluir o setor?",
      "Isso não poderá ser desfeito.",
      () => {
        sectorsService.destroy(sectorId).then((response) => {
          if (response.status) {
            alerts.showAlert(
              "success",
              "Sucesso",
              "O setor foi excluído com sucesso"
            );
            setUpdateCounter(updateCounter + 1);
          }
        });
      },
      () => {}
    );
  };

  const store = () => {
    let requestData = {
      name: name,
      company: company,
      active: active,
      unity: editId,
    };
    if (editId === "0") {
      fieldsService
        .store(requestData)
        .then((response) => {
          if (response.status) {
            alerts.showAlert("success", "Sucesso", "Area salvo com sucesso");
            history.push("/auth/PR/");
          } else {
            handleStoreError();
          }
        })
        .catch((error) => {
          handleStoreError();
        });
    } else {
      fieldsService
        .put(field, requestData)
        .then((response) => {
          if (response.status) {
            alerts.showAlert("success", "Sucesso", "Área salva com sucesso");
            history.push("/auth/PR/");
          } else {
            handleStoreError();
          }
        })
        .catch((error) => {
          console.log(error.request);

          handleStoreError();
        });
    }
  };
  return (
    <AuthContainer>
      <div className="m-3 mt-4 mb-4">
        <div className="mb-4">
          <Title> Criar/Editar AREA </Title>
          <Subtitle>
            {" "}
            Parametrização {">"} Unidades {">"} Adicionar Àrea{" "}
          </Subtitle>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
          <div className="col-span-4">
            <AmbraInputText
              label="Nome da área"
              placeholder="Ex. Oficina Mecânica"
              validation="required"
              value={name}
              onChange={(e: any) => {
                setName(e.target.value.toUpperCase());
              }}
            />
          </div>
          <div className="col-span-3">
            <AmbraInputCheckbox
              label="Disponibilidade"
              onChange={(e) => {
                setActive(!active);
              }}
              checked={active}
              value="Ativo"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
          <div className="col-start-6">
            <Button
              type="primary"
              className="mr-2"
              onClick={() => {
                history.push("/auth/PR/Area?id=" + editId);
              }}
            >
              <RiArrowLeftLine className="inline-block text-white-700" /> Voltar
            </Button>
            <Button
              type="primary"
              onClick={() => {
                store();
              }}
              className="ml-2"
            >
              Salvar ÁREA{" "}
              <RiSave2Fill className="inline-block text-white-700" />
            </Button>
          </div>
        </div>
      </div>

      {field !== "0" && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
            <div>
              <AmbraTable
                title="setores"
                data={dataSector}
                renderColumns={[{ label: "Nome", dataColumn: "name" }]}
                showEdit={true}
                showDelete={true}
                showFile={false}
                search={true}
                onEdit={(element: any) => {
                  history.push("/auth/PR/CreateSectorPage?id=" + element.id);
                }}
                onDelete={(element: any) => {
                  destroySector(element.id);
                }}
                onFile={(element: any) => {
                  console.log(element);
                }}
                route={"/auth/PR/CreateSectorPage?fieldId=" + field}
                renderTools={<>adicionar Setor</>}
              />
            </div>
          </div>
        </>
      )}
    </AuthContainer>
  );
};

export default PRCreateSectorPage;
