import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { alertsState } from '../state/alertState';
import uuidv4 from '../utils/uuidv4';

const useAlerts = () => {

    const [alerts, setAlerts] = useRecoilState(alertsState);

    useEffect(() => {

        return () => {};
    }, []);

    let uuid = uuidv4.generate();

    const showAlert = (type: string, title: string, content: string) => {
        setAlerts([...alerts, {
            confirm: false,
            id : uuid,
            type: type,
            title: title,
            content: content,
            onConfirm: () => {},
            onCancel: () => {}
        }]);
    }

    const showAlertConfirm = (type: string, title: string, content: string, onConfirm: () => void, onCancel: () => void) => {
        setAlerts([...alerts, {
            confirm: true,
            id : uuid,
            type: type,
            title: title,
            content: content,
            onConfirm: onConfirm,
            onCancel: onCancel
        }]);
    }

    return {
        showAlert,
        showAlertConfirm
    }
    
}

export default useAlerts;