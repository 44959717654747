import React, { useEffect, useState } from 'react';
import { RiSave2Fill } from 'react-icons/ri';
import { useHistory } from 'react-router';
import Button from '../../../components/elements/button/Button';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import companiesService from '../../../services/companiesService';
import useAlerts from '../../../hooks/useAlerts';
import { maskCNPJ } from '../../../utils/masks';
import AmbraInputFile from '../../../components/elements/form/AmbraInputFile';
import fileService from '../../../services/fileService';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';

const PRCompanyPage = () => {
    const history = useHistory();
    const alerts = useAlerts();

    const [company, setCompany] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [cnpj, setCnpj] = useState<string>('');
    const [medicalEmergencyPhone, setMedicalEmergencyPhone] = useState<string>('');
    const [medicalClinicPhone, setMedicalClinicPhone] = useState<string>('');
    const [brigadePhone, setBrigadePhone] = useState<string>('');
    const [firefighterPhone, setFirefighterPhone] = useState<string>('');
    const [emergencyPhone, setEmergencyPhone] = useState<string>('');
    const [active, setActive] = useState<string>('');
    const [updateCounter, setUpdateCounter] = useState<number>(0);
    const [fileUrl, setFileUrl] = useState<string>('');

    const [minOxigen, setMinOxigen] = useState<string>('19.5');
    const [maxOxigen, setMaxOxigen] = useState<string>('23');
    const [maxCO, setMaxCO] = useState<string>('39');
    const [lieRef, setLieRef] = useState<string>('0');
    const [maxH2S, setMaxH2S] = useState<string>('8');
    const [mandatoryGasMedPhoto, setMandatoryGasMedPhoto] = useState<boolean>(true);

    useEffect(() => {
        let companyId = localStorage.getItem('company');
        if(companyId) {
            setCompany(companyId);
        }
    }, []);

    useEffect(() => {
        if(company !== '') {
            companiesService.get(company).then((response) => {
                if(response.status) {
                    getObjectFromRequest(response.data);            
                } 
            });
        }
    }, [company, updateCounter]);

    const getObjectFromRequest = (area: any) => {
        setName(area.name);
        setAddress(area.address);
        setPostalCode(area.postal_code);
        setCnpj(maskCNPJ(area.cnpj+''));
        setMedicalEmergencyPhone(area.medical_emergency_phone);
        setMedicalClinicPhone(area.medical_clinic_phone);
        setBrigadePhone(area.brigade_phone);
        setFirefighterPhone(area.firefighter_phone);
        setEmergencyPhone(area.emergency_phone);
        setActive(area.active);
        setFileUrl(area.profile_picture_url);
    }

    const store = () => {
        let requestData: any = {
            name : name,
            address : address,
            postal_code : postalCode,
            cnpj : cnpj.replace(/[^0-9]/g, ''),
            medical_emergency_phone : medicalEmergencyPhone,
            medical_clinic_phone : medicalClinicPhone,
            brigade_phone : brigadePhone,
            firefighter_phone : firefighterPhone,
            emergency_phone : emergencyPhone,
        };
        if(fileUrl) {
            requestData.profile_picture_url = fileUrl;
        }
        companiesService.put(company, requestData).then((response) => {
            if(response.status) {
                alerts.showAlert('success', 'Sucesso', 'Os dados foram alterados com sucesso');
                setUpdateCounter(updateCounter + 1);
            }
        }).catch((error) => {
            alerts.showAlert('error', 'Ops', 'Não foi possível alterar os dados da sua empresa. Por favor, tente novamente');
        });
    }

    const uploadFile = (e: any) => {
        let formData = new FormData();
        
        formData.append('file', e.target.files[0]);
        formData.append('company', company);
        
        fileService.uploadFile(formData).then((response: any) => {
            if(response.status) {
                setFileUrl(response.data.full_url);
            }
        });
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-5'>
                    <Title> Parametrização </Title>
                    <Subtitle> Parametrização {'>'} Dados da empresa </Subtitle>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 mb-1 mt-5 border-bottom">

                    <div>
                        <Button type='visible'><h1 className="text-xl text-gray-700 font-medium uppercase">1. Dados da Empresa</h1></Button>
                    </div>
                
                    <div>
                        <Button type='Invisible' onClick={() => {history.push('/auth/PR')}}><h1 className="text-xl text-gray-600 uppercase">2. Unidades</h1></Button>
                    </div>
                </div>

                <hr />

                <div className='grid grid-cols-2 lg:grid-cols-1 gap-5 mb-5 mt-5'>
                    <div className=''>
                        <div className='text-center'>
                        {
                            fileUrl && <img src={fileUrl} className='rounded-full' style={{width: 150, height: 150, objectFit: 'cover', border: '2px solid #656565', background: '#FFFFFF', backgroundPosition: 'center'}} />
                        }
                        </div>
                        <AmbraInputFile
                            className="mt-5"
                            label={fileUrl ? 'Alterar logo / Escolher nova (270x270px)' : 'Escolher nova (270x270px)'}
                            onChange={(e: any) => {uploadFile(e)}}
                        />
                    </div>
                </div>


                <div className="grid grid-cols-2 lg:grid-cols-1 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Nome"
                            placeholder='Digite o nome'
                            value={name}
                            onChange={(e: any) => {setName(e.target.value)}}
                            validation="required"
                        />

                        
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-1 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Endereço"
                            placeholder='Digite o seu endereço completo'
                            value={address}
                            onChange={(e: any) => {setAddress(e.target.value)}}
                            validation="required"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="CEP"
                            placeholder='88133-933'
                            value={postalCode}
                            mask="cep"
                            onChange={(e: any) => {setPostalCode(e.target.value)}}
                            validation="required"
                        />
                    </div>
                    <div>
                        <AmbraInputText
                            label="CNPJ"
                            placeholder='33.332.738/0001-05'
                            value={cnpj}
                            mask="cpfCnpj"
                            onChange={(e: any) => {setCnpj(e.target.value)}}
                            validation="required"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Plantão de emergência"
                            placeholder='(xx) xxxxx-xxxx'
                            value={medicalEmergencyPhone}
                            //mask="phone"
                            onChange={(e: any) => {setMedicalEmergencyPhone(e.target.value)}}
                            validation=""
                        />
                    </div>
                    <div>
                        <AmbraInputText
                            label="Ambulatório médico"
                            placeholder='(xx) xxxxx-xxxx'
                            value={medicalClinicPhone}
                            //mask="phone"
                            onChange={(e: any) => {setMedicalClinicPhone(e.target.value)}}
                            validation=""
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Brigada"
                            placeholder='(xx) xxxxx-xxxx'
                            value={brigadePhone}
                            //mask="phone"
                            onChange={(e: any) => {setBrigadePhone(e.target.value)}}
                            validation=""
                        />
                    </div>
                    <div>
                        <AmbraInputText
                            label="Bombeiros"
                            placeholder='(xx) xxxxx-xxxx'
                            value={firefighterPhone}
                            //mask="phone"
                            onChange={(e: any) => {setFirefighterPhone(e.target.value)}}
                            validation=""
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-1 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Samu"
                            placeholder='(xx) xxxxx-xxxx'
                            value={emergencyPhone}
                            //mask="phone"
                            onChange={(e: any) => {setEmergencyPhone(e.target.value)}}
                            validation=""
                        />
                    </div>
                </div>

                {/* <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Descrição"
                            placeholder='Segurança'
                            value={name}
                            onChange={(e: any) => {setName(e.target.value)}}
                            validation="required"
                        />
                    </div>
                </div> */}

                <div className="grid left grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div className='text-right col-span-2'>
                        <Button type='primary' onClick={() => {store()}}>Salvar <RiSave2Fill className="inline-block" /></Button>
                    </div>
                </div>

                <hr />

                <div className='mb-5 mt-5'>
                    <Title>Parâmetros PT Espaço Confinado (Medição de Gases)</Title>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Mínimo Oxigênio"
                            placeholder=''
                            value={minOxigen}
                            //mask="phone"
                            onChange={(e: any) => {setMinOxigen(e.target.value)}}
                            validation=""
                        />
                    </div>
                    <div>
                        <AmbraInputText
                            label="Máximo Oxigênio"
                            placeholder=''
                            value={maxOxigen}
                            //mask="phone"
                            onChange={(e: any) => {setMaxOxigen(e.target.value)}}
                            validation=""
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Max. Monóxido de Carbono"
                            placeholder=''
                            value={maxCO}
                            //mask="phone"
                            onChange={(e: any) => {setMaxCO(e.target.value)}}
                            validation=""
                        />
                    </div>
                    <div>
                        <AmbraInputText
                            label="Ideal Combustível (LIE)"
                            placeholder=''
                            value={lieRef}
                            //mask="phone"
                            onChange={(e: any) => {setLieRef(e.target.value)}}
                            validation=""
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Max. Gás Sulfídrico H2S"
                            placeholder=''
                            value={maxH2S}
                            //mask="phone"
                            onChange={(e: any) => {setMaxH2S(e.target.value)}}
                            validation=""
                        />
                    </div>
                    <div>
                        <AmbraInputCheckbox
                            label="Foto do aparelho de medição obrigatória"
                            onChange={(e) => {setMandatoryGasMedPhoto(!mandatoryGasMedPhoto)}}
                            checked={mandatoryGasMedPhoto}
                            value='Marcar como obrigatório'
                        />
                    </div>
                </div>

                <div className="grid left grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div className='text-right col-span-2'>
                        <Button type='primary' onClick={() => {store()}}>Salvar Parâmetros PT/APR <RiSave2Fill className="inline-block" /></Button>
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default PRCompanyPage