import React, { useEffect, useState } from 'react';
import { RiArrowLeftLine, RiSave2Fill } from 'react-icons/ri';
import { useHistory } from 'react-router';
import Button from '../../../components/elements/button/Button';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import AmbraTable from '../../../components/elements/table/AmbraTable';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import useAlerts from '../../../hooks/useAlerts';
import { useQuery } from '../../../hooks/useQuery';
import companiesService from '../../../services/companiesService';
import fieldsService from '../../../services/fieldsService';
import sectorsService from '../../../services/sectorsService';
import userService from '../../../services/userService';
import { maskCNPJ } from '../../../utils/masks';
import cepService from '../../../services/cepService';

const CompaniesCreatePage = () => {

    const history = useHistory();
    const alerts = useAlerts();
    const query = useQuery();

    const [editId, setEditId] = useState<string>('0');
    const [company, setCompany] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [cnpj, setCnpj] = useState<string>('');
    const [medicalEmergencyPhone, setMedicalEmergencyPhone] = useState<string>('');
    const [medicalClinicPhone, setMedicalClinicPhone] = useState<string>('');
    const [brigadePhone, setBrigadePhone] = useState<string>('');
    const [firefighterPhone, setFirefighterPhone] = useState<string>('');
    const [emergencyPhone, setEmergencyPhone] = useState<string>('');
    const [active, setActive] = useState<string>('');
    const [updateCounter, setUpdateCounter] = useState<number>(0);
    const [companyCode, setCompanyCode] = useState<string>('');

    useEffect(() => {
        let company = localStorage.getItem('company');
        if (company) {
            setCompany(company);
        }
        let id = query.get('id');
        if (id) {
            setEditId(id);
        }
    }, []);

    useEffect(() => {
        if (editId !== '0') {
            companiesService.get(editId).then((response) => {
                if (response.status) {
                    getObjectFromRequest(response.data);
                }
            });
            userService.get(editId).then((response) => {
                if (response.status) {
                    loadObjectFromRequestUser(response.data);
                }
            });
        }
    }, [editId, updateCounter]);

    useEffect(() => {
        if(postalCode.length === 9) {
            cepService.getAddressFromCEP(postalCode).then((response: any) => {
                if(response.status) {
                    //console.log(response.data);
                    let logradouro = response.data.logradouro;
                    let bairro = response.data.bairro;
                    let localidade = response.data.localidade;
                    let uf = response.data.uf;
                    if(logradouro.length === 0) {
                        logradouro = '{LOGRADOURO}';
                    }
                    if(bairro.length === 0) {
                        bairro = '{BAIRRO}';
                    }
                    if(localidade.length === 0) {
                        localidade = '{LOCALIDADE}';
                    }
                    if(uf.length === 0) {
                        uf = '{UF}';
                    }
                    setAddress(logradouro+', {NÚMERO}, '+bairro+', '+localidade+', '+uf);
                }
            });
        }
    }, [postalCode]);

    const getObjectFromRequest = (area: any) => {
        setName(area.name);
        setAddress(area.address);
        setPostalCode(area.postal_code);
        setCnpj(maskCNPJ(area.cnpj + ''));
        setMedicalEmergencyPhone(area.medical_emergency_phone);
        setMedicalClinicPhone(area.medical_clinic_phone);
        setBrigadePhone(area.brigade_phone);
        setFirefighterPhone(area.firefighter_phone);
        setEmergencyPhone(area.emergency_phone);
        setActive(area.active);
        setEmail(area.email);
        setCompanyCode(area.code);
    }

    const loadObjectFromRequestUser = (requestData: any) => {
        setEmail(requestData.email);
    }

    const store = () => {
        let requestData = {
            name: name,
            address: address,
            postal_code: postalCode,
            cnpj: cnpj,
            medical_emergency_phone: medicalEmergencyPhone,
            medical_clinic_phone: medicalClinicPhone,
            brigade_phone: brigadePhone,
            firefighter_phone: firefighterPhone,
            emergency_phone: emergencyPhone,
            code: companyCode
        };

        let requestDataUser: any = {
            name: name,
            is_thirdy_party: false,
            company_name: null,
            company_cnpj: cnpj,
            email: email,
            company: company,
            position: 'Diretor',
            active: true,
            is_responsible: true,
            is_releaser: false,
            releasers: [],
            fields: [],
            companyIdentificator: 'D1'
        };

        if (password.length > 0) {
            requestDataUser.password = password;
        }

        if (editId === '0') {
            companiesService.store(requestData).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Sucesso', 'A empresa foi criada com sucesso');

                    requestDataUser.company = response.data.company.id;
                    userService.signupCompanyAdmin(requestDataUser).then((response) => {
                        if (response.status) {
                            alerts.showAlert('success', 'Sucesso', 'O usuário "'+email+'" com a senha "'+password+'" foi criada com sucesso.');
                            history.push('/auth/companies');
                        }
                    }).catch((error) => {
                        alerts.showAlert('error', 'Ops', 'Não foi possível alterar os dados da sua empresa. Por favor, tente novamente');
                    });
                }
            }).catch((error) => {
                alerts.showAlert('error', 'Ops', 'Não foi possível alterar os dados da sua empresa. Por favor, tente novamente');
            });
            // userService.signupCompanyAdmin(requestDataUser).then((response) => {
            //     if (response.status) {
            //         alerts.showAlert('success', 'Sucesso', 'O usuário "'+email+'" com a senha "'+password+'" foi criada com sucesso.');
            //         history.push('/auth/companies');
            //     }
            // }).catch((error) => {
            //     alerts.showAlert('error', 'Ops', 'Não foi possível alterar os dados da sua empresa. Por favor, tente novamente');
            // });
        } else {
            companiesService.put(editId, requestData).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Os dados foram alterados com sucesso');
                    history.push('/auth/companies');
                    setUpdateCounter(updateCounter + 1);
                }
            }).catch((error) => {
                alerts.showAlert('error', 'Ops', 'Não foi possível alterar os dados da sua empresa. Por favor, tente novamente');
            });
            userService.put(editId, requestDataUser).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Os dados foram alterados com sucesso');
                    history.push('/auth/companies');
                    setUpdateCounter(updateCounter + 1);
                }
            }).catch((error) => {
                alerts.showAlert('error', 'Ops', 'Não foi possível alterar os dados da sua empresa. Por favor, tente novamente');
            });
        }
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-5'>
                    <Title> Empresas </Title>
                    <Subtitle> Controle de clientes {'>'} Cadastro da empresa </Subtitle>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Código da empresa"
                            placeholder='Digite um código único de até 8 dígitos'
                            value={companyCode}
                            onChange={(e: any) => { 
                                let string = e.target.value.toUpperCase();
                                string = string.replace(/[^0-9A-Z]/g, '');
                                setCompanyCode(string);
                            }}
                            validation="required"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Nome"
                            placeholder='Digite o nome'
                            value={name}
                            onChange={(e: any) => { setName(e.target.value) }}
                            validation="required"
                        />
                    </div>
                </div>
                {
                    editId === '0' &&
                    <>
                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                            <div>
                                <AmbraInputText
                                    label="Email*"
                                    placeholder='Digite um e-mail'
                                    value={email}
                                    validation="required|email"
                                    onChange={(e: any) => { setEmail(e.target.value) }}
                                />
                            </div>
                            <div>
                                <AmbraInputText
                                    label="Senha* (min. 8 caracteres)"
                                    placeholder={editId !== '0' ? '(INALTERADA)' : 'Digite a senha'}
                                    validation={editId !== '0' ? 'unrequired|min:8' : 'required|min:8'}
                                    value={password}
                                    key={'reload_pw_cb'}
                                    onChange={(e: any) => { setPassword(e.target.value) }}
                                />
                            </div>
                        </div>
                    </>
                }


                <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="CEP"
                            placeholder='88133-933'
                            value={postalCode}
                            mask="cep"
                            onChange={(e: any) => { setPostalCode(e.target.value) }}
                            validation="required"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Endereço"
                            placeholder='Digite o seu endereço completo'
                            value={address}
                            onChange={(e: any) => { setAddress(e.target.value) }}
                            validation="required"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="CNPJ"
                            placeholder='33.332.738/0001-05'
                            value={cnpj}
                            mask="cpfCnpj"
                            onChange={(e: any) => { setCnpj(e.target.value) }}
                            validation="required"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Plantão de emergência"
                            placeholder='xx xxxxx-xxxx'
                            value={medicalEmergencyPhone}
                            // mask="phone"
                            onChange={(e: any) => { setMedicalEmergencyPhone(e.target.value) }}
                            validation=""
                        />
                    </div>
                    <div>
                        <AmbraInputText
                            label="Ambulatório médico"
                            placeholder='(xx) xxxxx-xxxx'
                            value={medicalClinicPhone}
                            //mask="phone"
                            onChange={(e: any) => { setMedicalClinicPhone(e.target.value) }}
                            validation=""
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Brigada"
                            placeholder='(xx) xxxxx-xxxx'
                            value={brigadePhone}
                            //mask="phone"
                            onChange={(e: any) => { setBrigadePhone(e.target.value) }}
                            validation=""
                        />
                    </div>
                    <div>
                        <AmbraInputText
                            label="Bombeiros"
                            placeholder='(xx) xxxxx-xxxx'
                            value={firefighterPhone}
                            //mask="phone"
                            onChange={(e: any) => { setFirefighterPhone(e.target.value) }}
                            validation=""
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Samu"
                            placeholder='(xx) xxxxx-xxxx'
                            value={emergencyPhone}
                            //mask="phone"
                            onChange={(e: any) => { setEmergencyPhone(e.target.value) }}
                            validation=""
                        />
                    </div>
                </div>

                {/* <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Descrição"
                            placeholder='Segurança'
                            value={name}
                            onChange={(e: any) => {setName(e.target.value)}}
                            validation="required"
                        />
                    </div>
                </div> */}

                {
                    editId === '0' &&
                    <>

                    </>
                }

                <div className="grid left grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
                    <div className='col-start-4'>
                        <Button type='primary' onClick={() => { store() }}>Salvar <RiSave2Fill className="inline-block" /></Button>
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default CompaniesCreatePage