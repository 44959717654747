import React, { useState } from 'react'
import Button from '../../../components/elements/button/Button';
import AmbraInputText from '../form/AmbraInputText';
import Pagination from '../pagination/Pagination';
import { RiPencilFill, RiDeleteBin7Fill, RiAddFill, RiFolderDownloadFill } from 'react-icons/ri';
import { useHistory } from 'react-router';

interface AmbraTableColumnInterface {
    label: string;
    dataColumn: string;
    classNameFn?: (el: any) => string;
}

interface AmbraTablePropsInterface {
    title: string;
    renderTools: any;
    data: any[];
    showEdit: boolean;
    showDelete: boolean;
    showFile: boolean;
    onEdit: (el: any) => void;
    onDelete: (el: any) => void;
    onFile: (el: any) => void;
    route: string;
    search: boolean;
    renderColumns: AmbraTableColumnInterface[];
    rowClassNameFn?: (el: any) => string;
    currentPage?: number;
    totalPages?: number;
    onPageChange?: (page: number) => void;
}

const AmbraTable = (props: AmbraTablePropsInterface) => {
    const history = useHistory();
    const [searchTerms, setSearchTerms] = useState<string>('');
    const [terms, setTerms] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(props.currentPage ?? 1);
    const [totalPages, setTotalPages] = useState<number>(props.totalPages ?? 1);

    const loadSearchTermsToTerms = () => {
        setTerms(searchTerms);
        setCurrentPage(1);
    }


    return (
        <div className="ambra-table color-ambra-gray">
            <div className="ambra-table-header bg-ambra-orangeLight p-1">
                <div className="grid grid-cols-2">
                    <div className="ambra-table-title text-xl uppercase m-2 font-medium">
                        {props.title}
                    </div>


                    <div className="text-right col-start-2 mt-1">
                        {
                            props.search ?
                                <AmbraInputText
                                    label=""
                                    placeholder="Digite aqui para pesquisar..."
                                    value={searchTerms}
                                    className="border-0"
                                    onEnterKeyUp={() => { loadSearchTermsToTerms() }}
                                    onChange={(e: any) => { setSearchTerms(e.target.value) }}
                                />
                                :
                                <div></div>
                        }
                    </div>
                    <div className="text-right col-start-6 p-1">
                        {
                            props.renderTools && props.route &&
                            <Button type="primary" className='m-2 uppercase' onClick={() => { history.push(props.route) }}>{props.renderTools} <RiAddFill className="inline-block" /></Button>
                        }
                        {
                            props.renderTools && (!props.route || props.route.length === 0) &&
                            <Button type="primary" className='m-2 uppercase' onClick={() => { }}>{props.renderTools} <RiAddFill className="inline-block" /></Button>
                        }
                    </div>
                </div>
            </div>
            <div className="ambra-table-body bg-ambra-orangeLight">
                <table className="w-full text-left">
                    <thead>
                        <tr>
                            {
                                props.renderColumns.map((el: any, index: number) =>
                                    <th key={index} className="p-1 uppercase font-medium">{el.label}{/* TODO: ordenação */}</th>
                                )
                            }
                            <th className="p-1 uppercase font-medium">Ações</th>
                        </tr>
                    </thead>
                    <tbody className="bg-ambra-white">
                        {
                            props.data.map((el: any, index: number) =>
                                <tr key={index} className={"hover:bg-gray-50 "+(props.rowClassNameFn ? props.rowClassNameFn(el) : '')}>
                                    {
                                        props.renderColumns.map((elCol: any, indexCol: number) =>
                                            <td className={'p-1 '+(elCol.classNameFn ? elCol.classNameFn(el[elCol.dataColumn]) : '')} key={'col_' + indexCol}>{el[elCol.dataColumn]}</td>
                                        )
                                    }
                                    <td>
                                    {
                                        props.showEdit &&
                                            <Button type="primary" onClick={() => { props.onEdit(el) }}> Ver/Editar <RiPencilFill className="inline-block" /></Button>
                                        
                                    }
                                    {
                                        props.showDelete &&
                                            <Button type="primary" onClick={() => { props.onDelete(el) }}> Excluir <RiDeleteBin7Fill className="inline-block" /></Button>
                                        
                                    }
                                    {
                                        props.showFile &&
                                            <Button type="primary" onClick={() => { props.onFile(el) }}> Exportar PDF <RiFolderDownloadFill className="inline-block" /></Button>
                                        
                                    }
                                    </td>
                                </tr>
                            )
                        }
                        {
                            props.data.length === 0 &&
                            <tr>
                                <td colSpan={props.renderColumns.length+1} className="p-1 text-center font-normal">Não existem dados cadastrados.</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="mt-5 ambra-table-footer p-1 text-center bg-ambra-orangeLight ">{/* TODO: PAGINATION - DEIXAR POR ÚLTIMO */}
                <Pagination pages={totalPages} currentPage={currentPage} onPageChange={(page: number) => { 
                    if(props.onPageChange) {
                        props.onPageChange(page); 
                    }
                }} />
            </div>
        </div>
    )
}

export default AmbraTable