import BackToConfig from "../../../components/config/BackToConfig";
import GenericCRUD, { GenericCRUDFieldInterface } from "../../../components/genericCRUD/GenericCRUD";
import AuthContainer from "../../../components/layout/auth/AuthContainer";
import { UserRole } from "../../../enums/UserRole";

const UserPage = () => {
    const fields: GenericCRUDFieldInterface[] = [
        {
            fieldName: 'name',
            fieldInputLabel: 'Nome',
            fieldInputPlaceholder: 'Digite o nome',
            fieldValidationPattern: 'required' 
        },
        {
            fieldName: 'is_admin',
            fieldInputLabel: 'Permissão',
            fieldInputPlaceholder: 'Selecione o tipo de usuário',
            fieldValidationPattern: 'required',
            fieldType: 'select',
            fieldSelectData: [
                {
                    text: 'Administrador',
                    value: true
                }
            ]
        },
        {
            fieldName: 'email',
            fieldInputLabel: 'E-mail',
            fieldInputPlaceholder: 'Digite o e-mail',
            fieldValidationPattern: 'required|email' 
        },
        {
            fieldName: 'password',
            fieldInputLabel: 'Senha',
            fieldInputPlaceholder: 'Digite a senha',
            fieldValidationPattern: 'required|min:8',
            fieldType: 'password'
        },
    ];
    
    return (
        <AuthContainer>
            <BackToConfig />
            <GenericCRUD 
                name="Usuário"
                subtitle="Gerenciamento dos usuários"
                fields={fields}
                endpoint="/users"
            />
        </AuthContainer>
    )
}

export default UserPage;