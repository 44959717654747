import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import AmbraTable from '../../../components/elements/table/AmbraTable';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import useAlerts from '../../../hooks/useAlerts';
import asoService from '../../../services/asoService';
import trainingsService from '../../../services/trainingServices';
import unityServices from '../../../services/unityServices';
import userService from '../../../services/userService';
import { fromJSDateToViewMaskOnlyDate } from '../../../utils/masks';

const VNPage = () => {
    const history = useHistory();
    const alerts = useAlerts();

    const [company, setCompany] = useState<string>('');
    const [dataUser, setDataUser] = useState<any>([]);
    const [dataAso, setDataAso] = useState<any>([]);
    const [dataTraining, setDataTraining] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [updateCounter, setUpdateCounter] = useState<number>(0);
    const [updateCounterList, setUpdateCounterList] = useState<number>(0);
    const [filterPeriod, setFilterPeriod] = useState<string>('all');

    const [unities, setUnities] = useState<any>([]);
    const [unity, setUnity] = useState<string>('');

    useEffect(() => {
        let companyId = localStorage.getItem('company');
        if (companyId) {
            setCompany(companyId);
            const loadUnities = async (companyIdParam: string) => {
                unityServices.list(companyIdParam).then((response) => {
                    if(response.data) {
                        let newUnitites = response.data.map((el: any) => {
                            return {
                                text: el.name,
                                value: el.id
                            };
                        });
                        setUnities(newUnitites);
                    }
                });
            };
            loadUnities(companyId);
        }
    }, []);

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (company !== '') {
            userService.list(company).then((response) => {
                if (response.status === 200) {
                    setDataUser(transformData(response.data));
                }
            });
            setTimeout(() => {
                trainingsService.list(company).then((response) => {
                    if (response.status === 200) {
                        setDataTraining(transformData(response.data));
                        setUpdateCounterList(updateCounterList+1);
                    }
                    asoService.list(company).then((response) => {
                        if (response.status === 200) {
                            setDataAso(transformData(response.data));
                            setUpdateCounterList(updateCounterList+1);
                        }
                    });
                });
            }, 1000);
        }
    }, [updateCounter, company]);

    useEffect(() => {
        //TODO: rewrite backend
        if (dataUser.length > 0 && (dataAso.length > 0 || dataTraining.length > 0)) {
            let usersNameById: any[] = [];
            let newDate = new Date();
            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let seconds = newDate.getSeconds();
            let minutes = newDate.getMinutes();
            let hour = newDate.getHours();
            let current = `${year}${'-'}${month < 10 ? `0${month}` : `${month}`}${'-'}${date}${' '}${hour}${':'}${minutes}${':'}${seconds}`;

            var d = new Date();
            d.setDate(d.getDate()-5);

            dataUser.map((el: any) => {
                usersNameById[el.id] = el.name;
            });

            let newData: any[] = [];
            dataAso.map((el: any) => {
                if (el.active && current > el.expiry_date) {
                    newData.push({
                        user: el.user,
                        userName: usersNameById[el.user],
                        type: 'ASO',
                        expiry: 'Sim',
                        dueDate: el.expiry_date,
                        dueDateFormatted: fromJSDateToViewMaskOnlyDate(el.expiry_date)
                    });
                }else {
                    newData.push({
                        user: el.user,
                        userName: usersNameById[el.user],
                        type: 'ASO',
                        expiry: 'Não',
                        dueDate: el.expiry_date,
                        dueDateFormatted: fromJSDateToViewMaskOnlyDate(el.expiry_date)
                    });
                } 
            });

            dataTraining.map((el: any) => {
                if (el.active && current > el.expiry_date) {
                    newData.push({
                        user: el.user,
                        userName: usersNameById[el.user],
                        type: 'Treinamento',
                        expiry: 'Sim',
                        expDate: el.emission_date,
                        expDateFormatted: fromJSDateToViewMaskOnlyDate(el.emission_date),
                        dueDate: el.expiry_date,
                        dueDateFormatted: fromJSDateToViewMaskOnlyDate(el.expiry_date)
                    });
                }
                else {
                    newData.push({
                        user: el.user,
                        userName: usersNameById[el.user],
                        type: 'Treinamento',
                        expiry: 'Não',
                        dueDate: el.expiry_date,
                        dueDateFormatted: fromJSDateToViewMaskOnlyDate(el.expiry_date)
                    });
                }
            });
            
            if(filterPeriod && filterPeriod !== 'all') {
                let removeIndexes: number[] = [];
                let current = new Date().getTime();
                let newData2: any[] = [];
                for(let i = 0; i < newData.length; i++) {
                    let objDate = new Date(newData[i].dueDate).getTime();
                    if(filterPeriod === 'dued' && (objDate < current)) {
                        //removeIndexes.push(i);
                        newData2.push(newData[i]);
                    }else if(filterPeriod === '30d' && newData[i].expiry === 'Não' && (objDate < (current+(30*24*60*60*1000)))) {
                        newData2.push(newData[i]);
                        //removeIndexes.push(i);
                    }else if(filterPeriod === '45d' && newData[i].expiry === 'Não' && (objDate < (current+(45*24*60*60*1000)))) {
                        newData2.push(newData[i]);
                        //removeIndexes.push(i);
                    }else if(filterPeriod === '60d' && newData[i].expiry === 'Não' && (objDate < (current+(60*24*60*60*1000)))) {
                        newData2.push(newData[i]);
                        //removeIndexes.push(i);
                    }else{

                    }
                }   
                newData = newData2; //newData.filter((el, index) => (removeIndexes.indexOf(index) !== -1));
            }

            const sortFn = (a: any, b: any) => {
                if (a.dueDate < b.dueDate) {
                    return -1;
                }
                if (a.dueDate > b.dueDate) {
                    //console.log(a.dueDate < b.dueDate);
                    return 1;
                }
                return 0;
            }
            newData.sort(sortFn);
            setData(newData);
        }
    }, [filterPeriod, dataUser, dataTraining, dataAso, updateCounterList]);

    const transformData = (data: any) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].active) {
                data[i].activeLabel = 'Sim';
            } else {
                data[i].activeLabel = 'Não';
            }
        }
        return data;

    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Vencimentos </Title>
                    <Subtitle> Visualizar Vencimentos </Subtitle>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                    <div className='grid grid-cols-2 lg:grid-cols-2 gap-5'>
                        <AmbraInputSelect
                            label='Filtrar por período'
                            value={filterPeriod}
                            placeholder='Selecione um período'
                            data={[
                                {text: 'Vencidos', value: 'dued'},
                                {text: 'Vencendo em 30 dias', value: '30d'},
                                {text: 'Vencendo em 45 dias', value: '45d'},
                                {text: 'Vencendo em 60 dias', value: '60d'},
                                {text: 'Todos', value: 'all'}
                            ]}
                            onChange={(e: any) => {setFilterPeriod(e.target.value)}}
                            />
                            {/* <AmbraInputSelect
                            label='Filtrar por Unidade'
                            value={unity}
                            placeholder='Selecione uma unidade'
                            data={unities}
                            onChange={(e: any) => {setUnity(e.target.value)}}
                            /> */}
                    </div>
                    <div>
                        <AmbraTable
                            title='Vencimentos'
                            data={data}
                            renderColumns={[
                                { label: 'Colaborador', dataColumn: 'userName' },
                                { label: 'Tipo', dataColumn: 'type' },
                                { label: 'Vencido', dataColumn: 'expiry' },
                                { label: 'Data de vencimento', dataColumn: 'dueDateFormatted' }
                            ]}
                            showEdit={true}
                            showDelete={false}
                            showFile={false}
                            search={false}
                            onEdit={(element: any) => { history.push('/auth/CBCollaborator?id=' + element.user) }}
                            onDelete={(element: any) => { }}
                            onFile={(element: any) => { }}
                            route=''
                            renderTools={null}
                        />
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default VNPage