import { AxiosResponse } from 'axios';
import api from './api';

interface genericServiceInterface {
    delete: (endpoint: string, id: number) => Promise<AxiosResponse<any>>;
    getAll: (endpoint: string) => Promise<AxiosResponse<any>>;
    create: (endpoint: string, data: any) => Promise<AxiosResponse<any>>;
    update: (endpoint: string, id: number, data: any) => Promise<AxiosResponse<any>>;
}

const genericService: genericServiceInterface = {
    getAll: (endpoint: string) => 
        api.get(endpoint),
    create: (endpoint: string, data: any) =>
        api.post(endpoint, data),
    update: (endpoint: string, id: number, data) =>
        api.put(endpoint+'/'+id, data),
    delete: (endpoint: string, id: number) =>
        api.delete(endpoint+'/'+id)
};

export default genericService;