import React, { useEffect, useState } from 'react'
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import Title from '../../../components/elements/text/Title';
import Subtitle from '../../../components/elements/text/Subtitle';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import AmbraInputFile from '../../../components/elements/form/AmbraInputFile';
import Button from '../../../components/elements/button/Button';
import { RiSave2Fill } from 'react-icons/ri';
import userService from '../../../services/userService';
import facialService from '../../../services/facialService';
import useAlerts from '../../../hooks/useAlerts';

export const CBUploadPhotoPage = () => {

    const [requestFormData, setRequestFormData] = useState<FormData>();
    const [selectedCB, setSelectedCB] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [data, setData] = useState<any>([]);
    const [selectData, setSelectData] = useState<any>([]);
    const [updateCounter, setUpdateCounter] = useState<number>(0);

    useEffect(() => {
        let companyId = localStorage.getItem('company');
        if(companyId) {
            setCompany(companyId);
        }
    }, []);

    useEffect(() => {
        if(company !== '') {
            userService.list(company).then((response) => {
                if(response.status === 200) {
                    setData(transformData(response.data));
                }
            });
        }
    }, [updateCounter, company]);

    useEffect(() => {
        if(data && data.length > 0) {
            let selD: any[] = [];
            data.map((el: any) => {
                selD.push({
                    text: el.name,
                    value: el.id
                });
            })
            setSelectData(selD);
        }
    }, [data]);

    const alerts = useAlerts();

    const transformData = (dataparam: any) => {
        setData(dataparam);
    }

    const store = () => {
        if(selectedCB?.length === 0 || !requestFormData) {
            alerts.showAlert('error', 'Ops', 'Preencha todos os campos corretamente.');
            return;
        }
        facialService.save(selectedCB, requestFormData).then((response) => {
            if(response.status) {
                alerts.showAlert('success', 'Successo', 'Foto enviada com sucesso.');
                setSelectedCB('');
            }
        }).catch((error) => {
            alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar a foto.');
        });
    }
    
    const uploadFile = (e: any) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        setRequestFormData(formData);
        //formData.append('company', company);
        
        // fileService.uploadFile(formData).then((response: any) => {
        //     if(response.status) {
        //         setFileUrl(response.data.full_url);
        //     }
        // });
    }

    return (
        <AuthContainer>
            <div className="m-3 mt-4 mb-4">
                <div className="mb-4">
                    <Title> Configurações do reconhecimento facial </Title>
                    <Subtitle> Reconhecimento facial {">"} Adicionar foto</Subtitle>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-1 mb-2">
                <AmbraInputSelect
                    label='Selecione um colaborador'
                    className=''
                    onChange={(e: any) => {setSelectedCB(e.target.value)}}
                    placeholder={'Selecione um colaborador'}
                    value={selectedCB}
                    data={selectData}
                />
            </div>
            <div className="grid grid-cols-1 gap-1 mb-2">
                <AmbraInputFile
                    label='Envie uma imagem do rosto do colaborador'
                    className=''
                    onChange={(e: any) => {uploadFile(e)}}
                />
            </div>

            <div className="grid left grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                <div className='text-right col-span-2'>
                    <Button type='primary' onClick={() => {store()}}>Salvar <RiSave2Fill className="inline-block" /></Button>
                </div>
            </div>
        </AuthContainer>
    )
}
