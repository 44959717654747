import { useEffect, useState } from 'react';
import { RiArrowLeftLine, RiSave2Fill } from 'react-icons/ri';
import { useHistory } from 'react-router';
import Button from '../../../components/elements/button/Button';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import useAlerts from '../../../hooks/useAlerts';
import { useQuery } from '../../../hooks/useQuery';
import questionsService from '../../../services/questionsService';

const PTCreateQuestPage = () => {
    const history = useHistory();
    const query = useQuery();
    const alerts = useAlerts();

    const [name, setName] = useState<string>('');
    const [questionStatement, setQuestionStatement] = useState<string>('');
    const [mandatoryPicture, setMandatoryPicture] = useState<boolean>(false);
    const [answerType, setAnswerType] = useState<'unique_choice' | 'multiple_choice' | 'dissertative_text'>('dissertative_text');
    const [order, setOrder] = useState<string>('0');
    const [topic, setTopic] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [options, setOptions] = useState<string>('');
    const [active, setActive] = useState<boolean>(true);
    const [picture, setPicture] = useState<boolean>(false);
    const [pictureStatement, setPictureStatement] = useState<string>('');

    const [editId, setEditId] = useState<string>('0');

    useEffect(() => {
        let company = localStorage.getItem('company');
        if(company) {
            setCompany(company);
        }
        let id = query.get('id');
        if(id) {
            setEditId(id);
        }
        let topicId = query.get('topicId');
        if(topicId) {
            setTopic(topicId);
        }
    }, []);

    useEffect(() => {
        if(editId !== '0') {
            questionsService.get(editId).then((response) => {
                if(response.status) {
                    getObjectFromRequest(response.data);            
                }
            });
        }
    }, [editId]);

    const getObjectFromRequest = (area: any) => {
        setName(area.name);
        setQuestionStatement(area.question_statement);
        setMandatoryPicture(area.mandatory_picture);
        setAnswerType(area.answer_type);
        setOrder(area.order);
        setTopic(area.topic);
        setCompany(area.company);
        setOptions(area.options.join(';' || '.' || ':'));
        setActive(area.active);
        setPicture(area.picture);
        setPictureStatement(area.picture_statement);
    }

    const handleStoreError = () => {
        alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar a questão.');
    }

    const store = () => {
        let requestData = {
            name : name,
            question_statement : questionStatement,
            mandatory_picture : mandatoryPicture,
            answer_type : answerType,
            order : parseInt(order+''),
            topic : topic,
            company : company,
            options : options.split(';'),
            active: active,
            picture: picture,
            picture_statement: pictureStatement
        };
        if(editId === '0') {
            questionsService.store(requestData).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Questão salva com sucesso');
                    history.push('/auth/PT/Topic?id='+topic);
                }else{
                    handleStoreError();
                }
            }).catch((error) => {
                handleStoreError();
            });
        }else{
            questionsService.put(editId, requestData).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Questão salva com sucesso');
                    history.push('/auth/PT/Topic?id='+topic);
                }else{
                    handleStoreError();
                }
            }).catch((error) => {
                handleStoreError();
            });
        }
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Adicionar Questão  </Title>
                    <Subtitle> Permissão de Trabalho {'>'} Adicionar PT {'>'} Adicionar Tópico {'>'} Adicionar Pergunta </Subtitle>
                </div>
                
                <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
                    <div className="col-span-4">
                        <AmbraInputText
                            label="Nome da questão"
                            placeholder="Digite o nome da questão"
                            validation="required"
                            value={name}
                            onChange={(e: any) => { setName(e.target.value) }}
                        />
                    </div>
                </div>
                
                <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
                    <div className="col-span-4">
                        <AmbraInputText
                            label="Enunciado da questão"
                            placeholder="Digite o nome da questão"
                            validation="required"
                            value={questionStatement}
                            onChange={(e: any) => { setQuestionStatement(e.target.value) }}
                        />
                    </div>
                    <div className="col-span-3">
                        <AmbraInputCheckbox
                            label="Solicitar foto"
                            onChange={(e) => {setPicture(!picture)}}
                            checked={picture}
                            value='Solicitar'
                        />
                    </div>
                </div>

                {
                    picture &&
                    <>
                        <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
                            <div className="col-span-4">
                                <AmbraInputText
                                    label="Texto para a solicitação da foto"
                                    placeholder="Digite o nome do tópico"
                                    validation=""
                                    value={pictureStatement}
                                    onChange={(e: any) => { setPictureStatement(e.target.value) }}
                                />
                            </div>
                            <div className="col-span-3">
                                <AmbraInputCheckbox
                                    label="Foto obrigatória"
                                    onChange={(e) => {setMandatoryPicture(!mandatoryPicture)}}
                                    checked={mandatoryPicture}
                                    value='Marcar como obrigatório'
                                />
                            </div>
                        </div>
                    </>
                }

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mb-5">

                    {/* unique_choice' | 'multiple_choice' | 'dissertative_text */}

                    {/* Texto livre, multipla escolha, alternativas */}
                    <div>
                        <AmbraInputCheckbox
                            label="Tipo da questão"
                            onChange={(e) => {setAnswerType('unique_choice')}}
                            checked={answerType === 'unique_choice'}
                            value='Alternativas'
                        />
                    </div>
                    <div>
                        <AmbraInputCheckbox
                            label=""
                            onChange={(e) => {setAnswerType('multiple_choice')}}
                            checked={answerType === 'multiple_choice'}
                            value='Múltipla escolha'
                        />
                    </div>
                    <div>
                        <AmbraInputCheckbox
                            label=""
                            onChange={(e) => {setAnswerType('dissertative_text')}}
                            checked={answerType === 'dissertative_text'}
                            value='Texto livre'
                        />
                    </div>
                </div>

                {/* 
                    Aqui podemos usar por exemplo -> https://react-select.com
                */}
                {
                    (answerType !== 'dissertative_text') &&
                    <>
                        <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
                            <div className="col-span-4">
                                <AmbraInputText
                                    label="Opções de resposta"
                                    placeholder="Digite as opções de resposta separadas por ';', ':', '.' (ponto e vírgula)"
                                    validation="required"
                                    value={options}
                                    onChange={(e: any) => { setOptions(e.target.value) }}
                                />
                            </div>
                        </div>
                    </>
                }

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div className='col-start-6'>
                        <Button type='primary' className='mr-2' onClick={() => {history.push('/auth/PT/Topic?id='+topic)}}><RiArrowLeftLine className="inline-block text-white-700" /> Voltar</Button>
                        <Button type='primary' className='ml-2' onClick={() => { store() }}>Salvar Pergunta <RiSave2Fill className="inline-block text-white-700" /></Button>
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default PTCreateQuestPage