import React from "react";

interface BoxPropsInterface {
  className?: string;
}

interface BoxStateInterface {}

class Box extends React.Component<BoxPropsInterface, BoxStateInterface> {
  //eslint-disable-next-line
  constructor(props: any) {
    super(props);
  }

  //TODO: Prop tools, Prop Title, Prop Header, Prop Footer

  render() {
    return (
      <div
        className={
          "bg-white p-4 block w-full shadow rounded text-gray-600 mb-4 " +
          (this.props.className ? this.props.className : "")
        }
      >
        {this.props.children}
      </div>
    );
  }
}

export default Box;
