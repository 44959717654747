export const getCurrentDateDdMmYYYY = () => {
    let date = new Date();
    return ('0'+date.getDate()).slice(-2)+'/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear()
}

export const getCurrentDateDdMmYYYYHour = () => {
    let date = new Date();
    return ('0'+date.getDate()).slice(-2)+'/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear()
}

export const getCurrentDateYYYYMmDd = () => {
    let date = new Date();
    return date.getFullYear() + '-' + ('0'+(date.getMonth()+1)).slice(-2) + '-' + ('0'+date.getDate()).slice(-2);
}

export const convertDateFromDdMmYyyyToAPI = (date: string) => {
    let split = date.split('/');
    return split[2]+'-'+split[1]+'-'+split[0];
}