import { AxiosResponse } from "axios";
import api from "./api";

interface companiesServiceInterface {
    list: () => Promise<AxiosResponse<any>>;
    store: (data: companiesData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: companiesData) => Promise<AxiosResponse<any>>;
    findCompanyById: (id: number|string) => Promise<AxiosResponse<any>>;
}

interface companiesData {
    id?: string;
    name?: string;
    address?: string;
    postal_code?: string;
    cnpj?: string;
    medical_emergency_phone?: string;
    medical_clinic_phone?: string;
    brigade_phone?: string;
    firefighter_phone?: string;
    emergency_phone?: string;
    active?: string;
}

const prefix = 'companies';

const companiesService: companiesServiceInterface = {
    list: () => 
        api.get('/'+prefix),
    store: (data: companiesData) => 
        api.post('/'+prefix, data),
    get: (id: number|string) => 
        api.get('/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/'+prefix+'/'+id),
    put: (id: number|string, data: companiesData) =>
        api.put('/'+prefix+'/'+id, data),
    findCompanyById: (id: number|string) =>
        api.get('/'+prefix+'/'+id)
}

export default companiesService;