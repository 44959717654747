export const maskCelPhone = (value: string) => {
    let val = value.replace(/[^0-9]/g, '').split('');
    let valsize = val.length;
    let result = '';
    if(valsize >= 1)
        result += '('+val[0];
    if(valsize >= 2)
        result += val[1];
    if(valsize >= 3) {
        result += ') ';
        for(var i = 2; i < Math.min(6, valsize); i++)
        result += val[i];
    }
    if(valsize >= 7 && valsize < 11) {
        result += '-';
        for(var i = 6; i < Math.min(11,valsize); i++)
            result += val[i];
    }
    if(valsize >= 7 && valsize >= 11) {
        result += val[6];
        result += '-';
        for(var i = 7; i < Math.min(11,valsize); i++)
            result += val[i];
    }
    return result;
}

export const maskCPFCNPJ = (value: string) => {
    value = value.replace(/[^0-9]/g, '');
    if(value.length <= 11) {
        return maskCPF(value);
    }
    return maskCNPJ(value);
}

export const maskCPF = (value: string) => {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const maskCNPJ = (value: string) => {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
}

export const maskCEP = (value: string) => {
    return value.replace(/(\d{5})(\d{3})/g,"\$1\-\$2");
}

export const maskValue = (value: string) => {
    return value;
}

export const maskHour = (value: string) => {
    value = value.replace(/[0-2]/g ? /[0-3]/ : /[0-9]/, '');
    return value;
}

export const maskDate = (value: string) => {
    return value.replace(/(\d{2})(\d{2})(\d{4})/,"$1/$2/$3");
}

export const maskNumber = (value: string) => {
    return value.replace(/[^0-9]/g, '');
}

export const maskFloatToBRL = (value: any) => {
    return parseFloat(value).toLocaleString('pt-BR', {minimumFractionDigits: 2});
}

export const maskBRLToFloat = (value: string|number) => {
    return parseFloat((value+'').replace(/\./g, '').replace(',', '.'));
}

export const maskBRL = (value: any) => {
    value = maskNumber(value+'');
    if(value.length < 3) {
        return value;
    }
    value = value.slice(0, -2) + '.' + value.slice(-2);
    if(value !== '.') {
        let parsedValue = parseFloat(value).toLocaleString('pt-BR', {minimumFractionDigits: 2});
        let splitParsedValue = parsedValue.split(',');
        if(splitParsedValue.length == 2 && splitParsedValue[1].length == 1)
            return parsedValue+'0';
        else
            return parsedValue;
    } 
    else
        return '';
}

export const fromJSDateToViewMask = (timestamp: string) => {
    let date = new Date(timestamp);
    return ('0'+date.getDate()).slice(-2)+'/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear()+' '+('0'+date.getHours()).slice(-2)+':'+('0'+date.getMinutes()).slice(-2);
}

export const fromJSDateToViewMaskOnlyDate = (timestamp: string) => {
    let date = new Date(timestamp);
    return ('0'+date.getDate()).slice(-2)+'/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear();
}