import React, { useState } from 'react'
import { useEffect } from 'react';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import ModalLg from '../../../components/elements/modal/ModalLg';
import Title from '../../../components/elements/text/Title';
import Button from '../../../components/elements/button/Button';
import useAlerts from '../../../hooks/useAlerts';
import { useQuery } from '../../../hooks/useQuery';
import { useHistory } from 'react-router';
import releasersService from '../../../services/releasersService';
import { RiSave2Fill, RiArrowLeftLine } from 'react-icons/ri';
import { updateShorthandPropertyAssignment } from 'typescript';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import unityServices from '../../../services/unityServices';

interface LiberatorFormModalPropsInterface {
    show: boolean;
    handleClose: () => void;
    handleUpdate: () => void;
    liberator: any;
}

const LBModal = (props: LiberatorFormModalPropsInterface) => {
    const alerts = useAlerts();
    const history = useHistory(); 
    const query = useQuery();

    const [editId, setEditId] = useState<string>('0');
    const [company, setCompany] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [active, setActive] = useState<boolean>(true);
    const [liberator, setLiberator] = useState<string>('');
    const [unityData, setUnityData] = useState<any[]>([]);
    const [unitySelectData, setUnitySelectData] = useState<any[]>([]);
    const [unity, setUnity] = useState<string>("");

    useEffect(() => {
        if(!props.show) {
            setEditId('0');
            setName('');
            setUnity('');
            setActive(true);
        }
        let company = localStorage.getItem('company');
        if (company) {
            setCompany(company);
        }
    }, [props.show]);

    useEffect(() => {
        if (company && company.length > 0) {
            unityServices.list(company).then((response) => {
                if (response.status === 200) {
                setUnityData(response.data);
                }
            });
        }
    }, [company]);

    useEffect(() => {
        if(unityData) {
            let selectData: any[] = [];
            unityData.map((el) => {
                selectData.push({
                    text: el.name,
                    value: el.id
                })
            });
            setUnitySelectData(selectData);
        }
    }, [unityData]);

    useEffect(() => {
        if(props.liberator?.id) {
            setEditId(props.liberator?.id);
            getObjectFromRequest(props.liberator);
        }
    }, [props.liberator]);

    const getObjectFromRequest = (area: any) => {
        setName(area.name);
        setActive(area.active);
        setCompany(area.company);
        setUnity(area.unity);
    }

    useEffect(() => {
        if(editId !== '0') {
            releasersService.get(editId).then((response) => {
                if(response.status) {
                    getObjectFromRequest(response.data);            
                }
            });
        }
    }, [editId]);

    const handleLiberatorError = () => {
        alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar o Liberador.');
    }

    const store = () => {
        let requestData = {
            name : name,
            active : true,
            company : company,
            unity: unity
        };
        if(editId === '0') {
            releasersService.store(requestData).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Liberador salvo com sucesso');
                    props.handleUpdate();
                    props.handleClose();
                }else{
                    handleLiberatorError();
                }
            }).catch((error) => {
                handleLiberatorError();
            });
        }else{
            releasersService.put(editId, requestData).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Liberador salvo com sucesso');
                    props.handleUpdate();
                    props.handleClose();
                }else{
                    handleLiberatorError();
                }
            }).catch((error) => {
                handleLiberatorError();
            });
        }
    }

    return (
        <ModalLg show={props.show} handleClose={() => { props.handleClose() }}>
            <div className="text-gray-700 bg-ambra-white">
                <Title>Adicionar liberador{editId ? '' : '- Edição'}</Title>
                <div className="grid grid-cols-1 lg:grid-cols-1 gap-1 mt-4">
                    <AmbraInputText
                        label="Nome"
                        placeholder="Digite o nome do liberador"
                        validation="required"
                        value={name}
                        onChange={(e: any) => { setName(e.target.value.toUpperCase()) }}
                    />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-1 mt-4">
                    <div className="col-span-2">
                        <AmbraInputSelect
                        label="Selecionar a unidade"
                        validation="required"
                        placeholder="Selecione a unidade do colaborador"
                        value={unity}
                        data={unitySelectData}
                        onChange={(e: any) => {
                            setUnity(e.target.value);
                        }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div className="col-start-6">
                        <Button type="primary" className='mr-2' onClick={() => { props.handleClose() }}> <RiArrowLeftLine className="inline-block text-white-700" /> Voltar</Button>
                        <Button type="primary" onClick={() => {store()}}>Salvar <RiSave2Fill className="inline-block text-white-700" /> </Button>
                    </div>
                </div>
            </div>
        </ModalLg>
    )
}

export default LBModal;