import React, { useEffect, useState } from 'react'
import AmbraTable from '../../../components/elements/table/AmbraTable';
import AmbraInputFile from '../../../components/elements/form/AmbraInputFile';
import AmbraInputRadio from '../../../components/elements/form/AmbraInputRadio';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import Box from '../../../components/elements/Box';
import Button from '../../../components/elements/button/Button';
import Title from '../../../components/elements/text/Title';
import Subtitle from '../../../components/elements/text/Subtitle';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import Pagination from '../../../components/elements/pagination/Pagination';
import ModalLg from '../../../components/elements/modal/ModalLg';
import LBModal from './LBModal';
import { useHistory } from 'react-router';
import releasersService from '../../../services/releasersService';
import useAlerts from '../../../hooks/useAlerts';
import unityServices from '../../../services/unityServices';

const LBPage = () => {
    const history = useHistory();
    const alerts = useAlerts();

    const [updateCounter, setUpdateCounter] = useState<number>(0);
    const [showLBModal, setShowLBModal] = useState<boolean>(false);
    const [modalModel, setModalModel] = useState<any>();
    const [company, setCompany] = useState<string>('');

    const handleCreateClick = () => {
        setModalModel(null);
        setShowLBModal(true);
    }

    const handleEditClick = (element: any) => {
        setModalModel(element);
        setShowLBModal(true);
    }

    const [dataReleasers, setDataReleasers] = useState<any>([]);

    useEffect(() => {
        let company = localStorage.getItem('company');
        if(company) {
            setCompany(company);
        }
    }, []);

    useEffect(() => {
        if(company.length > 0) {
            releasersService.list(company).then((response) => {
                if(response.status) {
                    setDataReleasers(response.data);
                    console.log(dataReleasers);
                }
            });
        }
    }, [updateCounter, company]);

    const destroyReleaser = (releaserId: string) => {
        alerts.showAlertConfirm('warning', 'Você realmente deseja excluir esse tipo de liberador?', 'Isso não poderá ser desfeito.', () => {
            releasersService.destroy(releaserId).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'O tipo de liberador foi removido com sucesso');
                    setUpdateCounter(updateCounter + 1);
                }
            });
        }, () => {});
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Liberadores </Title>
                    <Subtitle> Cadastro de Liberadores </Subtitle> 
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                    <div>
                        <AmbraTable
                            title='Tipos adicionados'
                            data={dataReleasers}
                            renderColumns={[
                                { label: 'Nome', dataColumn: 'name' },
                                { label: 'Unidade', dataColumn: 'unity_name' },
                            ]}
                            showEdit={true}
                            showDelete={true}
                            showFile={false}
                            search={false}
                            onEdit={(element: any) => { handleEditClick(element) }}
                            onDelete={(element: any) => { destroyReleaser(element.id) }}
                            onFile={(element: any) => {  }}
                            route=''
                            renderTools={<div style={{display: 'inline'}} onClick={ (e: any) => {e.preventDefault(); handleCreateClick()}}>adicionar tipo de liberador</div>}
                        />
                    </div>
                </div>
                
                <LBModal show={showLBModal} liberator={modalModel} handleClose={() => {setShowLBModal(false)}} handleUpdate={() => {setUpdateCounter(updateCounter+1)}}/>
            </div>
        </AuthContainer>
    )
}

export default LBPage