import React, { useEffect, useState } from 'react';

interface PaginationPropsInterface {
    currentPage: number;
    pages: number;
    onPageChange: (page: number) => void;
} 

const Pagination = (props: PaginationPropsInterface) => {
    
    const [pageItems, setPageItems] = useState<any[]>([]);
    
    const PAGE_INTERVAL = 3;

    useEffect(() => {
        let startPage = Math.max(props.currentPage-PAGE_INTERVAL, 1);
        let endPage = Math.min(props.currentPage+PAGE_INTERVAL, props.pages);
        let pageItemsArray = [];
        for(let i = startPage; i <= endPage; i++)
            pageItemsArray.push(i);
        setPageItems(pageItemsArray);
    }, [props.pages, props.currentPage]);

    return (
        <div className="text-center text-gray text-base mt-1">
                Resultado em {props.pages} página(s)
                <br />
                <ul className="pt-2 pb-2 text-gray-700">
                    <li 
                    onClick={() => {props.onPageChange(1)}}
                    className={'bg-gray-50 p-2 shadow-sm rounded-sm inline cursor-pointer hover:text-blue-700 transition mr-1'}
                    >
                        {'<<'}
                    </li>
                    {
                        pageItems.map((el, index) => 
                            <li 
                            onClick={() => {props.onPageChange(el)}}
                            key={index} 
                            className={'bg-white p-2 shadow-sm rounded-sm inline cursor-pointer hover:text-blue-700 transition mr-1 ' + (el == props.currentPage ? 'font-bold' : '')}
                            >
                                {el}
                            </li>
                        )
                    }
                    <li 
                    onClick={() => {props.onPageChange(props.pages)}}
                    className={'bg-gray-50 p-2 shadow-sm rounded-sm inline cursor-pointer hover:text-blue-700 transition'}
                    >
                        {'>>'}
                    </li>
                </ul>
            </div>
    )
}

export default Pagination;