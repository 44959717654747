import { useRecoilState } from "recoil";
import { alertsState } from '../../../state/alertState';
import Alert from "./Alert";

const AlertManager = () => {

    const [alerts, setAlerts] = useRecoilState(alertsState);

    return (
        <>
        {
            alerts.map((el) => 
                <Alert 
                    key={el.id} 
                    type={el.type} 
                    title={el.title} 
                    content={el.content} 
                    onAlertClose={() => {
                        setAlerts(alerts.filter((elFilter) => {
                            return elFilter !== el;
                        }));
                    }}
                    confirm={el.confirm}
                    onAlertConfirm={el.onConfirm}
                    onAlertCancel={el.onCancel}
                />
            )
        }
        </>
    )
}

export default AlertManager;