import  { FC } from 'react';
import { Redirect } from 'react-router';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
    render: any;
    roles?: string; // user|manager|admin
}

/**
 * user|roles
 */

export const AuthGuard: FC<AuthGuardProps> = ({ render, roles }) => 
{
    const { isLoggedIn, getUserRole} = useAuth();

    let matchRoles = true;
    let loggedUserRoles = getUserRole();
    if(!loggedUserRoles) {
        matchRoles = false;
    }else{
        if(roles && roles.length > 0) {
            matchRoles = false;
            let rolesAr = roles.split('|');
            for(let i = 0; i < rolesAr.length; i++) {
                if(loggedUserRoles.includes(rolesAr[i])) {
                    matchRoles = true;
                }
            }
        }
    }

    return (
        <>
        {
            isLoggedIn && matchRoles ? render : <>
                <div className="text-center pt-5 text-2xl text-gray-600">
                    Você precisa estar autenticado.
                    <br />
                    Você será redirecionado em breve.
                </div>
                <Redirect to="/" />
            </>
        }
        </>
    )
}