import React, { useState, useEffect } from 'react'
import AmbraTable from '../../../components/elements/table/AmbraTable';
import AmbraInputFile from '../../../components/elements/form/AmbraInputFile';
import AmbraInputRadio from '../../../components/elements/form/AmbraInputRadio';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import Box from '../../../components/elements/Box';
import Button from '../../../components/elements/button/Button';
import Title from '../../../components/elements/text/Title';
import Subtitle from '../../../components/elements/text/Subtitle';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import Pagination from '../../../components/elements/pagination/Pagination';
import { RiSave2Fill, RiArrowLeftLine } from 'react-icons/ri';
import { useQuery } from '../../../hooks/useQuery';
import useAlerts from '../../../hooks/useAlerts';
import trainingsService from '../../../services/trainingServices';
import { useHistory } from 'react-router';
import { type } from 'os';
import { fromJSDateToViewMaskOnlyDate } from '../../../utils/masks';
import { convertDateFromDdMmYyyyToAPI } from '../../../utils/general';
import workPermissionsService from '../../../services/workPermissionsService';
import fileService from '../../../services/fileService';
import asoService from '../../../services/asoService';

const CBCreateTrainingPage = () => {
    const history = useHistory();
    const query = useQuery();
    const alerts = useAlerts();

    const [editId, setEditId] = useState<string>('0');
    const [company, setCompany] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [emissionDate, setEmissionDate] = useState<string>('');
    const [expiryDate, setExpiryDate] = useState<string>('');
    const [attanchments, setAttanchments] = useState<string>('');
    const [user, setUser] = useState<string>('');
    const [workPermission, setWorkPermission] = useState<string>('');
    const [workPermissions, setWorkPermissions] = useState<any[]>([]);
    const [workPermissionsLoaded, setWorkPermissionsLoaded] = useState<any[]>([]);
    const [workPermissionsSelectData, setWorkPermissionsSelectData] = useState<any[]>([]);
    const [validSelected, setValidSelected] = useState<string>('');

    const [updateCounter, setUpdateCounter] = useState<number>(0);
    
    const [workPermissionsTmp, setWorkPermissionsTmp] = useState<any[]>([]);

    useEffect(() => {
        let companyId = localStorage.getItem('company');
        if(companyId) {
            setCompany(companyId);
        }
        let paramId = query.get('id');
        if(paramId) {
            setEditId(paramId);
        }
        let userId = query.get('userId');
        if(userId) {
            setUser(userId);
        }
    }, []);

    useEffect(() => {
        if(editId !== '0') {
            trainingsService.get(editId).then((response) => {
                if(response.status) {
                    getObjectFromRequest(response.data);            
                }
            });
        }
    }, [editId]);

    useEffect(() => {
        if(company.length > 0) {
            workPermissionsService.list(company).then((response) => {
                if(response.status) {
                    let newWorkPermissions: any[] = [...response.data];
                    for(let i = 0; i < newWorkPermissions.length; i++) {
                        newWorkPermissions[i].checked = false;
                    }
                    setWorkPermissions( newWorkPermissions );
                    setWorkPermissionsTmp( newWorkPermissions );
                }
            });
        }
    }, [company]);

    useEffect(() => {
        let wkSelectData: any[] = [];
        workPermissions.map((el) => {
            wkSelectData.push({
                text: el.name,
                value: el.id
            })
        });
        setWorkPermissionsSelectData(wkSelectData);
    }, [workPermissions]);

    useEffect(() => {
        if(workPermissionsTmp.length > 0 && workPermissionsLoaded.length > 0) {
            let ids: any[] = [];
            workPermissionsLoaded.map((el) => {
                ids.push(el.work_permission);
            });
            
            let newWp = [...workPermissions];
            for(let i = 0; i < newWp.length; i++) {
                if(ids.indexOf(newWp[i].id) !== -1) {
                    newWp[i].checked = true;
                }
            }
            setWorkPermissions(newWp);
        }
    }, [workPermissionsTmp, workPermissionsLoaded]);

    useEffect(() => {
        if(validSelected.length > 0) {
            let vInt = parseInt(validSelected);
            let expiryDateNew = new Date(convertDateFromDdMmYyyyToAPI(emissionDate));
            expiryDateNew.setMonth(expiryDateNew.getMonth()+vInt);
            let newDateString = ('0'+expiryDateNew.getDate()).slice(-2) + '/' + ('0'+(expiryDateNew.getMonth()+1)).slice(-2) + '/' + (expiryDateNew.getFullYear());
            // console.log(newDateString);
            setExpiryDate( newDateString );
        }
    }, [validSelected]);

    const getObjectFromRequest = (requestData: any) => {
        setName(requestData.name);
        setType(requestData.type);
        setEmissionDate(fromJSDateToViewMaskOnlyDate(requestData.emission_date));
        setExpiryDate(fromJSDateToViewMaskOnlyDate(requestData.expiry_date));
        setAttanchments(requestData.attanchments);
        setCompany(requestData.company);
        setUser(requestData.user);
        setWorkPermission(requestData.work_permission);
        setWorkPermissionsLoaded(requestData.work_permissions);
        setValidSelected(requestData.expiration_months);
    }

    const handleStoreError = () => {
        alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar o treinamento.');
    }

    const store = () => {
        let workPermissionsRequest: any[] = [];
        workPermissions.map((el) => {
            if(el.checked) {
                workPermissionsRequest.push(el.id);
            }
        });

        let requestData = {
            name: name,
            type: 'Treinamento',
            emission_date: convertDateFromDdMmYyyyToAPI(emissionDate),
            expiry_date: convertDateFromDdMmYyyyToAPI(expiryDate),
            attanchments: attanchments,
            company: company,
            user: user,
            work_permissions: workPermissionsRequest,
            expiration_months: validSelected
        };
        if(editId === '0') {
            trainingsService.store(requestData).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Treinamento salvo com sucesso');
                    history.push('/auth/CBCollaborator?id='+user);
                }else{
                    handleStoreError();
                }
            }).catch((error) => {
                handleStoreError();
            });
        }else{
            trainingsService.put(editId, requestData).then((response) => {
                if(response.status) {
                    alerts.showAlert('success', 'Sucesso', 'Treinamento salvo com sucesso');
                    history.push('/auth/CBCollaborator?id='+user);
                }else{
                    handleStoreError();
                }
            }).catch((error) => {
                handleStoreError();
            });
        }
    }

    const uploadFile = (e: any) => {
        let formData = new FormData();
        
        formData.append('file', e.target.files[0]);
        formData.append('company', company);
        
        fileService.uploadFile(formData).then((response: any) => {
            if(response.status) {
                setAttanchments(response.data.full_url);
            }
        });
    }

    const setWorkPermissionChecked = (index: number, checked: boolean) => {
        let wpCopy = [...workPermissions];
        wpCopy[index].checked = checked;
        setWorkPermissions(wpCopy);
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Adicionar Treinamento </Title>
                    <Subtitle> Colaboradores {'>'} Adicionar Colaborador {'>'} Adicionar Treinamento </Subtitle>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Nome do treinamento"
                            placeholder="Digite o nome do treinamento"
                            validation="required"
                            value={name}
                            onChange={(e: any) => { setName(e.target.value.toUpperCase()) }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        {/* <AmbraInputSelect
                            label="Selecione o tipo de treinamento (PT relacionada)"
                            placeholder="Selecione o tipo de treinamento"
                            data={workPermissionsSelectData}
                            validation="required"
                            value={workPermission}
                            onChange={(e: any) => { setWorkPermission(e.target.value) }}
                        /> */}
                        {
                            workPermissions.map((el: any, index: number) =>
                                <AmbraInputCheckbox
                                label={index === 0 ? 'Este treinamento está relacionado com quais permissões de trabalho?' : ''}
                                onChange={(e) => { setWorkPermissionChecked(index, !el.checked) }}
                                checked={el.checked}
                                value={el.name}
                            />
                            )
                        }
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Data de emissão"
                            placeholder="Ex. 20/10/2022"
                            validation="required|date"
                            mask='date'
                            value={emissionDate}
                            onChange={(e: any) => { setEmissionDate(e.target.value) }}
                        />
                    </div>
                </div>

                {
                    emissionDate.length === 10 &&
                    <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                        <div>
                            <AmbraInputSelect
                                label="Prazo de validade"
                                placeholder="Selecione o prazo de validade"
                                data={[
                                    {
                                        text: '6 meses',
                                        value: '6'
                                    },
                                    {
                                        text: '12 meses',
                                        value: '12'
                                    },
                                    {
                                        text: '24 meses',
                                        value: '24'
                                    },
                                ]}
                                validation="required"
                                value={validSelected}
                                onChange={(e: any) => { setValidSelected(e.target.value) }}
                            />
                        </div>
                    </div>
                }

                {/* {
                    editId !== '0' &&
                    <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                        <div>
                            <AmbraInputText
                                label="Prazo de validade"
                                placeholder="Ex. 20/10/2022"
                                validation="required|date"
                                mask='date'
                                value={expiryDate}
                                onChange={(e: any) => { setExpiryDate(e.target.value) }}
                            />
                        </div>
                    </div>
                } */}

                {/* <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        <AmbraInputText
                            label="Anexo"
                            placeholder="Anexo(teste)"
                            validation="required"
                            value={attanchments}
                            onChange={(e: any) => { setAttanchments(e.target.value) }}
                        />
                    </div>
                </div> */}

                <div className='grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5 mt-5'>
                    <div className=''>
                        <div className='text-center'>
                        </div>
                        <AmbraInputFile
                            className="mt-5"
                            label={attanchments ? 'Alterar anexo' : 'Anexo'}
                            onChange={(e: any) => {uploadFile(e)}}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div className='col-start-6'>
                        <Button type='primary' className='mr-2' onClick={() => { history.push('/auth/CBCollaborator?id='+user); }}>
                            <RiArrowLeftLine className="inline-block text-white-700" /> Voltar</Button>
                        <Button type='primary' className='ml-2' onClick={() => {store()}}>Salvar Treinamento <RiSave2Fill className="inline-block text-white-700" /></Button>
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default CBCreateTrainingPage