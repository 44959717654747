import React, { useState } from 'react'
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { RiSave2Fill, RiArrowLeftLine } from 'react-icons/ri';
import { useQuery } from '../../../hooks/useQuery';
import AmbraTable from '../../../components/elements/table/AmbraTable';
import AmbraInputFile from '../../../components/elements/form/AmbraInputFile';
import AmbraInputRadio from '../../../components/elements/form/AmbraInputRadio';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';
import AmbraInputCheckbox from '../../../components/elements/form/AmbraInputCheckbox';
import Box from '../../../components/elements/Box';
import Button from '../../../components/elements/button/Button';
import Title from '../../../components/elements/text/Title';
import Subtitle from '../../../components/elements/text/Subtitle';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import useAlerts from '../../../hooks/useAlerts';
import Pagination from '../../../components/elements/pagination/Pagination';
import workPermissionsService from '../../../services/workPermissionsService';
import topicsService from '../../../services/topicsService';
import releasersService from '../../../services/releasersService';
import preliminaryRiskAnalysisService from '../../../services/preliminaryRiskAnalysisService';


const PTCreatePage = () => {
    const history = useHistory();
    const query = useQuery();
    const alerts = useAlerts();

    const [editId, setEditId] = useState<string>('0');
    const [name, setName] = useState<string>('');
    const [expiration, setExpiration] = useState<string>('');
    const [company, setCompany] = useState<any>();
    const [PreliminaryRisk, setPreliminaryRisk] = useState<string>('');
    const [isDraft, setIsDraft] = useState<boolean>(false);
    const [bloodPressure, setBloodPressure] = useState<boolean>(false);
    const [bloodPressureMeasureByUser, setBloodPressureMeasureByUser] = useState<boolean>(false);
    const [airPressure, setAirPressure] = useState<boolean>(false);

    const [releasersData, setReleaserData] = useState<any[]>([]);
    const [releasers, setReleasers] = useState<string[]>([]);
    
    const [selectedPreliminaryRiskAnalysis, setSelectedPreliminaryRiskAnalysis] = useState<string>('');
    const [preliminaryRiskAnalysisSelectData, setPreliminaryRiskAnalysisSelectData] = useState<any[]>([]);
    const [preliminaryRiskAnalysisList, setPreliminaryRiskAnalysisList] = useState<any[]>([]);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(1);
    const [updateCounter, setUpdateCounter] = useState<number>(0);
    
    const [startTopics, setStartTopics] = useState<any[]>([]);
    const [endTopics, setEndTopics] = useState<any[]>([]);

    useEffect(() => {
        let newPreliminaryRiskAnalysisSelectData: any[] = [];
        preliminaryRiskAnalysisList.map((el, index) => {
            newPreliminaryRiskAnalysisSelectData.push({
                text: el.name,
                value: '' + el.id
            })
        });
        setPreliminaryRiskAnalysisSelectData(newPreliminaryRiskAnalysisSelectData);
    }, [preliminaryRiskAnalysisList]);

    useEffect(() => {
        let company = localStorage.getItem('company');
        if (company) {
            setCompany(company);
        }
        let id = query.get('id');
        if (id) {
            setEditId(id);
        }
    }, []);

    useEffect(() => {
        if (editId !== '0') {
            workPermissionsService.get(editId).then((response) => {
                if (response.status) {
                    loadObjectFromRequest(response.data);
                }
            });
            
            topicsService.listWP(editId).then((response) => {
                if (response.status === 200) {
                    setStartTopics(transformData(response.data.filter((el: any) => el.start_topic)));
                    setEndTopics(transformData(response.data.filter((el: any) => !el.start_topic)));
                }
            });
        }

    }, [editId, updateCounter]);

    useEffect(() => {
        if (company && company.length > 0) {
            preliminaryRiskAnalysisService.list(company).then((response) => {
                if(response.status === 200) {
                    setPreliminaryRiskAnalysisList(response.data);
                }
            });
            
            releasersService.list(company).then((response) => {
                if (response.status === 200) {
                    setReleaserData(response.data);
                }
            });
        }
    }, [company]);

    const loadObjectFromRequest = (requestData: any) => {
        setCompany(requestData.company);
        setExpiration(requestData.expiration + '');
        setPreliminaryRisk(requestData.preliminary_risk_analysis);
        setSelectedPreliminaryRiskAnalysis(requestData.preliminary_risk_analysis);
        setIsDraft(requestData.is_draft);
        setBloodPressure(requestData.need_blood_pressure);
        setBloodPressureMeasureByUser(requestData.need_blood_pressure_by_user);
        setAirPressure(requestData.need_air_pressure);
        setName(requestData.name);
        
        let releasersList: any[] = [];
        requestData.releasers_list.map((el: any) => {
            releasersList.push(el.releaser);
        });
        setReleasers(releasersList);
    }

    const handleStoreError = () => {
        alerts.showAlert('error', 'Ops', 'Ocorreu um erro ao salvar a PT.');
    }

    const storePT = (redirect: boolean) => {
        let requestData = {
            company: company,
            expiration: parseInt(expiration + ''),
            is_draft: isDraft,
            need_blood_pressure: bloodPressure,
            need_blood_pressure_by_user: bloodPressureMeasureByUser,
            need_air_pressure: airPressure,
            preliminary_risk_analysis: selectedPreliminaryRiskAnalysis,
            name: name,
            releasers: releasers
        };
        if (editId === '0') {
            workPermissionsService.store(requestData).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Sucesso', 'PT salva com sucesso');
                    if(redirect)
                        history.push('/auth/PT');
                } else {
                    handleStoreError();
                }
            }).catch((error) => {
                handleStoreError();
            });
        } else {
            workPermissionsService.put(editId, requestData).then((response) => {
                if (response.status) {
                    alerts.showAlert('success', 'Sucesso', 'PT salva com sucesso');
                    if(redirect)
                        history.push('/auth/PT?id=' + workPermissionsService);
                } else {
                    handleStoreError();
                }
            }).catch((error) => {
                handleStoreError();
            });
        }
    }

    const transformData = (startTopicsData: any) => {
        for (let i = 0; i < startTopicsData.length; i++) {
            //manipulate data operations
            if (startTopicsData[i].mandatory) {
                startTopicsData[i].activeLabel = 'Sim';
            } else {
                startTopicsData[i].activeLabel = 'Não';
            }
            if (startTopicsData[i].mandatory) {
                startTopicsData[i].isDraftLabel = 'Sim';
            } else {
                startTopicsData[i].isDraftLabel = 'Não';
            }
        }
        return startTopicsData;
    }
    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Adicionar Permissão de Trabalho </Title>
                    <Subtitle> Adicionar Permissão de Trabalho {'>'} Adicionar Permissão </Subtitle>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-8 gap-5 mb-5">
                    <div className="col-span-4">
                        <AmbraInputText
                            label="Nome da permissão de trabalho"
                            placeholder="Digite uma permissão de trabalho"
                            validation="required"
                            value={name}
                            onChange={(e: any) => { setName(e.target.value) }}
                        />
                    </div>
                    <div className="col-span-4">
                        <AmbraInputCheckbox
                            label="Situação"
                            onChange={(e) => { setIsDraft(!isDraft) }}
                            checked={isDraft}
                            value='Marcar como rascunho'
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-8 gap-5 mb-5">
                    <div className="col-span-4">
                        {/* <AmbraInputText
                            label="Análise preliminar à ser preenchida"
                            placeholder="Análise preliminar à ser preenchida"
                            validation="required"
                            value={PreliminaryRisk}
                            onChange={(e: any) => { setPreliminaryRisk(e.target.value) }}
                        /> */}
                        <AmbraInputSelect
                            label="Análise preliminar à ser preenchida"
                            placeholder='Selecione a APR'
                            value={selectedPreliminaryRiskAnalysis}
                            data={preliminaryRiskAnalysisSelectData}
                            onChange={(e: any) => { setSelectedPreliminaryRiskAnalysis(e.target.value) }}
                        />
                    </div>
                    <div className="col-span-4">
                        <AmbraInputText
                            label="Validade em horas"
                            placeholder="Digite um número"
                            validation="required|number"
                            type='number'
                            value={expiration}
                            onChange={(e: any) => { setExpiration(e.target.value) }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-8 gap-5 mb-5">
                    <div className="col-span-4">
                        <AmbraInputCheckbox
                            label="Solicitar medição de gases? (Definir funções E.C)"
                            onChange={(e) => { setAirPressure(!airPressure) }}
                            checked={airPressure}
                            value='Sim'
                        />
                    </div>
                    <div className="col-span-4">
                        <AmbraInputCheckbox
                            label="Solicitar aferição da pressão arterial?"
                            onChange={(e) => { setBloodPressure(!bloodPressure) }}
                            checked={bloodPressure}
                            value='Sim'
                        />
                    </div>
                        {
                            bloodPressure && <>
                            <div className="col-span-4">
                            <AmbraInputCheckbox
                                label="A aferição de pressão arterial é realizada pelo colaborador?"
                                onChange={(e) => { setBloodPressureMeasureByUser(!bloodPressureMeasureByUser) }}
                                checked={bloodPressureMeasureByUser}
                                value='Sim. (Caso desmarcada a aferição deverá ser realizada pelo profissional de saúde)'
                            />
                            </div>
                            </>
                        }
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div>
                        {
                            releasersData.map((el, index) => 
                            <AmbraInputCheckbox key={index}
                            value={el.name+' ('+el.unity_name+')'}
                            label={index === 0 ? 'Tipo de liberador' : ''}
                            onChange={(e) => {
                                let indexChange = releasers.indexOf(el.id);
                                let relCopy = [...releasers];
                                if(indexChange === -1) {
                                    relCopy.push(el.id);
                                }else{
                                    relCopy.splice(indexChange, 1);
                                }
                                setReleasers(relCopy);
                            }}
                            checked={releasers.indexOf(el.id) !== -1}
                            />)
                        }
                    </div>
                </div>

                {
                    editId !== '0' &&
                    <>

                        <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                            <div>
                                <AmbraTable
                                    title='Tópicos de início'
                                    data={startTopics}
                                    renderColumns={[
                                        { label: 'Tópico', dataColumn: 'name' },
                                        { label: 'Descrição', dataColumn: 'description' }
                                    ]}
                                    showEdit={true}
                                    showDelete={true}
                                    showFile={false}
                                    search={false}
                                    onEdit={(element: any) => { history.push('/auth/PT/Topic?prId='+editId+'&id='+element.id) }}
                                    onDelete={(element: any) => { setUpdateCounter(updateCounter + 1); }}
                                    onFile={(element: any) => { console.log(element) }}
                                    route={'/auth/PT/Topic?prId=' + editId}
                                    renderTools={<>adicionar tópico</>}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                            <div>
                                <AmbraTable
                                    title='Tópicos de encerramento'
                                    data={endTopics}
                                    renderColumns={[
                                        { label: 'Tópico', dataColumn: 'name' },
                                        { label: 'Descrição', dataColumn: 'description' }
                                    ]}
                                    showEdit={true}
                                    showDelete={true}
                                    showFile={false}
                                    search={false}
                                    onEdit={(element: any) => { history.push('/auth/PT/Topic?start=false&prId='+editId+'&id='+element.id) }}
                                    onDelete={(element: any) => { setUpdateCounter(updateCounter + 1); }}
                                    onFile={(element: any) => { console.log(element) }}
                                    route={'/auth/PT/Topic?start=false&prId=' + editId}
                                    renderTools={<>adicionar tópico</>}
                                />
                            </div>
                        </div>
                    </>

                }


                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div className='col-start-6'>
                        <Button type='primary' className='mr-2' onClick={() => { history.push('/auth/PT') }}><RiArrowLeftLine className="inline-block text-white-700" /> Voltar</Button>
                        <Button type='primary' className='ml-2' onClick={() => { storePT(true) }}>Salvar PT <RiSave2Fill className="inline-block text-white-700" /></Button>
                        <Button type='primary' className='ml-2' onClick={() => { storePT(false) }}>Salvar e continuar na PT <RiSave2Fill className="inline-block text-white-700" /></Button>
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default PTCreatePage