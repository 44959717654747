import { RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri';
import React from 'react';

interface AmbraInputCheckboxInterface {
    label: string;
    onChange?: (e: any) => void;
    className?: any;
    checked: boolean;
    value: string;
}

const AmbraInputCheckbox = (props: AmbraInputCheckboxInterface) => {

    const handleChange = (e: any) => {
        if (props.onChange) {
            props.onChange(e);
        }
    }

    return (
        <div className={props.className}>
            {
                props.label && props.label.length > 0 &&
                <>
                    <label className="text-base font-medium text-gray-700">
                        {props.label}
                    </label>
                    <br />
                </>
            }
            <div className="text-base font-normal color-ambra-gray inline items-center cursor-pointer" onClick={(e: any) => { handleChange(e) }}>
                {
                    props.checked ?
                        <div><RiCheckboxLine className="text-lg text-gray-700 inline" /> {props.value} </div>
                        :
                        <div><RiCheckboxBlankLine className="text-lg text-gray-700 inline" /> {props.value} </div>
                }
            </div>
        </div>
    )
}

export default AmbraInputCheckbox;