import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Button from '../../../components/elements/button/Button';
import AmbraTable from '../../../components/elements/table/AmbraTable';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import useAlerts from '../../../hooks/useAlerts';
import reportService from '../../../services/reportService';
import { fromJSDateToViewMaskOnlyDate } from '../../../utils/masks';
import { convertDateFromDdMmYyyyToAPI } from '../../../utils/general';
import AmbraInputText from '../../../components/elements/form/AmbraInputText';

const RLAnalyzePage = () => {
    const history = useHistory();
    const alerts = useAlerts();

    const [data, setData] = useState<any>([]);
    const [companyId, setCompanyId] = useState<string>('');
    const [updateCounter, setUpdateCounter] = useState<number>(0);

    const [filterCode, setFilterCode] = useState<string>('');
    const [filterDate, setFilterDate] = useState<string>('');
    const [filterSolicitationUser, setFilterSolicitationUser] = useState<string>('');
    const [filterActivity, setFilterActivity] = useState<string>('');

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(1);

    useEffect(() => {
        let company = localStorage.getItem('company');
        if(company) {
            setCompanyId(company);
        }
    }, []);

    useEffect(() => {
        if(companyId.length > 0) {
            reportService.apr(companyId, currentPage, filterCode.trim(), (filterDate.trim().length === 10 ? convertDateFromDdMmYyyyToAPI(filterDate.trim()) : ''), filterSolicitationUser.trim(), filterActivity.trim()).then((response: any) => {
                if(response.status === 200) {
                    setData(transformData(response.data.data));
                    setPages(response.data.last_page);
                }
            });
        }
    }, [companyId, updateCounter, currentPage]);

    const transformData = (data: any) => {
        for(let i = 0; i < data.length; i++) {
            if(data[i].created_at) {
                data[i].created_atLabel = fromJSDateToViewMaskOnlyDate(data[i].created_at);
                console.log(fromJSDateToViewMaskOnlyDate(data[i].created_at));
            }
            else{
                data[i].created_atLabel = '';
            }
            data[i].code = 'A'+data[i].code;
        }
        return data;
    }

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> relatórios </Title>
                    <Subtitle> Relatórios {'>'} Análises preliminares de risco </Subtitle>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mb-1 mt-5 border-bottom">
                    <div>
                        <Button type='Invisible' onClick={() => { history.push('/auth/RL') }}><h1 className="text-xl text-gray-500 uppercase">Permissões de trabalhos</h1></Button>
                    </div>
                    <div>
                        <Button type='visible'><h1 className="text-xl text-gray-900 uppercase">Análises preliminares de risco</h1></Button>
                    </div>
                </div>
                <hr />

                <div className='grid grid-cols-4 lg:grid-cols-4 gap-1 mt-5 color-ambra-gray'>
                    <div className='col-span-4 ambra-table-title text-lg uppercase m-2 font-medium'>
                        Pesquisar
                    </div>
                    <AmbraInputText 
                        label='Código da solicitação'
                        placeholder='Ex. QNCVEV5W'
                        value={filterCode}
                        onChange={(e: any) => {setFilterCode(e.target.value.toUpperCase())}}
                    />
                    <AmbraInputText 
                        label='Data da solicitação'
                        placeholder='Ex. 10/10/2022'
                        value={filterDate}
                        mask="date"
                        validation='date'
                        onChange={(e: any) => {setFilterDate(e.target.value)}}
                    />
                    <AmbraInputText 
                        label='Nome do solicitante'
                        placeholder='Ex. João da Silva'
                        value={filterSolicitationUser}
                        onChange={(e: any) => {setFilterSolicitationUser(e.target.value)}}
                    />
                    <AmbraInputText 
                        label='Atividade'
                        placeholder='Ex. Limpeza tubulação'
                        value={filterActivity}
                        onChange={(e: any) => {setFilterActivity(e.target.value)}}
                    />
                    <div className='col-span-4 text-right'>
                        <Button type='secondary' onClick={() => {
                            setFilterCode('');
                            setFilterDate('');
                            setFilterActivity('');
                            setFilterSolicitationUser('');
                            setCurrentPage(1);
                            setPages(1);
                            setTimeout(() => {
                                setUpdateCounter(updateCounter+1)
                            }, 500);
                        }}>Limpar busca</Button>
                        <Button type='primary' onClick={() => {
                            setCurrentPage(1);
                            setPages(1);
                            setTimeout(() => {
                                setUpdateCounter(updateCounter+1)
                            }, 500);
                        }}>Filtrar resultados</Button>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                    <div>
                        <AmbraTable
                            title='Permissões geradas'
                            data={data}
                            renderColumns={[
                                { label: 'Nome', dataColumn: 'name' },
                                { label: 'Atividade Realizada', dataColumn: 'activity' },
                                { label: 'Nº Solicitação', dataColumn: 'code' },
                                { label: 'Gerado em', dataColumn: 'created_atLabel' }
                            ]}
                            showEdit={false}
                            showDelete={false}
                            showFile={true}
                            search={false}
                            onEdit={(element: any) => { console.log(element) }}
                            onDelete={(element: any) => { console.log(element) }}
                            onFile={(element: any) => { window.open('https://api.pass.seg.br/api/report/'+element.id)}}
                            route=''
                            renderTools={false}
                            currentPage={currentPage}
                            totalPages={pages}
                            onPageChange={(page: number) => {setCurrentPage(page)}}
                        />
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default RLAnalyzePage