import { AxiosResponse } from "axios";
import api from "./api";

interface asoServiceInterface {
    list: (companyId: number|string) => Promise<AxiosResponse<any>>;
    listByUser: (userId: number|string) => Promise<AxiosResponse<any>>;
    store: (data: asoData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: asoData) => Promise<AxiosResponse<any>>;
}

interface asoData {
    name?: string;
    is_fit_for_function?: boolean;
    is_fit_for_exceptional_permission?: boolean;
    exceptional_permissions?: string;
    expiry_date?: string;
    company?: string;
    user?: string;
}

const prefix = 'ocupational_medical_letters';

const asoService: asoServiceInterface = {
    list: (companyId: number|string) => 
        api.get('/all_'+prefix+'/'+companyId),
    listByUser: (userId: number|string) => 
        api.get('/all_ocupational_medical_letters_by_user/'+userId),
    store: (data: asoData) => 
        api.post('/'+prefix, data),
    get: (id: number|string) => 
        api.get('/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/'+prefix+'/'+id),
    put: (id: number|string, data: asoData) =>
        api.put('/'+prefix+'/'+id, data)
}

export default asoService;