import { Component } from "react";

interface ButtonPropsInterface {
  // Primary, Secondary, Danger
  type: string;
  size?: number;
  onClick?: () => void;
  className?: string;
}

interface ButtonStateInterface { }

export default class Button extends Component<
  ButtonPropsInterface,
  ButtonStateInterface
> {
  setButtonClass() {
    let cssClass = "mt-1 mb-1 text-white rounded p-1 px-4 mr-1 ";
    if (this.props.type === "primary") {
      cssClass = cssClass.concat("color-ambra-white bg-ambra-gray uppercase hover:bg-gray-600 transition-all");
    } else if (this.props.type === "secondary") {
      cssClass = cssClass.concat("color-ambra-white bg-gray-500 uppercase");
    } else if (this.props.type === "danger") {
      cssClass = cssClass.concat("text-pink-500 border-pink-700");
    } else if (this.props.type === "invisible") {
      cssClass = cssClass.concat("invisible m-0 ");
    } else if (this.props.type === "visible") {
      cssClass = cssClass.concat("visible m-0 ");
    }

    if (this.props.className) {
      return cssClass + ' ' + this.props.className;
    }
    return cssClass;
  }

  render() {
    return (
      <button className={this.setButtonClass()} onClick={this.props.onClick}>{this.props.children}</button>
    );
  }
}
