import React from 'react'

const Subtitle = (props: any) => {
    return (
        <div className="text-1lg font-normal text-gray-600 text-decoration-underline">
            {props.children}
        </div>
    )
}

export default Subtitle;