import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AmbraInputSelect from '../../../components/elements/form/AmbraInputSelect';
import AmbraTable from '../../../components/elements/table/AmbraTable';
import Subtitle from '../../../components/elements/text/Subtitle';
import Title from '../../../components/elements/text/Title';
import AuthContainer from '../../../components/layout/auth/AuthContainer';
import useAlerts from '../../../hooks/useAlerts';
import reportService from '../../../services/reportService';
import { fromJSDateToViewMask } from '../../../utils/masks';

const DuedNotificationsPage = () => {
    const history = useHistory();
    const alerts = useAlerts();

    const [company, setCompany] = useState<string>('');
    const [data, setData] = useState<any>([]);
    const [updateCounter, setUpdateCounter] = useState<number>(0);
    const [filterPeriod, setFilterPeriod] = useState<string>('all');

    const [pages, setPages] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [denieds, setDenieds] = useState<any>([]);
    
    useEffect(() => {
        let companyId = localStorage.getItem('company');
        if (companyId) {
            setCompany(companyId);
            const loadDenieds = async (companyIdParam: string, currentPage: number) => {
                reportService.denied(companyIdParam, currentPage).then((response) => {
                    let data = response.data.data;
                    setPages(response.data.last_page);
                    if(data) {
                        for(let i = 0; i < data.length; i++) {
                            data[i]['dateform'] = fromJSDateToViewMask(data[i]['duedateformatted']);
                        }
                        setDenieds(data);
                    }
                });
            };
            loadDenieds(companyId, currentPage);
        }
    }, [currentPage]);

    return (
        <AuthContainer>
            <div className='m-3 mt-4 mb-4'>
                <div className='mb-4'>
                    <Title> Vencimentos Notificados</Title>
                    <Subtitle> Acompanhe quais colaboradores estão tentando participar de uma atividade sem estar com o treinamento ou ASO em dia. </Subtitle>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
                    <div>
                        <AmbraTable
                            title='Vencimentos Notificados'
                            data={denieds}
                            renderColumns={[
                                { label: 'Colaborador', dataColumn: 'username' },
                                { label: 'Unidade', dataColumn: 'unityname' },
                                { label: 'Tipo de Solicitação', dataColumn: 'wpname' },
                                { label: 'Data da tentativa', dataColumn: 'dateform' }
                            ]}
                            showEdit={true}
                            showDelete={false}
                            showFile={false}
                            search={false}
                            onEdit={(element: any) => { history.push('/auth/CBCollaborator?id=' + element.user) }}
                            onDelete={(element: any) => { }}
                            onFile={(element: any) => { }}
                            route=''
                            renderTools={null}
                            currentPage={currentPage}
                            totalPages={pages}
                            onPageChange={(page: number) => {setCurrentPage(page)}}
                        />
                    </div>
                </div>
            </div>
        </AuthContainer>
    )
}

export default DuedNotificationsPage