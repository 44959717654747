import { useEffect } from "react";
import { useHistory } from "react-router";
import useAuth from "../../hooks/useAuth";

const LogoutPage = () => {
    
    const { logout } = useAuth();
    const history = useHistory();

    useEffect(() => {
        logout();
        history.push('/');
    });
    
    return (
        <></>
    )
}

export default LogoutPage;