import { AxiosResponse } from "axios";
import api from "./api";

interface trainingsServiceInterface {
    list: (companyId: number|string) => Promise<AxiosResponse<any>>;
    listByUser: (userId: number|string) => Promise<AxiosResponse<any>>;
    store: (data: trainingsData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: trainingsData) => Promise<AxiosResponse<any>>;
}

interface trainingsData {
    name?: string;
    type?: string;
    emission_date?: string;
    expiry_date?: string;
    attachments?: string;
    company?: string;
    user?: string;
}

const prefix = 'trainings';

const trainingsService: trainingsServiceInterface = {
    list: (companyId: number|string) => 
        api.get('/all_'+prefix+'/'+companyId),
    listByUser: (userId: number|string) => 
        api.get('/all_trainings_by_user/'+userId),
    store: (data: trainingsData) => 
        api.post('/'+prefix, data),
    get: (id: number|string) => 
        api.get('/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/'+prefix+'/'+id),
    put: (id: number|string, data: trainingsData) =>
        api.put('/'+prefix+'/'+id, data)
}

export default trainingsService;