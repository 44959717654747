import { AxiosResponse } from "axios";
import api from "./api";

interface recoverServiceInterface {
    retrive: (email: string) => Promise<AxiosResponse<any>>;
    recover: (data: any) => Promise<AxiosResponse<any>>;
}

const recoverService: recoverServiceInterface = {
    retrive: (email: string) =>
        api.post('/users/retrieve', {
            email: email
        }),
    recover: (data: any) =>
        api.post('/users/retrieve/change', data)
};

export default recoverService;