import { useState } from "react";
import { RE_CEP, RE_CPFCNPJ, RE_EMAIL, RE_PHONE } from "../utils/validations";

const useValidation = () => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [validationMessage, setValidationMessage] = useState<string>('');
  
  /* Validation patterns
  * const patterns = 'email|cpf|cnpj|cpfCnpj|cep|phone|required|nullable|min:X|max:Y';
  */

  const validate = (value: string, patterns: string) => {
    setIsValid(true);

    let validations = patterns.split('|');
    let minLength = null;
    let maxLength = null;

    //load custom validations
    validations.forEach((el) => {
      if(el.includes('min')) {
        let splitCustom = el.split(':');
        minLength = splitCustom[1];
      }
      if(el.includes('max')) {
        let splitCustom = el.split(':');
        maxLength = splitCustom[1];
      }
    });
    //required
    if(validations.includes('required') && value.trim().length == 0) {
      setIsValid(false);
      setValidationMessage('Este campo é obrigatório');
      return;
    }
    if(validations.includes('unrequired') && value.trim().length == 0) {
      setIsValid(true);
      setValidationMessage('');
      return;
    }
    if(value.length > 0) {
      //min:X
      if(minLength && minLength > value.length) {
        setIsValid(false);
        setValidationMessage('Mínimo de '+minLength+' caracteres.');
        return;
      }
      //max:X
      if(maxLength && maxLength < value.length && value.length > 0) {
        setIsValid(false);
        setValidationMessage('Máximo de '+maxLength+' caracteres.');
        return;
      }
      //email
      if(validations.includes('email') && !RE_EMAIL.test(value.toLowerCase())) {
        setIsValid(false);
        setValidationMessage('Preencha o e-mail corretamente.');
        return;
      }
      //cpf/cnpj
      if(validations.includes('cpfCnpj') && !RE_CPFCNPJ.test(value.toLowerCase())) {
        setIsValid(false);
        setValidationMessage('Preencha o CPF/CNPJ corretamente.');
        return;
      }
      //phone
      if(validations.includes('phone') && !RE_PHONE.test(value.toLowerCase())) {
        setIsValid(false);
        setValidationMessage('Preencha o telefone corretamente.');
        return;
      }
      //phone
      if(validations.includes('cep') && !RE_CEP.test(value.toLowerCase())) {
        setIsValid(false);
        setValidationMessage('Preencha o CEP corretamente.');
        return;
      }
    }
     
  }

  return {
    validate,
    isValid,
    validationMessage
  }
}
export default useValidation;