import React from "react";

interface AmbraInputFilePropsInterface {
  label?: string;
  className?: any;
  onChange?: (e: any) => void;
}

const AmbraInputFile = (props: AmbraInputFilePropsInterface) => {
  const input = React.createRef<HTMLInputElement>();
  
  const handleInputChange = (e: any) => {
    if(props.onChange) {
      props.onChange(e);
    }
  }

  return (
    <div className={props.className}>
      <label className="text-base font-medium text-gray-700">
        {props.label}
      </label>
      <input
        type="file"
        multiple={false}
        onChange={(e: any) => {handleInputChange(e)}}
        ref={input}
        className={'bg-ambra-whiteForm color-ambra-gray border-ambra-gray border-solid p-2 text-base rounded border block w-full shadow-sm outline-none focus:outline-none focus:shadow-lg transition-all duration-300 border-gray-300'}
      ></input>
    </div>
  );

}

export default AmbraInputFile;