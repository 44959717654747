import { AxiosResponse } from "axios";
import api from "./api";

interface workPermissionsServiceInterface {
    list: (solicitationId: number|string) => Promise<AxiosResponse<any>>;
    store: (data: workPermissionsData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: workPermissionsData) => Promise<AxiosResponse<any>>;
}

interface workPermissionsData {
    name?: string;
    is_draft?: boolean;
    preliminary_risk_analysis?: string;
    expiration?: number;
    need_blood_pressure?: boolean;
    need_air_pressure?: boolean;
    company?: string;
    releasers?: any;
}

const prefix = 'work_permissions';

const workPermissionsService: workPermissionsServiceInterface = {
    list: (companyId: number|string) => 
        api.get('/all_'+prefix+'/'+companyId),
    store: (data: workPermissionsData) => 
        api.post('/'+prefix, data),
    get: (id: number|string) => 
        api.get('/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/'+prefix+'/'+id),
    put: (id: number|string, data: workPermissionsData) =>
        api.put('/'+prefix+'/'+id, data)
}

export default workPermissionsService;