import { AxiosResponse } from "axios";
import api from "./api";

interface releasersServiceInterface {
    list: (companyId: number|string) => Promise<AxiosResponse<any>>;
    listByUnity: (unity: number|string) => Promise<AxiosResponse<any>>;
    store: (data: releasersData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: releasersData) => Promise<AxiosResponse<any>>;
}

interface releasersData {
    name?: string;
    company?: string;
    active?: boolean;
}

const prefix = 'releasers';

const releasersService: releasersServiceInterface = {
    list: (companyId: number|string) => 
        api.get('/company/all_'+prefix+'/'+companyId),
    listByUnity: (unity: number|string) => 
        api.get('/company/all_'+prefix+'_by_unity/'+unity),
    store: (data: releasersData) => 
        api.post('/company/'+prefix, data),
    get: (id: number|string) => 
        api.get('/company/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/company/'+prefix+'/'+id),
    put: (id: number|string, data: releasersData) =>
        api.put('/company/'+prefix+'/'+id, data)
}

export default releasersService;