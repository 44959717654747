import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const BackToConfig = () => {
    return (
        <div className="mb-2 text-white">
            <Link to="/auth/config" className="text-white text-lg"><FontAwesomeIcon icon={faAngleLeft} /> Voltar para configurações</Link>
        </div>
    )
}

export default BackToConfig;