import { AxiosResponse } from "axios";
import api from "./api";

interface userServiceInterface {
    signupCompanyAdmin: (data: any) => Promise<AxiosResponse<any>>;
    signupCompanyEmployee: (data: any) => Promise<AxiosResponse<any>>;
    signupCompanyGrantor: (name: string, email: string, password: string, company: string, field: string, sector: string) => Promise<AxiosResponse<any>>;
    signIn: (email: string, password: string) => Promise<AxiosResponse<any>>;
    list: (companyId: number|string) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: usersData) => Promise<AxiosResponse<any>>;
    changePassword: (id: string, password: string) => Promise<AxiosResponse<any>>;
}

interface usersData {
    //id?: string;
    name?: string;
    password?: string;
    is_thirdy_party?: boolean;
    company_name?: string | null;
    company_cnpj?: string | null | number; // ? -> 
    email?: string;
    unity?:string;
    company?: string;
    sector?: string;
    field?: string;
    active?: boolean;
    scopes?: string[];
    position?: string;
    is_responsible?: boolean;
    is_releaser?: boolean;
    email_verified_at?: string;
    password_changed_at?: string;
    deleted_at?: string;
    created_at?: string;
    updated_at?: string;
    fields?: string[];
    releasers?: string[];
    company_identificator?: string;
}   

const prefix = 'users';

const userService: userServiceInterface = {
    signupCompanyAdmin: (data: any) => 
        api.post('/auth/signup', {
            ...data, scopes: ['company_admin']
        }),
    signupCompanyEmployee: (data: any) => 
        api.post('/auth/signup', {
            ...data, scopes: ['company_employee']
        }),
    signupCompanyGrantor: (name: string, email: string, password: string, company: string, field: string, sector: string) =>
        api.post('/auth/signup', {
            name, email, password, company, field, sector, scopes: ['company_grantor'], 
        }),
    signIn: (email: string, password: string) => 
        api.post('/auth/signin', {
            email, password
        }),
    list: (companyId: number|string) => 
        api.get('/all_'+prefix+'/'+companyId),
    get: (id: number|string) => 
        api.get('/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/'+prefix+'/'+id),
    put: (id: number|string, data: usersData) =>
        api.put('/'+prefix+'/'+id, data),
    changePassword: (id: string, password: string) =>
        api.post('/users/changePassword/'+id, {
            password
        })
}

export default userService;