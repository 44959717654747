import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { loggedUserState, tokenState } from '../state/authState';

const useAuth = () => {

    const [loggedUser, setLoggedUser] = useRecoilState(loggedUserState);
    const [token, setToken] = useRecoilState(tokenState);
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        if(loggedUser && token && localStorage.getItem('token') !== null) {
            setIsLoggedIn(true);
        }else{
            setIsLoggedIn(false);
        }

        return () => {}
    });

    const logout = () => {
        setToken('');
        setLoggedUser({});
        localStorage.removeItem('rememberLogin');
        localStorage.removeItem('rememberPassword');
        localStorage.removeItem('token');
        setIsLoggedIn(false);
    }

    const updateLoggedUser = () => {
        setLoggedUser(JSON.parse(window.localStorage.getItem('loggedUser') ?? ''));
    }

    const getUserRole = () => {
        let toRoleLoggedUser = loggedUser;
        if(!loggedUser) {
            updateLoggedUser();
            toRoleLoggedUser = JSON.parse(window.localStorage.getItem('loggedUser') ?? '');
        }
        return toRoleLoggedUser.role;
    }

    return {
        loggedUser,
        setLoggedUser,
        isLoggedIn,
        token,
        setToken,
        logout,
        getUserRole
    }
}

export default useAuth;