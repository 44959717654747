import { AxiosResponse } from "axios";
import api from "./api";

interface unityServiceInterface {
  list: (company:string) => Promise<AxiosResponse<any>>;
  store: (data: unityData) => Promise<AxiosResponse<any>>;
  get: (id: number|string) => Promise<AxiosResponse<any>>;
  destroy: (id: number|string) => Promise<AxiosResponse<any>>;
  put: (id: number|string, data: unityData) => Promise<AxiosResponse<any>>;
}

interface unityData {
  id?: string;
  name?: string;
  address?: string;
  company?: string;
}

const unityServices: unityServiceInterface = {
  list: (company:string) => api.get(`/company/unity/${company}`),
  store: (data: unityData) => api.post("/company/unity", data),
  get: (id: number|string) =>
      api.get('/company/unity_id/'+id),
   destroy: (id: number|string) =>
      api.delete('/company/unity/'+id),
  put: (id: number|string, data: unityData) =>
      api.put('/company/unity/'+id, data)
};

export default unityServices;
