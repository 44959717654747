import React from 'react'

const Title = (props: any) => {
    return (
        <div className="text-xl font-medium text-gray-700 uppercase">
            {props.children}
        </div>
    )
}

export default Title;