import  { useEffect, useState } from "react";
import { RiArrowLeftLine, RiSave2Fill } from "react-icons/ri";
import { useHistory } from "react-router";
import Button from "../../../components/elements/button/Button";

import AmbraInputText from "../../../components/elements/form/AmbraInputText";
import AmbraTable from "../../../components/elements/table/AmbraTable";
import Subtitle from "../../../components/elements/text/Subtitle";
import Title from "../../../components/elements/text/Title";
import AuthContainer from "../../../components/layout/auth/AuthContainer";
import { useQuery } from "../../../hooks/useQuery";
import fieldsService from "../../../services/fieldsService";

import useAlerts from "../../../hooks/useAlerts";

import unityServices from "../../../services/unityServices";

const PRCreateAreaPage = () => {
  const history = useHistory();
  const query = useQuery();
  const alerts = useAlerts();

  const [dataUnity, setDataUnity] = useState<any>([]);

  const [editId, setEditId] = useState<string>("0");
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [updateCounter, setUpdateCounter] = useState<number>(0);

  useEffect(() => {
    let companyId = localStorage.getItem("company");
    if (companyId) {
      setCompany(companyId);
    }
    let paramId = query.get("id");
    if (paramId) {
      setEditId(paramId);
    }
  }, []);

  useEffect(() => {
    if (editId !== "0") {
      unityServices.get(editId).then((response) => {

        if (response.status) {
          getObjectFromRequest(response.data);
        }
      });
    }
  }, [editId]);

  useEffect(() => {
    if (company !== "" && editId !== "0") {
      fieldsService.list(editId).then((response) => {
        if (response.status === 200) {
          setDataUnity(response.data);
        }
      });
    }
  }, [updateCounter, company, editId]);

  const destroySector = (sectorId: string) => {
    alerts.showAlertConfirm(
      "warning",
      "Você realmente deseja excluir a área?",
      "Isso não poderá ser desfeito.",
      () => {
        fieldsService.destroy(sectorId).then((response) => {
          if (response.status) {
            alerts.showAlert(
              "success",
              "Sucesso",
              "A área foi excluída com sucesso"
            );
            setUpdateCounter(updateCounter + 1);
          }
        });
      },
      () => {}
    );
  };

  const getObjectFromRequest = (area: any) => {
    setName(area[0].name);

    setAddress(area[0].address);
  };

  const handleStoreError = () => {
    alerts.showAlert("error", "Ops", "Ocorreu um erro ao salvar a Unidade.");
  };



  const store = () => {
    let cont = true;
    if (editId !== "0") cont = false;
    let requestData = {
      name: name,
      address: address,
      company: company,
    };
    if (editId === "0") {
      unityServices
        .store(requestData)
        .then((response) => {
          if (response.status) {
            alerts.showAlert("success", "Sucesso", "Unidade salva com sucesso");
            history.push("/auth/PR");
          } else {
            handleStoreError();
          }
        })
        .catch((error) => {
          handleStoreError();
        });
    } else {
      unityServices
        .put(editId, requestData)
        .then((response) => {
          if (response.status) {
            alerts.showAlert("success", "Sucesso", "Unidade salva com sucesso");
            history.push("/auth/PR");
          } else {
            handleStoreError();
          }
        })
        .catch((error) => {
          console.log(requestData)
          console.log(error.request);
          handleStoreError();
        });
    }
  };

  return (
    <AuthContainer>
      <div className="m-3 mt-4 mb-4">
        <div className="mb-4">
          <Title> Criar/Editar Unidade</Title>
          <Subtitle> Parametrização {">"} Unidades </Subtitle>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-7 gap-5 mb-5">
          <div className="col-span-4">
            <AmbraInputText
              label="Nome da Unidade"
              validation="required"
              value={name}
              onChange={(e: any) => {
                setName(e.target.value.toUpperCase());
              }}
            />
          </div>

          <div className="col-span-4">
            <AmbraInputText
              label="Endereço"
              placeholder="Digite o seu endereço completo"
              value={address}
              onChange={(e: any) => {
                setAddress(e.target.value);
              }}
              validation="required"
            />
          </div>
        </div>

        {editId !== "0" && (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-5 mt-5">
              <div>
                <AmbraTable
                  title="Áreas"
                  data={dataUnity}
                  renderColumns={[{ label: "Nome", dataColumn: "name" }]}
                  showEdit={true}
                  showDelete={true}
                  showFile={false}
                  search={true}
                  onEdit={(element: any) => {
                    history.push(
                      "/auth/PR/CreateSector?unityId=" +
                        editId +
                        "&id=" +
                        element.id
                     
                    );
                  }}
                  onDelete={(element: any) => {
                    destroySector(element.id);
                  }}
                  onFile={(element: any) => {
                    console.log(element);
                  }}
                  route={`/auth/PR/CreateArea?unityId=${editId}`}
                  renderTools={<>adicionar área</>}
                />
              </div>
            </div>
          </>
        )}

      <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
          <div className="col-start-6">
            <Button
              type="primary"
              className="mr-2"
              onClick={() => {
                history.push("/auth/PR");
              }}
            >
              <RiArrowLeftLine className="inline-block text-white-700" /> Voltar
            </Button>
            <Button
              type="primary"
              onClick={() => {
                store();
              }}
              className="ml-2"
            >
              Salvar Unidade{" "}
              <RiSave2Fill className="inline-block text-white-700" />
            </Button>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};

export default PRCreateAreaPage;
