import axios from 'axios';

const api = axios.create({
  baseURL : process.env.REACT_APP_API_URL,
  timeout: 30000,
  withCredentials: false,
  headers: {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'crossDomain': true
  }
});

api.interceptors.request.use((config) => {
  if(localStorage.token) {
    const token = localStorage.token;
    config.headers.Authorization =  'Bearer ' + token;
  }

  return config;
});

api.interceptors.response.use((response) => {
  if(response.status == 401 || response.status == 403) {
    localStorage.clear();
    window.location.reload();
  }
  return response;
}, function (error) {
  let response = error.response;
  if(response && response.status == 401) {
    localStorage.clear();
    window.location.reload();
  }
  return Promise.reject(error);
});

export default api;