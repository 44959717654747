import { AxiosResponse } from "axios";
import api from "./api";

interface facialServiceInterface {
    save: (userId: string, formData: FormData) => Promise<AxiosResponse<any>>;
}

const prefix = 'report';

const facialService: facialServiceInterface = {
    save: (userId: string, formData: FormData) => 
        api.post('mobile/save-face/'+userId, formData, {
            headers : {
                'Content-Type': 'multipart/form-data'
            }
        })
}

export default facialService;