import { AxiosResponse } from "axios";
import api from "./api";

interface preliminaryRiskAnalysisServiceInterface {
    list: (companyId: number|string) => Promise<AxiosResponse<any>>;
    store: (data: preliminaryRiskAnalysisData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: preliminaryRiskAnalysisData) => Promise<AxiosResponse<any>>;
}

interface preliminaryRiskAnalysisData {
    name?: string;
    company?: string;
    sector?: string;
    field?: string;
    is_draft?: boolean;
    expiration?: number;
    only_to_assigned_releaser?: boolean;
    need_pass_by_pass?: boolean;
    releasers?: any;
}

const prefix = 'preliminary_risk_analysis';

const preliminaryRiskAnalysisService: preliminaryRiskAnalysisServiceInterface = {
    list: (companyId: number|string) => 
        api.get('/all_'+prefix+'/'+companyId),
    store: (data: preliminaryRiskAnalysisData) => 
        api.post('/'+prefix, data),
    get: (id: number|string) => 
        api.get('/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/'+prefix+'/'+id),
    put: (id: number|string, data: preliminaryRiskAnalysisData) =>
        api.put('/'+prefix+'/'+id, data)
}

export default preliminaryRiskAnalysisService;