import { selector } from "recoil";
import { atom } from "recoil";


export const tokenState = atom({
    key: 'tokenUserState',
    default : {}
});

export const loggedUserState = atom<any>({
    key: 'loggedUserState',
    default : {}
});

export const tokenExistsState = selector({
    key: 'tokenExistsState',
    get: ({get}) => {
        const token = get(tokenState);
        if(token === {} || token === null)
            return false;
        else
            return true;
    },
});