import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FiSave } from 'react-icons/fi';
import { useRecoilState } from 'recoil';
import useAlerts from '../../hooks/useAlerts';
import genericService from '../../services/genericService';
import { genericCRUDModelState } from '../../state/GenericCRUDState';
import { errorMessageBeautify } from '../../utils/errorMessageHandler';
import { maskBRL } from '../../utils/masks';
import Button from '../elements/button/Button';
import AmbraInputSelect from '../elements/form/AmbraInputSelect';
import AmbraInputText from '../../components/elements/form/AmbraInputText';
import ModalLg from '../elements/modal/ModalLg';
import { GenericCRUDEntityFieldsInterface, GenericCRUDFieldInterface } from './GenericCRUD';

interface GenericCRUDModalProps {
    show: boolean;
    name: string;
    handleClose: () => void;
    modelEdit?: any;
    handleCreateOrUpdate?: () => void;
    fields: GenericCRUDFieldInterface[];
    entityFields?: GenericCRUDEntityFieldsInterface[];
    endpoint: string;
}

const GenericCRUDModal = (props: GenericCRUDModalProps) => {

    const [fields, setFields] = useState<GenericCRUDFieldInterface[]>([]);
    const [entityFields, setEntityFields] = useState<GenericCRUDEntityFieldsInterface[]>([]);
    const [genericCRUDModel, setGenericCRUDModel] = useRecoilState(genericCRUDModelState);

    const alerts = useAlerts();

    useEffect(() => {
        setFields(props.fields);
    }, [props.fields]);

    useEffect(() => {
        setEntityFields(props.entityFields ?? []);
    }, [props.entityFields]);

    useEffect(() => {
        setBlankModel();
    }, []);

    useEffect(() => {
        if(props.modelEdit) {
            setGenericCRUDModel(props.modelEdit);
        }
    }, [props.modelEdit]);

    const setBlankModel = () => {
        let modelBlank:any = {};
        props.fields.forEach((el: GenericCRUDFieldInterface) => {
            modelBlank[el.fieldName] = '';
        });
        setGenericCRUDModel(modelBlank);
    }

    const getModelToRequest = () => {
        let modelToRequest = {...genericCRUDModel};
        let priceExists = fields.filter((el, index) => {return (el.fieldName == 'price')});
        console.log(priceExists);
        if(priceExists.length > 0) {
            let priceField = modelToRequest['price'].replace(/\./g, '').replace(',', '.');
            modelToRequest['price'] = parseFloat(priceField);
        }
        return modelToRequest;
    }

    const create = () => {
        genericService.create(props.endpoint, getModelToRequest()).then((response) => {
            if(response.data.id > 0) {
                alerts.showAlert('success', 'Criado com sucesso', 'O "'+props.name+'" #'+response.data.id+' foi criado com sucesso');
                setBlankModel();
                props.handleClose();
                if(props.handleCreateOrUpdate)
                    props.handleCreateOrUpdate();
            }
        }).catch((error) => {
            let errorMessage = error.response?.data?.error?.message;
            if(errorMessage) {
                alerts.showAlert('error', 'Ops', 'Verificar: ' + errorMessageBeautify(errorMessage));
            }else{
                alerts.showAlert('error', 'Ops', 'Verifique se todos os campos estão preenchidos corretamente.');
            }
        });
    }

    const update = (id: number) => {
        genericService.update(props.endpoint, id, getModelToRequest()).then((response) => {
            if(response.status == 200) {
                alerts.showAlert('success', '"'+props.name+'" alterado com sucesso', 'O "'+props.name+'" '+props.modelEdit.id+' foi alterado com sucesso');
                setBlankModel();
                props.handleClose();
                if(props.handleCreateOrUpdate)
                    props.handleCreateOrUpdate();
            }
        }).catch((error) => {
            let errorMessage = error.response?.data?.error?.message;
            if(errorMessage) {
                alerts.showAlert('error', 'Ops', 'Verificar: ' + errorMessageBeautify(errorMessage));
            }else{
                alerts.showAlert('error', 'Ops', 'Verifique se todos os campos estão preenchidos corretamente.');
            }
        });
    }

    const handleClickSubmit = () => {
        if(props.modelEdit?.id) {
            update(props.modelEdit.id);
        } else {
            create();
        }
    }

    const setModelField = (field: string, value: any) => {
        let modelEdit = {...genericCRUDModel};

        if(field == 'price')
            modelEdit[field] = maskBRL(value);
        else
            modelEdit[field] = value;
        
            setGenericCRUDModel(modelEdit);
    }

    const getInputValue = (fieldName: string) => {
        if(fieldName == 'price')
            return maskBRL(genericCRUDModel[fieldName]);
        return genericCRUDModel[fieldName];
    }

    const handleClickClose = () => {
        setBlankModel();
        props.handleClose();
    }

    const renderInput = (el: any, index: number) => {
        let inputValue = getInputValue(el.fieldName);

        if(el.fieldType && el.fieldType == 'select') {
            return (
                <AmbraInputSelect 
                    key={index}
                    placeholder={el.fieldInputPlaceholder}
                    label={el.fieldInputLabel}
                    onChange={(event: any) => {
                        setModelField(el.fieldName, event.target.value);
                    }}
                    value={inputValue}
                    validation={el.fieldValidationPattern}
                    data={el.fieldSelectData}
                />
            )
        }

        return (
            <AmbraInputText 
                key={index}
                placeholder={el.fieldInputPlaceholder}
                label={el.fieldInputLabel}
                onChange={(event: any) => {
                    setModelField(el.fieldName, event.target.value);
                }}
                type={el.fieldType}
                mask={el.fieldMask ?? ''}
                value={inputValue}
                validation={el.fieldValidationPattern}
            />
        )
    }

    const renderEntityFieldForm = (el: any, index: number) => {

        let entityFieldFormModelValue = getInputValue(el.entityFieldName);

        return (
            <div key={index}>
                {
                    React.cloneElement(el.entityFieldFormComponent, {
                        model: entityFieldFormModelValue,
                        onModelChange: (modelUpdated: any) => {
                            console.log('onModelChange', modelUpdated);
                            setModelField(el.entityFieldName, modelUpdated);
                        }
                    })
                }
            </div>
        )
    }

    return (
        <ModalLg show={props.show} handleClose={() => {handleClickClose()}}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-1">
                <div className="text-xl text-gray-700 font-medium">
                {props.name} - {props.modelEdit && props.modelEdit?.id ? 'Atualização' : 'Cadastro' }
                </div>
            </div>
            <div className="text-lg text-gray-700 pt-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-1">
                    {
                        fields.map(renderInput)
                    }
                </div>
                {
                    entityFields.map(renderEntityFieldForm)
                }
            </div>
            <div className="text-right pt-4">
                <span className="ml-2 text-base">
                    <Button type="primary" onClick={(() => {handleClickSubmit()})}><FiSave className="inline mb-1"/> Salvar</Button>
                </span>
            </div>
        </ModalLg>
    )
}

export default GenericCRUDModal;