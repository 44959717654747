import { AxiosResponse } from "axios";
import api from "./api";

interface questionsServiceInterface {
    list: (topicId: number|string) => Promise<AxiosResponse<any>>;
    store: (data: questionsData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: questionsData) => Promise<AxiosResponse<any>>;
}

interface questionsData {
    name?: string;
    question_statement?: string;
    mandatory_picture?: boolean;
    answer_type?: string;
    order?: number;
    topic?: string;
    company?: string;
    options?: any;
    active?: boolean;
    picture?: boolean;
    picture_statement?: string;
}

const prefix = 'questions';

const questionsService: questionsServiceInterface = {
    list: (topicId: number|string) => 
        api.get('/all_'+prefix+'/'+topicId),
    store: (data: questionsData) => 
        api.post('/'+prefix, data),
    get: (id: number|string) => 
        api.get('/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/'+prefix+'/'+id),
    put: (id: number|string, data: questionsData) =>
        api.put('/'+prefix+'/'+id, data)
}

export default questionsService;