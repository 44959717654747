import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PublicNavbar from '../../components/layout/public/PublicNavbar';
import useAlerts from '../../hooks/useAlerts';
import recoverService from '../../services/recoverService';

const RecoverPage = () => {

    const [email, setEmail] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [step, setStep] = useState<number>(1);

    const [loading, setLoading] = useState<boolean>(false);

    const alerts = useAlerts();

    const history = useHistory();

    const retrieve = () => {
        setLoading(true);
        recoverService.retrive(email).then((response) => {
            setLoading(false);
            if(response.status === 200) {
                setStep(2);
            }else{
                alerts.showAlert('error', 'Ops', 'Parece que esse e-mail está incorreto ou o serviço está indisponível');
            }
        }).catch((error) => {
            setLoading(false);
            alerts.showAlert('error', 'Ops', 'Parece que esse e-mail está incorreto ou o serviço está indisponível');
        });
    }

    const reset = () => {
        recoverService.recover({
            email,
            code,
            password
        }).then((response) => {
            if(response.status === 200) {
                alerts.showAlert('success', 'Sucesso', 'Senha alterada com sucesso!');
                history.push('/');
            }else{
                alerts.showAlert('error', 'Ops', 'Código incorreto');
            }
        }).catch((error) => {
            alerts.showAlert('error', 'Ops', 'Código incorreto');
        });
    }

    return (
        <div className="container m-auto pt-36">
            <PublicNavbar></PublicNavbar>
            <div className="max-w-sm mx-auto shadow-lg p-4 text-indigo-700 rounded bg-white px-4 text-left text-2xl">
                {
                    step == 1 &&
                    <>
                        <div className="mt-4">
                            <p className="text-xl text-gray-600">Nós enviaremos um e-mail e na sequência você deverá informar o código que foi enviado.</p>
                        </div>
                        <div className="mt-2">
                            <input className="border-solid p-2 text-lg text-gray-600 rounded border block w-full shadow outline-none focus:outline-none focus:shadow-lg transition-all duration-300" value={email} onChange={(e: any) => {setEmail(e.target.value)}} placeholder="Digite o seu e-mail" type="email"></input>
                        </div>
                        <div className="mt-4">
                            <button className="p-2 text-xl text-white bg-indigo-600 text-center rounded block w-full shadow transition-all hover:bg-blue-600 duration-300" onClick={() => {retrieve()}}>Enviar e-mail</button>
                        </div>
                        {
                            loading && 
                            <div className="text-gray-500 text-center text-base">
                                Enviando...
                            </div>
                        }
                        <div className="mt-4 text-left text-lg text-blue-600">
                            <Link to="/login">Voltar</Link>
                        </div>
                    </>
                }
                {
                    step == 2 &&
                    <>
                        <div className="mt-4">
                            <p className="text-xl text-gray-600">Enviamos um código para você!</p>
                        </div>
                        <div className="mt-2">
                            <input className="border-solid p-2 text-lg text-gray-600 rounded border block w-full shadow outline-none focus:outline-none focus:shadow-lg transition-all duration-300" value={code} onChange={(e: any) => {setCode(e.target.value)}} placeholder="Digite o código"></input>
                        </div>
                        <div className="mt-2">
                            <input className="border-solid p-2 text-lg text-gray-600 rounded border block w-full shadow outline-none focus:outline-none focus:shadow-lg transition-all duration-300" value={password} onChange={(e: any) => {setPassword(e.target.value)}} placeholder="Digite a nova senha" type="password"></input>
                        </div>
                        <div className="mt-4">
                            <button className="p-2 text-xl text-white bg-indigo-600 text-center rounded block w-full shadow transition-all hover:bg-blue-600 duration-300" onClick={() => {reset()}}>Alterar senha</button>
                        </div>
                        <div className="mt-4 text-left text-lg text-blue-600">
                            <Link to="/login">Voltar</Link>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default RecoverPage;