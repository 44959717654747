import React from 'react';
import './fonts.scss';
import './App.scss';
import AlertManager from './components/layout/general/AlertMaganager';
import ProjectRouter from './routes/ProjectRouter';

function App() {

  return (
    <>
      <ProjectRouter />
      <AlertManager />
    </>
  );
}

export default App;
