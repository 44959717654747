export const RE_EMAIL = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const RE_NUMBER = /[0-9]/i;

export const RE_PHONE = /\(\d{2,}\) \d{4,5}-\d{4}/i;

export const RE_CPF = /[0-9]/i;

export const RE_CNPJ = /[0-9]/i;

export const RE_CPFCNPJ = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/i;

export const RE_CEP = /[0-9]{5}-[0-9]{3}/i;