import { AxiosResponse } from "axios";
import api from "./api";

interface sectorsServiceInterface {
    list: (companyId: number|string) => Promise<AxiosResponse<any>>;
    store: (data: sectorsData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: sectorsData) => Promise<AxiosResponse<any>>;
}

interface sectorsData {
    id?: string;
    name?: string;
    company?: string;
    active?: boolean;
}

const prefix = 'sectors';

const sectorsService: sectorsServiceInterface = {
    list: (companyId: number|string) => 
        api.get('/company/all_'+prefix+'/'+companyId),
    store: (data: sectorsData) => 
        api.post('/company/'+prefix, data),
    get: (id: number|string) => 
        api.get('/company/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/company/'+prefix+'/'+id),
    put: (id: number|string, data: sectorsData) =>
        api.put('/company/'+prefix+'/'+id, data)
}

export default sectorsService;