import { AxiosResponse } from "axios";
import api from "./api";

interface topicsServiceInterface {
    list: (companyId: number|string) => Promise<AxiosResponse<any>>;
    listPRA: (companyId: number|string) => Promise<AxiosResponse<any>>;
    listWP: (companyId: number|string) => Promise<AxiosResponse<any>>;
    store: (data: topicsData) => Promise<AxiosResponse<any>>;
    get: (id: number|string) => Promise<AxiosResponse<any>>;
    destroy: (id: number|string) => Promise<AxiosResponse<any>>;
    put: (id: number|string, data: topicsData) => Promise<AxiosResponse<any>>;
}

interface topicsData {
    name?: string;
    mandatory?: boolean;
    description?: string;
    order?: number;
    preliminary_risk_analysis?: string;
    start_topic?: boolean;
    company?: string;
}

const prefix = 'topics';

const topicsService: topicsServiceInterface = {
    list: (companyId: number|string) => 
        api.get('/all_'+prefix+'/'+companyId),
    listPRA: (aprId: number|string) => 
        api.get('/all_topics_by_preliminary_risk_analysis/'+aprId),
    listWP: (wpId: number|string) => 
        api.get('/all_topics_by_work_permission/'+wpId),
    store: (data: topicsData) => 
        api.post('/'+prefix, data),
    get: (id: number|string) => 
        api.get('/'+prefix+'/'+id),
    destroy: (id: number|string) => 
        api.delete('/'+prefix+'/'+id),
    put: (id: number|string, data: topicsData) =>
        api.put('/'+prefix+'/'+id, data)
}

export default topicsService;