export const errorMessageBeautify = (message: string) => {
    let newMessage = message;

    //general text replace
    newMessage = newMessage.replace('no campo email', 'no campo e-mail');
    newMessage = newMessage.replace('no campo "cpfCnpj"', 'no campo CPF/CNPJ');

    //class name replace
    newMessage = newMessage.replace('na tabela salespeople', 'para vendedores');
    newMessage = newMessage.replace('na tabela clients', 'para clientes');

    return newMessage;
}